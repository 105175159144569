import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import API from '../../../api/api';
import { classModifier, getPrettyTimeFromSeconds } from '../../../utils';

import { ICallsDurationWidgetProps } from '../Welcome.types';
import { AppState } from '../../../redux/store';

import Spinner from '../../../components/Spinner';
import { selectUserTimezone } from '../../../redux/selectors/selectors';


type FilterTypes = 'day' | 'week' | 'month' | 'all';


const CallsDurationWidget = (props: ICallsDurationWidgetProps) => {
  const {
    title,
    type,
    userTimezone,
  } = props;

  const [dateFilter, setDateFilter] = useState<FilterTypes>('day');
  const [totalTime, setTotalTime] = useState<number>(0);
  const [averageTime, setAverageTime] = useState<number>(0);

  const [isPending, setPending] = useState(true);

  useEffect(() => {
    setPending(true)

    const interval = dateFilter === 'all'
      ? null
      : props.getDatesForInterval(dateFilter, userTimezone);

    API.getCallsDurationByPeriod(type, interval)
      .then(({ data }) => {
        setTotalTime(data.total);
        setAverageTime(data.average);
      })
      .catch(console.log)
      .finally(() => setPending(false))
  }, [dateFilter, userTimezone])

  return (
    <div className="welcome__item card">
      <h1 className="welcome__item-title">
        {title}
      </h1>

      <div className="calls-duration-widget welcome__not-draggable">
        <div className="switch calls-duration-widget__switch">
          <button
            className={classModifier('switch__btn', [dateFilter === 'day' && 'active'])}
            onClick={() => setDateFilter('day')}
          >
            Today
          </button>

          <button
            className={classModifier('switch__btn', [dateFilter === 'week' && 'active'])}
            onClick={() => setDateFilter('week')}
          >
            Week
          </button>

          <button
            className={classModifier('switch__btn', [dateFilter === 'month' && 'active'])}
            onClick={() => setDateFilter('month')}
          >
            Month
          </button>

          <button
            className={classModifier('switch__btn', [dateFilter === 'all' && 'active'])}
            onClick={() => setDateFilter('all')}
          >
            All Time
          </button>
        </div>

        <div className="welcome__data">
          {isPending
            ? (
              <div className="welcome__spinner-wrap">
                <Spinner />
              </div>
            )
            : (
              <div className="welcome__count-container">
                <div className="welcome__count-wrap">
                  <span>Total</span>
                  <span className="welcome__count">
                    {totalTime
                      ? getPrettyTimeFromSeconds(totalTime)
                      : 0
                    }
                  </span>
                </div>

                <div className="welcome__count-wrap">
                  <span>Average</span>
                  <span className="welcome__count">
                    {averageTime
                      ? getPrettyTimeFromSeconds(averageTime)
                      : 0
                    }
                  </span>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
};

const mapStateToProps = (state: AppState) => ({
  userTimezone: selectUserTimezone(state),
});

export default connect(mapStateToProps)(CallsDurationWidget);