import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faPhone,
  faHashtag,
  faQuoteRight,
  faMapMarkerAlt,
  // faAt,
} from '@fortawesome/free-solid-svg-icons';

import { classModifier, getPhotoFromEntity } from '../../utils';

import { IContactCardProps } from './ContactCard.types';

import './ContactCard.scss';
import LazyLoadImage from '../LazyLoadImage/LazyLoadImage';
import Scrollbars from 'react-custom-scrollbars';


const ContactCard = (props: IContactCardProps) => {
  const {
    contact,
  } = props;

  return (
    <div className="contact-card animated-init">
      <div className='contact-card__left-container'>
        <div className='contact-card__avatar'>
          <LazyLoadImage src={getPhotoFromEntity(contact.photo)} />
        </div>
        <div className='contact-card__name'>{contact.fn}</div>
      </div>

      <div className='contact-card__right-container'>
        <div className='contact-card__info-block'>
          <h2 className='contact-card__info-block-title'>General</h2>

          <Scrollbars
            autoHide
            autoHeight
            autoHeightMax={100}
          >
            {contact.emails.map(email =>
              <div
                className={classModifier('contact-card__info-block-item', [!!email.default && 'active'])}
                key={email.id}
              >
                <FontAwesomeIcon icon={faEnvelope} className='contact-card__info-item-icon' />
                {email.email}
              </div>
            )}

            {contact.tels.map(phone =>
              <div
                className={classModifier('contact-card__info-block-item', [!!phone.default && 'active'])}
                key={phone.id}
              >
                <FontAwesomeIcon icon={faPhone} className='contact-card__info-item-icon' />
                {phone.tel}
              </div>
            )}
          </Scrollbars>
        </div>

        <div className='contact-card__info-block'>
          <h2 className='contact-card__info-block-title'>Additional</h2>

          <div className='contact-card__info-block-item'>
            <FontAwesomeIcon icon={faMapMarkerAlt} className='contact-card__info-item-icon' />
            {contact.adr || 'none'}
          </div>

          <div className='contact-card__info-block-item'>
            <FontAwesomeIcon icon={faQuoteRight} className='contact-card__info-item-icon' />
            {contact.note || 'none'}
          </div>

          {/* <div className='contact-card__info-block-item'>
            <FontAwesomeIcon icon={faAt} className='contact-card__info-item-icon' />
            {contact.urls || 'none'}
          </div> */}

          <div className='contact-card__info-block-item'>
            <FontAwesomeIcon icon={faHashtag} className='contact-card__info-item-icon' />

            {!!contact.callerTags.length
              ? (
                <Scrollbars
                  autoHide
                  autoHeight
                  autoHeightMax={80}
                >
                  <div className="contact-card__tags-wrap">
                    {contact.callerTags.map(tag =>
                      <div
                        key={tag.id}
                        className='contact-card__tag'
                      >
                        {tag.title}
                      </div>
                    )}
                  </div>
                </Scrollbars>
              )
              : <span>none</span>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactCard;