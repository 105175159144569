import getDateByTimezoneOffset from './getDateByTimezoneOffset'
import { TimeInterval, SUM, DAY, WEEK, MONTH } from '../components/TimeIntervalsSwitcher/TimeIntervalsSwitcher'

type TDataObjectResult = { [key: string]: number[] }

function getDataByTimeInterval(
  userTimezone: number,
  dataInterval: TimeInterval,
  dates: number[],
  dataset: number[],
): { datesByInterval: number[], dataByInterval: number[] }

function getDataByTimeInterval(
  userTimezone: number,
  dataInterval: TimeInterval,
  dates: number[],
  dataset: { [key: string]: { data: number[] } },
): { datesByInterval: number[], dataByInterval: TDataObjectResult }

function getDataByTimeInterval(
  userTimezone: number,
  dataInterval: TimeInterval,
  dates: number[],
  dataset: number[] | { [key: string]: { data: number[] } },
): { datesByInterval: number[], dataByInterval: number[] | TDataObjectResult } {
  const newDataset = Array.isArray(dataset) ? { datasetKey: { data: dataset } } : dataset;

  let resultDates: number[] = [];
  let resultData: { [key: string]: number[] } = {};

  const datasetKeys: string[] = Object.keys(newDataset);

  if (dataInterval === SUM) {
    resultDates = [dates[0]]

    datasetKeys.forEach((key) => {
      const data = newDataset[key].data;
      let sumData: number = 0;

      data.forEach((value: number) => sumData += value)

      resultData[key] = [sumData];
    })
  }

  else if (dataInterval === DAY) {
    resultDates = [...dates];

    datasetKeys.forEach((key) => {
      resultData[key] = [...newDataset[key].data];
    });
  }

  else if (dataInterval === WEEK) {
    dates.forEach((date, idx) => {
      const timezoneDate = getDateByTimezoneOffset(userTimezone, date);

      if (timezoneDate.getDay() === 1 || idx === 0) {
        resultDates.push(date);
      }
    })

    datasetKeys.forEach((key) => {
      const allWeeksData: number[] = [];
      let oneWeekData: number = 0;

      const data = newDataset[key].data;

      data.forEach((value: number, idx: number) => {
        const timezoneDate = getDateByTimezoneOffset(userTimezone, dates[idx]);

        oneWeekData += value;

        if (timezoneDate.getDay() === 0 || idx === (data.length - 1)) {
          allWeeksData.push(oneWeekData);
          oneWeekData = 0;
        }
      })

      resultData[key] = allWeeksData;
    })
  }

  else if (dataInterval === MONTH) {
    dates.forEach((date, idx) => {
      const timezoneDate = getDateByTimezoneOffset(userTimezone, date);

      if (timezoneDate.getDate() === 1 || idx === 0) {
        resultDates.push(date);
      }
    })

    datasetKeys.forEach((key) => {
      let allMonthsData: number[] = [];
      let oneMonthData: number = 0;

      const data = newDataset[key].data;

      data.forEach((value: number, idx: number) => {
        const timezoneDate = getDateByTimezoneOffset(userTimezone, dates[idx]);
        const lastDayOfMonth = new Date(timezoneDate.getFullYear(), timezoneDate.getMonth() + 1, 0);

        oneMonthData += value;

        if (+timezoneDate === +lastDayOfMonth || idx === (data.length - 1)) {
          allMonthsData.push(oneMonthData);
          oneMonthData = 0;
        }
      });

      resultData[key] = allMonthsData;
    })
  }

  return {
    datesByInterval: resultDates,
    dataByInterval: Array.isArray(dataset) ? resultData.datasetKey : resultData
  }
}

export default getDataByTimeInterval;
