import { Dispatch } from "redux";
import { AxiosError } from "axios";

import API from "../../api/api";
import { normalize } from "../../utils/normalize";

import {
  ICouriersState,
  ICustomResponse,
} from "./types";
import {
  ICourierApi,
  IAction,
} from "../../interfaces/interfaces";

export const GET_ALL_COURIERS = 'GET_ALL_COURIERS';
export const CREATE_COURIER = 'CREATE_COURIER';
export const DELETE_COURIER = 'DELETE_COURIER';
export const CHANGE_COURIER_STATUS = 'CHANGE_COURIER_STATUS';


export const getAllCouriers = (userId: number) => (dispatch: Dispatch): void => {
  API.getAllCouriers()
    .then(res => {
      dispatch({
        type: GET_ALL_COURIERS,
        payload: {
          ...normalize(res.data),
          userId,
        },
      });
    })
    .catch(console.log);
};

export const createCourier = (courier: ICourierApi) => (dispatch: Dispatch): Promise<ICustomResponse> => {

  return API.createCourier(courier)
    .then(res => {
      dispatch({
        type: CREATE_COURIER,
        payload: {
          id: res.data,
          username: courier.username,
          email: courier.email,
          status: courier.status,
        },
      });

      return {
        status: true,
      }
    })
    .catch((err: AxiosError) => {
      const res = err && err.response && err.response.data;

      return res && {
        status: false,
        msg: JSON.parse(res.message),
      }
    });
};

export const changeCourierStatus = (courier: ICourierApi) => (dispatch: Dispatch): Promise<ICustomResponse> => {

  return API.changeCourierStatus(courier)
    .then(() => {

      dispatch({
        type: CHANGE_COURIER_STATUS,
        payload: {
          id: courier.id,
          status: courier.status
        },
      });

      return { status: true };
    })
    .catch(err => {
      const res = err && err.response && err.response.data;

      return {
        status: false,
        msg: JSON.parse(res.message),
      };
    });
};

export const deleteCourier = (id: number) => (dispatch: Dispatch): void => {
  API.deleteCourier(id)
    .then(() => {
      dispatch({
        type: DELETE_COURIER,
        payload: id,
      });
    })
    .catch(console.log);
};

const initialState: ICouriersState = {
  ids: [],
  entities: {},
};


export default (state = initialState, action: IAction) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_COURIERS: {
      const { entities, ids } = payload;

      return {
        ...state,
        entities,
        ids,
      }
    }
    case CREATE_COURIER: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: { ...payload },
        },
        ids: [...state.ids, payload.id],
      }
    }
    case CHANGE_COURIER_STATUS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: {
            ...state.entities[payload.id],
            status: payload.status
          },
        }
      }
    }
    case DELETE_COURIER: {
      const updatedEntities = { ...state.entities };

      delete updatedEntities[payload];

      return {
        ...state,
        entities: updatedEntities,
        ids: state.ids.filter(id => id !== payload),
      }
    }
    default:
      return state;
  }
}