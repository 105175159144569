import React, { useState, useRef } from 'react';
import { Form, Field } from 'react-final-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { LocationState } from 'history';

import { useToggle, useDidMount } from '../../hooks';
import API from '../../api/api';
import { getCookie } from '../../utils';
import { ROUTES } from '../../config/constants';
import { ILoginParams } from '../../interfaces/interfaces';

import './LoginPage.scss';
import GlobalPreloader from '../GlobalPreloader/GlobalPreloader';
import FormTextInput from '../../components/FormTextInput/FormTextInput';
import Spinner from '../../components/Spinner';


interface ILoginPageProps {
  history: LocationState,
}

const LoginPage = (props: ILoginPageProps) => {
  const [authInfo, setAuthInfo] = useState<{
    errorMsg: string,
    loginPending: boolean,
  }>({
    errorMsg: '',
    loginPending: false
  })
  const [pagePending, setPagePending] = useState(true);

  const [isRecaptchaValid, toggleRecaptchaValid] = useToggle(false); //TODO: return false

  const inputRef = useRef(null);
  const recaptchaRef = useRef(null);

  useDidMount(() => {
    const initApp = async () => {
      await API.getIsIAmLoggedIn()
        .then(() => {
          localStorage.isLoggedIn = 1;
          delete localStorage.isAbilityLoginToAdmin;

          props.history.push(ROUTES.main);
        })
        .catch((err) => {
          console.log(err);
          setPagePending(false);
        })
    };

    if (process.env.NODE_ENV === 'development') {
      if (localStorage.isLoggedIn) {
        props.history.push(ROUTES.main);
      }
      else {
        initApp();
      }
    }
    else if (!+localStorage.isAbilityLoginToAdmin) {
      setPagePending(false);
      console.log('fefefefefefefe', localStorage.isAbilityLoginToAdmin)
      delete localStorage.isAbilityLoginToAdmin;
    }
    else if (!!getCookie('isAuthenticated')) {
      props.history.push(ROUTES.main);
    }
    else {
      setPagePending(false);
    }
  });

  const loginUser = (values: ILoginParams, history: LocationState) => {
    setAuthInfo({ ...authInfo, loginPending: true });

    API.loginUser(values)
      .then(res => {
        if (res.data.success === 1 && res.data.token.length > 0) {
          const userId = res.data.user.id;

          localStorage.isLoggedIn = 1; // only for development mode
          localStorage.currentUserId = userId;

          // history.push(ROUTES.main);
          console.log('history', history)
          window.location.href = ROUTES.main;
        }
        else {
          setAuthInfo({
            loginPending: false,
            errorMsg: res.data.message
          });
        }
      })
      .catch(err => {
        console.dir(err);

        setAuthInfo({
          loginPending: false,
          errorMsg: err.response.data.message || 'Network error'
        });
      })
  };

  const onSubmit = (values: ILoginParams) => {
    const newValues = {
      ...values,
      timezone: String(new Date().getTimezoneOffset() / -60),
    }
    console.log('values', newValues);
    return loginUser(newValues, props.history);
  };


  if (pagePending) {
    return <GlobalPreloader />;
  }

  return (
    <div className="login">

      <div className="login__container">
        <aside className="login__info">
          <h1 className="login__title">
            Admin Module
          </h1>

          <h2 className="login__subtitle">
            Please Enter Your Username
          </h2>

          <div className="login__text">Updates in 0.38 Beta</div>

          <ul className="login__info-list">
            <li className="login__info-list-item">Cancel booking only with reason</li>
            <li className="login__info-list-item">New clients widget feature</li>
            <li className="login__info-list-item">Modification of the Address Book form</li>
            <li className="login__info-list-item">Links Labels</li>
            <li className="login__info-list-item">Modification of the logic for updating contacts from fast mail</li>
            <li className="login__info-list-item">Clients Active Filter</li>
            <li className="login__info-list-item">Global chat search in another place</li>
            <li className="login__info-list-item">Extension of the Telegram Messages component</li>
            <li className="login__info-list-item">Don`t show service messages in the general stream</li>
            <li className="login__info-list-item">Show chat with the agent in the buffer</li>
          </ul>

          <p className="login__text-bottom">0.38 Beta for Diva</p>
        </aside>

        <Form
          onSubmit={onSubmit}
          validate={validate}
        >
          {({ handleSubmit, submitting, pristine }) => (
            <form
              className="login__form"
              onSubmit={handleSubmit}
            >

              <div className={authInfo.errorMsg ? "login__error" : "login__error--hidden"} role="alert">
                {authInfo.errorMsg}
              </div>

              <div className="login__form-field">
                <span className="login__label">Username</span>

                <div className="login__input-wrap">
                  <Field
                    name="username"
                    inputRef={inputRef}
                    autoFocus
                    tabIndex={1}
                    errorInputClass="login__input--error"
                    errorClass="login__error"
                    component={FormTextInput}
                    placeholder="Username"
                    className="login__input"
                  />
                </div>
              </div>

              <div className="login__form-field">
                <span className='login__label'>Password</span>
                <div className="login__input-wrap">
                  <Field
                    name="password"
                    component="input"
                    type="password"
                    tabIndex={2}
                    placeholder="Password"
                    className="login__input"
                  />
                </div>
              </div>

              <div className="login__recaptcha">
                <ReCAPTCHA
                  sitekey="6LcTJLUUAAAAAJ9lgSjRKy0ElVzqt_KxDvroGTdF" // TODO: move to config
                  onChange={() => toggleRecaptchaValid()}
                  ref={recaptchaRef}
                />
              </div>

              <div>
                <button
                  type="submit"
                  value="Log In"
                  tabIndex={3}
                  className="login__btn"
                  disabled={submitting || pristine || !isRecaptchaValid || authInfo.loginPending}
                >
                  {authInfo.loginPending
                    ? <span className="login__spinner">
                      <Spinner />
                    </span>
                    : "Login"
                  }
                </button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};


const validate = ({ username, password }: any) => {
  const errors: any = {};

  if (!username || !username.trim().length) {
    errors.username = 'Enter username';
  }
  if (!password || !password.trim().length) {
    errors.password = 'Enter the password'
  }
  return errors
};

export default LoginPage;