import React, { useEffect, useState, useCallback } from 'react';
import ReactCalendar from 'react-calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { useToggle, usePrevious, useDidMount } from '../../hooks';

import { ICalendarProps, TMenuItems } from './Calendar.types';

import './Calendar.scss';
import getDateByTimezoneOffset from '../../utils/getDateByTimezoneOffset';

export const ALL_TIME = 'All Time';
export const TODAY = 'Today';
export const YESTERDAY = 'Yesterday';
export const THIS_WEEK = 'This Week';
export const THIS_MONTH = 'This Month';
export const LAST_MONTH = 'Last Month';
export const CUSTOM_RANGE = 'Custom Range';


const Calendar = (props: ICalendarProps) => {
  const {
    date,
    userTimezone = 0,
    hiddenMenuItems,
  } = props;

  const twentyFourHours = 86400000;

  const todayByTimezone = getDateByTimezoneOffset(userTimezone).setHours(0, 0, 0, 0) -
    (userTimezone - (new Date().getTimezoneOffset() * (-1))) * 60000;

  const yesterdayByTimezone = todayByTimezone - twentyFourHours;

  const [availableMenuItems, setAvailableMenuItems] = useState<string[]>([]);
  const [activeMenuItem, setActiveMenuItem] = useState<string | null>(null);

  const [isActiveCustomRange, toggleActiveCustomRange] = useToggle(false);
  const [isSelectedCustomRange, toggleSelectedCustomRange] = useToggle(false);

  const prevActiveMenuItem = usePrevious(activeMenuItem);

  useDidMount(() => {
    getAvailableMenuItems()
  })

  useEffect(() => { // Setting active menu item
    if (isActiveCustomRange) {
      setActiveMenuItem(CUSTOM_RANGE);
    }
    else if (!date) {
      setActiveMenuItem(ALL_TIME)
    }
    else if (date === todayByTimezone) {
      setActiveMenuItem(TODAY);
    }
    else if (date === yesterdayByTimezone) {
      setActiveMenuItem(YESTERDAY);
    }
    else if (JSON.stringify(getWeekRange()) === JSON.stringify(date)) {
      setActiveMenuItem(THIS_WEEK);
    }
    else if (JSON.stringify(getMonthRange(true)) === JSON.stringify(date)) {
      setActiveMenuItem(THIS_MONTH);
    }
    else if (JSON.stringify(getMonthRange()) === JSON.stringify(date)) {
      setActiveMenuItem(LAST_MONTH);
    }
    else if (Array.isArray(date)) {
      setActiveMenuItem(CUSTOM_RANGE);
      toggleActiveCustomRange(true);
      toggleSelectedCustomRange(true);
    }
    else {
      setActiveMenuItem('Custom Date');
    }
  }, [date, isActiveCustomRange]);

  const getAvailableMenuItems = () => {
    const availableItems = hiddenMenuItems ? menuItems.filter(item => !hiddenMenuItems.includes(item)) : menuItems;

    setAvailableMenuItems(availableItems)
  }

  const getDateForCalendarView = useCallback((): Date | Date[] | undefined => {
    const isTogglingToCustomRange = activeMenuItem === CUSTOM_RANGE && (prevActiveMenuItem !== activeMenuItem && prevActiveMenuItem !== null);

    if (!date || isTogglingToCustomRange) {
      return undefined;
    }
    if (Array.isArray(date)) {
      const startRangeDateByTimezone = getDateByTimezoneOffset(userTimezone, +new Date(date[0]));
      const endRangeDateByTimezone = getDateByTimezoneOffset(userTimezone, +new Date(date[1]));

      return [startRangeDateByTimezone, endRangeDateByTimezone];
    }
    return new Date(getDateByTimezoneOffset(userTimezone, +new Date(date)));
  }, [date, activeMenuItem]);

  const handleCalendarDateChange = (date: Date | Date[] | undefined) => {
    if (date && date instanceof Date) {
      if (isActiveCustomRange) {
        toggleActiveCustomRange()
      }

      props.onChange(new Date(date).getTime() - (userTimezone - (new Date(date).getTimezoneOffset() * (-1))) * 60000);

    }
    else if (Array.isArray(date)) {
      const startRangeDateByTimezone: number = new Date(date[0]).getTime() - (userTimezone - (new Date(date[0]).getTimezoneOffset() * (-1))) * 60000;
      const endRangeDateByTimezone: number = new Date(date[1]).getTime() - (userTimezone - (new Date(date[1]).getTimezoneOffset() * (-1))) * 60000;

      props.onChange([startRangeDateByTimezone, endRangeDateByTimezone]);
    }
  };

  const getWeekRange = (): number[] => { // Current Week 
    const dateByTimezoneOffset = getDateByTimezoneOffset(userTimezone, todayByTimezone);

    const currentYearByTimezone = dateByTimezoneOffset.getFullYear();
    const currentMonthByTimezone = dateByTimezoneOffset.getMonth();
    const currentDayOfMonthByTimezone = dateByTimezoneOffset.getDate();
    let currentDayOfWeekByTimezone = dateByTimezoneOffset.getDay();

    if (currentDayOfWeekByTimezone === 0) currentDayOfWeekByTimezone = 7;

    const firstDayOfWeek = currentDayOfMonthByTimezone - currentDayOfWeekByTimezone + 1;

    const firstDayOfWeekByTimezone: number = new Date(currentYearByTimezone, currentMonthByTimezone, firstDayOfWeek).getTime()
      - (userTimezone - (new Date(currentYearByTimezone, currentMonthByTimezone, firstDayOfWeek).getTimezoneOffset() * (-1))) * 60000;

    const currentDay = todayByTimezone + 86400000 - 1;

    return [firstDayOfWeekByTimezone, currentDay];
  }

  const getMonthRange = (isCurrentMonth?: boolean): number[] => { // Current Month or  Last Month
    let currentYearByTimezone = getDateByTimezoneOffset(userTimezone, todayByTimezone).getFullYear();
    let currentMonthByTimezone = getDateByTimezoneOffset(userTimezone, todayByTimezone).getMonth();

    // previous month was in last year
    if (!isCurrentMonth && currentMonthByTimezone === 0) {
      currentYearByTimezone -= 1;
      currentMonthByTimezone = 11;
    }
    // previous month
    else if (!isCurrentMonth) {
      currentMonthByTimezone -= 1;
    }

    const firstDayOfMonthByTimezone: number = new Date(currentYearByTimezone, currentMonthByTimezone, 1).getTime()
      - (userTimezone - (new Date(currentYearByTimezone, currentMonthByTimezone, 1).getTimezoneOffset() * (-1))) * 60000;

    // this date is last day of month, but for server we need to send last day of month + 23h59m59s to include this day in range too;
    const lastDayOfMonthByTimezone: number = new Date(currentYearByTimezone, currentMonthByTimezone + 1, 0).getTime()
      - (userTimezone - (new Date(currentYearByTimezone, currentMonthByTimezone + 1, 0).getTimezoneOffset() * (-1))) * 60000 + 86400000 - 1;

    const currentDay = todayByTimezone + 86400000 - 1;

    const dateRange = isCurrentMonth ? currentDay : lastDayOfMonthByTimezone;

    return [firstDayOfMonthByTimezone, dateRange];
  }

  const selectMenuItem = (name: string): void => {
    if (activeMenuItem === CUSTOM_RANGE && name !== activeMenuItem) {
      toggleActiveCustomRange(false);
    }

    switch (name) {
      case ALL_TIME:
        props.onChange(null);
        break;
      case TODAY:
        props.onChange(todayByTimezone);
        break;
      case YESTERDAY:
        props.onChange(yesterdayByTimezone);
        break;
      case THIS_WEEK:
        props.onChange(getWeekRange());
        break;
      case THIS_MONTH:
        props.onChange(getMonthRange(true));
        break;
      case LAST_MONTH:
        props.onChange(getMonthRange());
        break;
      case CUSTOM_RANGE:
        toggleActiveCustomRange();
        break;
    }
  }

  const getClassName = (name: string): string => activeMenuItem === name
    ? 'calendar-menu__item calendar-menu__item--active'
    : 'calendar-menu__item';

  const menuItems: TMenuItems[] = [TODAY, YESTERDAY, THIS_WEEK, THIS_MONTH, LAST_MONTH, CUSTOM_RANGE];

  if (!props.isCannotBeNull) {
    menuItems.unshift(ALL_TIME);
  }

  return (
    <div className='calendar'>
      <FontAwesomeIcon
        icon={faTimes}
        className='calendar__close'
        onClick={props.onClose}
      />

      <ReactCalendar
        onChange={handleCalendarDateChange}
        value={getDateForCalendarView()}
        selectRange={isActiveCustomRange}
        maxDate={new Date(getDateByTimezoneOffset(userTimezone))}
      />

      <ul className="calendar-menu">
        {availableMenuItems.map(item =>
          <li
            key={item}
            onClick={() => selectMenuItem(item)}
            className={getClassName(item)}
          >
            {isActiveCustomRange && isSelectedCustomRange &&
              <div
                className="calendar-menu__item-button"
                onClick={() => Array.isArray(date) && props.onChange(date[0])}
              >
                <FontAwesomeIcon icon={faTimes} className="calendar-menu__item-button--close" />
              </div>}

            <span>{item}</span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Calendar;