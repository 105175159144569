import { Dispatch } from "redux";

import API from '../../api/api';
import { IAction, IOperator } from "../../interfaces/interfaces";
import { IUserState } from "./types";
import { IPermission } from "../../containers/SystemRolesSettings/SystemRolesSettings.types";
import { getAllOperators } from "./operators";
import { setGridLayouts } from './widgets';


export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_PERMISSIONS = 'GET_USER_PERMISSIONS';

export const initApp = (user: IOperator) => async (dispatch: Dispatch<any>) => {
  let userPermissions: string[] = [];

  dispatch(getUserInfo(user));

  await API.getPermissions(0, user.role, false, true)
    .then(res => userPermissions = res.data.map((permission: IPermission) => permission.name))
    .catch(console.log);

  dispatch(getUserPermissions(userPermissions));
  dispatch(setGridLayouts())

  dispatch(getAllOperators(user.id));
};

export const getUserInfo = (user: IOperator) => (dispatch: Dispatch): void => {
  dispatch({
    type: GET_USER_INFO,
    payload: user,
  });
};

export const getUserPermissions = (permissions: string[]) => (dispatch: Dispatch): void => {
  dispatch({
    type: GET_USER_PERMISSIONS,
    payload: permissions,
  });
};


const initialState: IUserState = {
  permissions: [],
  user: {} as IOperator,
};


export default (state = initialState, action: IAction) => {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_PERMISSIONS: {
      return {
        ...state,
        permissions: payload,
      }
    }
    case GET_USER_INFO: {
      return {
        ...state,
        user: payload,
      }
    }
    default:
      return state;
  }
}