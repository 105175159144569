import { combineReducers } from "redux";

import activeWindowsReducer from './ducks/activeWindows'
import operatorsReducer from './ducks/operators'
import couriersReducer from './ducks/couriers';
import cronJobsReducer from "./ducks/cronJobs";
import userReducer from "./ducks/user";
import widgetsReducer from "./ducks/widgets";


const rootReducer = combineReducers({
  activeWindows: activeWindowsReducer,
  operators: operatorsReducer,
  couriers: couriersReducer,
  cronJobs: cronJobsReducer,
  user: userReducer,
  widgets: widgetsReducer,
});

export default rootReducer;