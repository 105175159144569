import React, { useRef, useLayoutEffect, MutableRefObject, ReactType } from 'react';
import Scrollbars from 'react-custom-scrollbars';

import { useDidMount, usePrevious, useDidUpdate } from '../../hooks';
import Spinner from '../Spinner';


interface IListProps {
  list: any[] | [],
  limit: number,
  scrollInitialPosition: 'top' | 'bottom',
  listItem: ReactType,
  mode?: string,
  loadMore?: (offset: number) => void,
  listLoadPending?: boolean,
  classPrefix?: string,
  listItemProps?: any,
  listItemKey?: string;
};


const List = (props: IListProps) => {
  console.log("LIST RENDER");

  const {
    list = [],
    limit,
    scrollInitialPosition = 'top',
    mode,
    classPrefix,
    listItemKey = 'id',
    listItem: ListItem,
    listLoadPending,
  } = props;

  const listRef: React.RefObject<any> = useRef(null);

  const expectedListLength: MutableRefObject<number> = useRef(limit);
  const scrollPosition: MutableRefObject<number> = useRef(0);

  const prevListLength = usePrevious(list.length);

  useDidMount(() => {
    if (scrollInitialPosition === 'bottom') {
      listRef.current.scrollToBottom();
    }

    expectedListLength.current = list.length <= limit
      ? limit
      : list.length;
  });

  useDidUpdate(() => {
    expectedListLength.current = limit;

    if (listRef.current) {
      listRef.current.scrollTop(0);
    }
  }, [mode]);

  useLayoutEffect(() => {
    if (!prevListLength && list.length && scrollInitialPosition === 'bottom') {
      listRef.current.scrollToBottom();
    }
    if (prevListLength && list.length !== prevListLength && scrollInitialPosition === 'bottom') {       // save scroll on last item if we scroll to top

      listRef.current.scrollTop(listRef.current.getScrollHeight() - scrollPosition.current);
    }
    // one item was removed from list
    if (prevListLength && (prevListLength === list.length + 1)) {
      expectedListLength.current -= 1;
    }

    // one item was added to list
    // else if (prevListLength && (prevListLength === list.length - 1)) {
    //   expectedListLength.current += 1;
    // }
  }, [list.length, scrollInitialPosition]);


  const handleScroll = (e: React.SyntheticEvent<HTMLDivElement>): void => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    const scrollBottom = scrollHeight - scrollTop - clientHeight;

    if (scrollInitialPosition === 'top' && scrollBottom === 0 && expectedListLength.current === list.length) {
      expectedListLength.current = list.length + limit;

      props.loadMore && props.loadMore(list.length);    // pass offset to loadMore func
    }
    else if (scrollInitialPosition === 'bottom' && scrollTop === 0 && expectedListLength.current === list.length) {
      expectedListLength.current = list.length + limit;

      scrollPosition.current = scrollHeight - scrollTop;

      props.loadMore && props.loadMore(list.length);
    }
  };

  return (
    <div className={classPrefix + "__list-container"}>
      {listLoadPending
        ? <div className={classPrefix + "__load-wrap"}>
          <Spinner size='30px' />
        </div>
        : (
          !!list.length
            ? (
              <Scrollbars
                onScroll={handleScroll}
                autoHide
                ref={listRef}
                renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />} >

                <ul className={classPrefix + "__list"}>
                  {list.map(item => {
                    return <ListItem
                      key={item[listItemKey] || item}
                      item={item}

                      {...(
                        typeof props.listItemProps === 'function'
                          ? props.listItemProps(item)
                          : props.listItemProps
                      )}

                      className={classPrefix + "__item"} />;
                  })}
                </ul>
              </Scrollbars>
            )
            : (
              <div className={classPrefix + '__no-items'}>
                (no items)
              </div>
            )
        )
      }
    </div>
  );
};

export default List;