import React, { useEffect, useState } from 'react';

import './TimeIntervalsSwitcher.scss'
import { classModifier } from '../../utils';

export const DAY = 'day';
export const WEEK = 'week';
export const MONTH = 'month';
export const SUM = 'sum';

export type TimeInterval = typeof DAY | typeof WEEK | typeof MONTH | typeof SUM;

interface IProps {
  timeInterval: TimeInterval;
  onChange: (interval: TimeInterval) => any;
  date: null | number | number[],
  isSumButton?: boolean,
  className?: string;
}

const TimeIntervalsSwitcher = (props: IProps) => {
  const {
    timeInterval,
    onChange,
    className = '',
    date,
    isSumButton,
  } = props;

  const isEnabled = Array.isArray(date) && date.length > 1;

  const [prevTimeInterval, setPrevTimeInterval] = useState<TimeInterval>(DAY);

  useEffect(() => {
    if (Array.isArray(date) && date.length <= 1) onChange(DAY);
  }, [date])

  const handleClick = (interval: TimeInterval) => {
    if (timeInterval === SUM && interval === SUM) {
      onChange(prevTimeInterval);
    }
    else {
      if (interval !== SUM) setPrevTimeInterval(interval);

      onChange(interval);
    }
  }

  return (
    <div className={`time-intervals-switcher ${className}`}>
      <div className="time-intervals-switcher__intervals">
        <button
          className={classModifier('time-intervals-switcher__btn', [
            'interval',
            timeInterval === DAY && 'active'
          ])}
          onClick={() => handleClick(DAY)}
        >
          Day
        </button>

        <button
          className={classModifier('time-intervals-switcher__btn', [
            'interval',
            timeInterval === WEEK && 'active',
          ])}
          disabled={!isEnabled}
          onClick={() => handleClick(WEEK)}
        >
          Week
        </button>

        <button
          className={classModifier('time-intervals-switcher__btn', [
            'interval',
            timeInterval === MONTH && 'active',
          ])}
          disabled={!isEnabled}
          onClick={() => handleClick(MONTH)}
        >
          Month
        </button>
      </div>

      {isSumButton &&
        <button
          className={classModifier('time-intervals-switcher__btn', [
            'sum',
            timeInterval === SUM && 'active',
          ])}
          disabled={!isEnabled}
          onClick={() => handleClick(SUM)}
        >
          Selected
        </button>
      }
    </div>
  )
};

export default TimeIntervalsSwitcher;