import { Dispatch } from "redux";
import { Layouts } from "react-grid-layout";

import API from "../../api/api";

import {
  IWidgetsState,
} from "./types";
import {
  IAction,
} from "../../interfaces/interfaces";


export type CallOrMessageWidget = {
  incoming: number | null;
  outgoing: number | null;
};

export const UPDATE_CALLS_AND_MESSAGES_WIDGET = 'UPDATE_CALLS_AND_MESSAGES_WIDGET';

export const SET_GRID_LAYOUTS = 'SET_GRID_LAYOUTS';
export const UPDATE_GRID_LAYOUTS = 'UPDATE_GRID_LAYOUTS';
export const UPDATE_ACTIVE_WIDGETS = 'UPDATE_ACTIVE_WIDGETS';


export const updateCallsAndMessagesWidget = () => (dispatch: Dispatch): Promise<void> => {
  return API.getInteractionsCount()
    .then(res => {
      const calls: CallOrMessageWidget = {
        incoming: +res.data.incomingCallCount,
        outgoing: +res.data.outgoingCallCount,
      }
      const messages: CallOrMessageWidget = {
        incoming: +res.data.incomingMessageCount,
        outgoing: +res.data.outgoingMessageCount,
      }

      dispatch({
        type: UPDATE_CALLS_AND_MESSAGES_WIDGET,
        payload: {
          calls,
          messages,
        }
      });
    })
    .catch(console.log);
}

export const setGridLayouts = () => (dispatch: Dispatch) => {
  API.getUserWidgets()
    .then((res) => {
      const gridLayouts = res.data
        ? JSON.parse(res.data.widget_layouts)
        : null;
      const activeWidgets = res.data
        ? JSON.parse(res.data.active_widgets)
        : null;

      dispatch({
        type: SET_GRID_LAYOUTS,
        payload: {
          gridLayouts,
          activeWidgets,
        }
      });
    })
    .catch((e) => {
      console.log(e)
    });
};

export const updateGridLayouts = (newLayouts: Layouts) => (dispatch: Dispatch) => {
  API.updateWidgetLayouts(newLayouts)
    .then(() => {
      dispatch({
        type: UPDATE_GRID_LAYOUTS,
        payload: newLayouts,
      });
    })
    .catch(console.log);
};

export const updateActiveWidgets = (newWidgets: string[]) => (dispatch: Dispatch) => {
  API.updateActiveWidgets(newWidgets)
    .then(() => {
      dispatch({
        type: UPDATE_ACTIVE_WIDGETS,
        payload: newWidgets,
      });
    })
    .catch(console.log);
};


const initialState: IWidgetsState = {
  interactionsToday: {
    callsToday: {
      incoming: null,
      outgoing: null,
    },
    messagesToday: {
      incoming: null,
      outgoing: null,
    },
  },
  gridLayouts: null,
  activeWidgets: null,
  isPending: true,
};


export default (state = initialState, action: IAction) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_CALLS_AND_MESSAGES_WIDGET: {
      return {
        ...state,
        interactionsToday: {
          callsToday: payload.calls,
          messagesToday: payload.messages,
        },
      }
    }
    case SET_GRID_LAYOUTS: {
      if (!payload) {
        return state;
      }

      return {
        ...state,
        gridLayouts: payload.gridLayouts,
        activeWidgets: payload.activeWidgets,
        isPending: false,
      }
    }
    case UPDATE_GRID_LAYOUTS: {
      return {
        ...state,
        gridLayouts: payload,
      }
    }
    case UPDATE_ACTIVE_WIDGETS: {
      return {
        ...state,
        activeWidgets: payload,
      }
    }
    default:
      return state;
  }
}