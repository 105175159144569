import React, { Component } from 'react';

import { formatTime } from "../../utils";


class Timer extends Component {
  state = {
    timerId: null,
    counter: null,
    lastTick: null
  }

  componentDidMount() {
    const timerId = setInterval(() => this.timerTick(), 1000);

    this.timerStart(timerId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.timer !== this.props.timer) {
      if (this.state.timerId) {
        clearInterval(this.state.timerId);

        const timerId = setInterval(() => this.timerTick(), 1000);

        this.timerStart(timerId);
      }

    }
  }

  componentWillUnmount() {
    this.timerStop()
  }

  timerStart = (timerId) => {
    console.log('timerStart', timerId);

    this.setState({
      timerId,
      counter: Date.now() - this.props.timer,
      lastTick: Date.now()
    }, () => this.timerTick());
  }

  timerTick = () => {
    const now = Date.now();
    const diff = now - this.state.lastTick;

    this.setState((prevState) => {
      return {
        ...prevState,
        counter: prevState.counter + diff,
        lastTick: now
      }
    });

    this.props.onUpdate && this.props.onUpdate(this.state.counter);
  }

  timerStop = () => {
    this.props.onStop && this.props.onStop(this.state.counter);

    console.log('timerStop', this.state.timerId);
    clearInterval(this.state.timerId);
  }

  render() {
    return (
      <span className={this.props.className}>
        {formatTime(this.state.counter, this.props.hour12, this.props.isClockMode )}
      </span>
    )
  }
}

export default Timer;