import React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../redux/store';

import Timer from '../../components/Timer/Timer';
import { selectUserTimezone } from '../../redux/selectors/selectors';

interface ITimezoneClockProps {
  userTimezone: number;
  className: string;
  userHour12: boolean;
  timezone?: number;
}

const TimezoneClock = ({ userTimezone, className, timezone, userHour12 }: ITimezoneClockProps) => {
  const localTimezone = new Date().getTimezoneOffset() * (-1);

  const timezoneForClock = timezone !== undefined ? timezone : userTimezone;

  if ((!timezoneForClock && timezoneForClock !== 0) ||
    ((timezoneForClock === localTimezone) && timezone === undefined)
  ) {
    return null;
  }

  return (
    <button
      className={className}
    >
      <Timer
        timer={new Date().setHours(0, timezoneForClock * (-1) + localTimezone, 0, 0)}
        isClockMode
        hour12={userHour12}
      />
    </button>
  );
}

const mapStateToProps = (state: AppState) => ({
  userTimezone: selectUserTimezone(state),
  userHour12: state.user.user.isHour12,
});

export default connect(mapStateToProps)(TimezoneClock);