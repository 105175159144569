import { RefObject } from "react";
import { AnyObject } from "../interfaces/interfaces";

export default (ref: RefObject<HTMLElement>, data: AnyObject) => {
  if (ref.current){
    const styleParent = getComputedStyle(ref.current);
    let parentWidth = ref.current.clientWidth;
    
    if (styleParent.paddingRight && styleParent.paddingLeft){
      parentWidth = ref.current.clientWidth - parseInt(styleParent.paddingRight) - parseInt(styleParent.paddingLeft);
    }
  
    const children = ref.current.children as HTMLCollectionOf<HTMLElement>;
  
    const childrenLength: AnyObject = {};
  
    for (const child in children) {
      if (children.hasOwnProperty(child)) {
        const style = getComputedStyle(children[child]);

        if (style.marginLeft && style.marginRight) {
          childrenLength[child] =
            children[child].offsetWidth +
            parseInt(style.marginLeft) +
            parseInt(style.marginRight);
        }
      }
    };
  
    const usedKeys: string[] = [];
    let sortedArr: string[] = [];
    let sortedArrLen = 0;
  
    while (sortedArrLen <= Object.keys(childrenLength).length) {
      let line = [];
  
      for (const key in childrenLength) {
        const sum = line.reduce((acc, val) => acc + childrenLength[val], 0);
  
        if (sum + childrenLength[key] <= parentWidth && !usedKeys.includes(key)) {
          usedKeys.push(key);
          line.push(key);
        }
      }
  
      if (line.length) {
        sortedArrLen = sortedArrLen + line.length;
        sortedArr = sortedArr.concat(line);
      }
      else {
        break;
      }
    };
  
  
    return sortedArr.map((key: string) => {
      return data[key];
    });
  }
  else return [];

};