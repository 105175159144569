import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import API from '../../../api/api';
import { classModifier, getPrettyTimeFromSeconds } from '../../../utils';

import { IInternalCallsDurationWidgetProps } from '../Welcome.types';
import { AppState } from '../../../redux/store';

import Spinner from '../../../components/Spinner';
import { selectUserTimezone } from '../../../redux/selectors/selectors';


const InternalCallsDurationWidget = (props: IInternalCallsDurationWidgetProps) => {
  const {
    userTimezone,
  } = props;

  const [dateFilter, setDateFilter] = useState<'day' | 'week' | 'month'>('day');
  const [waitingTime, setWaitingTime] = useState<{ total: number, average: number }>({
    total: 0,
    average: 0,
  });

  const [isPending, setPending] = useState(true);

  useEffect(() => {
    setPending(true)

    API.getDurationOfInternalCalls(props.getDatesForInterval(dateFilter, userTimezone))
      .then((res) => {
        if (!res.data) return;

        setWaitingTime({
          total: res.data.total,
          average: res.data.average,
        });
      })
      .finally(() => setPending(false));
  }, [dateFilter, userTimezone]);

  return (
    <div className="welcome__item card">
      <h1 className="welcome__item-title">
        Internal Calls Duration
      </h1>

      <div className="incoming-calls-waiting-widget welcome__not-draggable">
        <div className="switch incoming-calls-waiting-widget__switch">
          <button
            className={classModifier('switch__btn', [dateFilter === 'day' && 'active'])}
            onClick={() => setDateFilter('day')}
          >
            Today
          </button>

          <button
            className={classModifier('switch__btn', [dateFilter === 'week' && 'active'])}
            onClick={() => setDateFilter('week')}
          >
            Week
          </button>

          <button
            className={classModifier('switch__btn', [dateFilter === 'month' && 'active'])}
            onClick={() => setDateFilter('month')}
          >
            Month
          </button>
        </div>

        <div className="welcome__data">
          {isPending
            ? (
              <div className="welcome__spinner-wrap">
                <Spinner />
              </div>
            )
            : (
              <div className="welcome__count-container">
                <div className='welcome__count-wrap'>
                  <span>Total</span>
                  <span className='welcome__count'>
                    {waitingTime.total
                      ? getPrettyTimeFromSeconds(waitingTime.total)
                      : 0
                    }
                  </span>
                </div>

                <div className='welcome__count-wrap'>
                  <span>Average</span>
                  <span className='welcome__count'>
                    {waitingTime.average
                      ? getPrettyTimeFromSeconds(waitingTime.average)
                      : 0
                    }
                  </span>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  userTimezone: selectUserTimezone(state),
});

export default connect(mapStateToProps)(InternalCallsDurationWidget);