import React, { useEffect } from 'react';

import './DropWrapper.scss';


interface IDropWrapperProps {
  dropWrapperRef: any;
  closeDropWrapper: (flag?: boolean) => void;
  className?: string;
  children?: any;
};

const DropWrapper = (props: IDropWrapperProps) => {
  const {
    dropWrapperRef,
    closeDropWrapper,
  } = props;

  useEffect(() => {
    const closeModalOnOutsideClick = (e: MouseEvent): void => {
      !dropWrapperRef.current.contains(e.target) && closeDropWrapper(false)
    };

    const closeModalOnKeyup = (e: KeyboardEvent): void => {
      e.key === 'Escape' && closeDropWrapper(false);
    };

    document.addEventListener('mousedown', closeModalOnOutsideClick);
    document.addEventListener('keyup', closeModalOnKeyup);

    return () => {
      document.removeEventListener('mousedown', closeModalOnOutsideClick);
      document.removeEventListener('keyup', closeModalOnKeyup);
    };
  }, []);

  return (
    <div className={props.className}>
      {props.children}
    </div>
  );
};

export default DropWrapper;