import React, { useCallback, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import API from '../../../../api/api';
import { LIMIT } from '../../../../config/constants';
import { playAudio } from '../../../../redux/ducks/activeWindows';

import { AppState } from '../../../../redux/store';
import { ICall, IContactInteractionEnum } from '../../../../interfaces/interfaces';
import {
  ILastCallsWidgetProps,
} from '../../Welcome.types';
import { IContactCardState } from '../../../../components/ContactCard/ContactCard.types';

import List from '../../../../components/List/List';
import LastCallsWidgetItem from './LastCallsWidgetItem';
import DropWrapper from '../../../../components/DropWrapper/DropWrapper';
import ContactCard from '../../../../components/ContactCard/ContactCard';
import Spinner from '../../../../components/Spinner';


const LastCallsWidget = (props: ILastCallsWidgetProps) => {
  const [calls, setCalls] = useState<ICall[]>([]);
  const [filter, setFilter] = useState<{ direction: null | string }>({
    direction: null,
  });
  const [contactCard, setContactCard] = useState<IContactCardState>({
    isOpen: false,
    contact: null,
  });
  const [isMount, setMount] = useState<boolean>(true);

  const contactCardRef = useRef(null);

  useEffect(() => {
    const config = {
      limit: LIMIT,
      offset: 0,
      type: filter.direction,
    };

    API.getLastCalls(config)
      .then(res => {
        setCalls(res.data);
      })
      .catch(console.log)
      .finally(() => setMount(false));
  }, [filter]);

  const loadMore = useCallback((offset: number) => {
    const config = {
      limit: LIMIT,
      offset,
      type: filter.direction,
    };

    API.getLastCalls(config)
      .then(res => {
        setCalls(prevCalls => ([...prevCalls, ...res.data]));
      })
      .catch(console.log);
  }, [filter]);

  const openContactCard = useCallback((card: IContactCardState) => setContactCard(card), []);

  const closeContactCard = useCallback(() => setContactCard({
    isOpen: false,
    contact: null,
  }), []);

  const playAudio = useCallback((audio: EventTarget) => props.playAudio(audio), []);

  const startFilteringByDirection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const direction: string | null = e.currentTarget.value === '0'
      ? null
      : e.currentTarget.value;

    setFilter({ ...filter, direction });
  }

  return (
    <div className='welcome__item card last-calls-widget'>
      {!!isMount &&
        <div className="welcome__spinner-wrap">
          <Spinner />
        </div>
      }

      <h2 className="welcome__item-title">
        Last Calls
      </h2>

      <div className='table welcome__not-draggable'>
        {contactCard.isOpen && contactCard.contact &&
          <div ref={contactCardRef}>
            <DropWrapper
              dropWrapperRef={contactCardRef}
              closeDropWrapper={closeContactCard}
            >
              <ContactCard contact={contactCard.contact} />
            </DropWrapper>
          </div>
        }

        <div className="table__header">
          <div className="table__header-block last-calls-widget__table-block-icon"></div>
          <div className="table__header-block last-calls-widget__table-block-client">Contact</div>
          <div className="table__header-block last-calls-widget__table-block-operator">Operator</div>
          <div className="table__header-block last-calls-widget__table-block-date">Date</div>
          <div className="table__header-block last-calls-widget__table-block-status">Status</div>
          <div className="table__header-block table__header-block--column last-calls-widget__table-block-direction">
            <span>Direction</span>

            <select
              defaultValue='0'
              className="select"
              onChange={startFilteringByDirection}
            >
              <option value='0'>all</option>
              <option value={IContactInteractionEnum.incoming}>{IContactInteractionEnum[IContactInteractionEnum.incoming]}</option>
              <option value={IContactInteractionEnum.outgoing}>{IContactInteractionEnum[IContactInteractionEnum.outgoing]}</option>
            </select>
          </div>
          <div className="table__header-block last-calls-widget__table-block-duration">Duration</div>
          <div className="table__header-block last-calls-widget__table-block-actions">Voicemail</div>
        </div>

        <List
          list={calls}
          listItem={LastCallsWidgetItem}
          classPrefix='table'
          limit={LIMIT}
          loadMore={loadMore}
          scrollInitialPosition='top'
          listItemProps={{
            openContactCard,
            playAudio,
          }}
          mode={String(filter.direction)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  operators: state.operators.entities,
});

const mapDispatchToProps = {
  playAudio,
};

export default connect(mapStateToProps, mapDispatchToProps)(LastCallsWidget);