import { lazy } from 'react';


const OperatorsSettings = lazy(() => import(/* webpackChunkName: "OperatorsSettingsChunk"*/ '../../../containers/OperatorsSettings/OperatorsSettings'));

const CouriersSettings = lazy(() => import(/* webpackChunkName: "OperatorsSettingsChunk"*/ '../../../containers/CouriersSettings/CouriersSettings'));

const ShortcutsSettings = lazy(() => import(/* webpackChunkName: "ShortcutsSettingsChunk"*/ '../../../containers/ShortcutsSettings/ShortcutsSettings'));

const RingtonesSettings = lazy(() => import(/* webpackChunkName: "RingtonesSettingsChunk"*/ '../../../containers/RingtonesSettings/RingtonesSettings'));

const VoicemailsSettings = lazy(() => import(/* webpackChunkName: "VoicemailsSettingsChunk"*/ '../../../containers/VoicemailsSettings/VoicemailsSettings'));

const CallsRecordsSettings = lazy(() => import(/* webpackChunkName: "CallsRecordsSettingsChunk"*/ '../../../containers/CallsRecordsSettings/CallsRecordsSettings'));

const OperatorsLogsSettings = lazy(() => import(/* webpackChunkName: "OperatorsLogsSettingsChunk"*/ '../../../containers/OperatorsLogsSettings/OperatorsLogsSettings'));

const CronJobsSettings = lazy(() => import(/* webpackChunkName: "CronJobsSettingsChunk"*/ '../../../containers/CronJobsSettings/CronJobsSettings'));

const SystemRolesSettings = lazy(() => import(/* webpackChunkName: "SystemRolesSettingsChunk"*/ '../../../containers/SystemRolesSettings/SystemRolesSettings'));

const StatisticSettings = lazy(() => import(/* webpackChunkName: "StatisticSettingsChunk"*/ '../../../containers/StatisticSettings/StatisticSettings'));

const Settings = lazy(() => import(/* webpackChunkName: "SettingsChunk" */ '../../../containers/Settings/Settings'));

const GlossarySettings = lazy(() => import(/* webpackChunkName: "GlossarySettingsChunk"*/ '../../../containers/GlossarySettings/GlossarySettings'));

const WebMasterSettings = lazy(() => import(/* webpackChunkName: "WebMasterSettingsChunk"*/ '../../../containers/WebMasterSettings/WebMasterSettings'));

const PhoneNumbersSettings = lazy(() => import(/* webpackChunkName: "PhonesSettingsChunk"*/ '../../../containers/PhoneNumbersSettings/PhoneNumbersSettings'));

const MsgTemplatesSettings = lazy(() => import(/* webpackChunkName: "MsgTemplatesSettingsChunk"*/ '../../../containers/MsgTemplatesSettings/MsgTemplatesSettings'));

const CallsLogSettings = lazy(() => import(/* webpackChunkName: "CallsLogSettings" */  '../../../containers/CallsLogSettings/CallsLogSettings'));

const SmsLogsSettings = lazy(() => import(/* webpackChunkName: "SmsLogsSettings" */ '../../../containers/SmsLogsSettings/SmsLogsSettings'));

const PublicApiSettings = lazy(() => import(/* webpackChunkName: "PublicApiSettings" */ '../../../containers/PublicApiSettings/PublicApiSettings'));

const BookingsSettings = lazy(() => import(/* webpackChunkName: "PublicApiSettings" */ '../../../containers/BookingsSettings/BookingsSettings'));

const ClientsRating = lazy(() => import(/* webpackChunkName: "ClientsRating" */ '../../ClientsRatingPage/ClientsRatingPage'));
const GirlsRating = lazy(() => import(/* webpackChunkName: "GirlsRating" */ '../../GirlsRatingPage/GirlsRatingPage'));
const OperatorsRating = lazy(() => import(/* webpackChunkName: "OperatorsRating" */ '../../OperatorsRatingPage/OperatorsRatingPage'));

const ContactsSettings = lazy(() => import(/* webpackChunkName: "ContactsSettingsChunk"*/ '../../../containers/ContactsSettings/ContactsSettings'));

const SessionsSettings = lazy(() => import(/* webpackChunkName: "SessionsSettingsChunk */ '../../../containers/SessionsSettings/SessionsSettings'));

const TagColorsSettings = lazy(() => import(/* webpackChunkName: "TagColorsSettingsChunk */ '../../../containers/TagColorsSettings/TagColorsSettings'));

export default {
  OperatorsSettings,
  CouriersSettings,
  ShortcutsSettings,
  RingtonesSettings,
  VoicemailsSettings,
  CallsRecordsSettings,
  OperatorsLogsSettings,
  CronJobsSettings,
  SystemRolesSettings,
  StatisticSettings,
  Settings,
  GlossarySettings,
  WebMasterSettings,
  PhoneNumbersSettings,
  MsgTemplatesSettings,
  CallsLogSettings,
  SmsLogsSettings,
  PublicApiSettings,
  BookingsSettings,
  ClientsRating,
  GirlsRating,
  OperatorsRating,
  ContactsSettings,
  SessionsSettings,
  TagColorsSettings,
}