import React from 'react';

import { classModifier } from '../../utils';

interface CollapseListItemProps {
  isOpen: boolean,
  isActive: boolean,
  children: JSX.Element,
  classPrefix: string
};


const CollapseListItem = (props: CollapseListItemProps) => {
  const {
    classPrefix = 'collapse',
    isOpen,
    isActive,
  } = props;

  return (
    <li 
      className={`${isActive
        ? classModifier(classPrefix, 'active')
        : classPrefix
      } ${isOpen
        ? classPrefix + '--open'
        : ''
      } ${classPrefix}--collapse-wrap
      `}
    >
      {props.children}
    </li>
  );
};

export default CollapseListItem;