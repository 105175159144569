import React, { Suspense, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { LocationState } from 'history';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import './config/api-config';
import API from "./api/api";
import { ROUTES, TITLE } from "./config/constants";
import { initApp } from "./redux/ducks/user";
import { useDidMount } from "./hooks";
import { deleteCookie, getCookie } from "./utils";
import capitalizeFirstLetter from "./utils/capitalizeFirstLetter";

import { IOperator } from "./interfaces/interfaces";

import "./App.css";
import MainPage from "./pages/MainPage/MainPage";
import NotFound from "./pages/NotFound/NotFound";
import LoginPage from "./pages/LoginPage/LoginPage";
import PrivateRoute from "./components/PrivateRoute";
import Welcome from "./containers/Welcome/Welcome";
import ChatSocket from "./components/ChatSocket/ChatSocket";
import AccessDenied from "./pages/AccessDenied/AccessDenied";
import GlobalPreloader from "./pages/GlobalPreloader/GlobalPreloader";
import WithPermissions from "./pages/MainPage/components/WithPermissions";
// import getCookie from "./utils/getCookie";

interface IPrivateRoutesProps {
  history: LocationState,
  initApp: (user: IOperator) => void,
}

const PrivateRoutes = (props: IPrivateRoutesProps) => {
  const [pagePending, setPagePending] = useState<boolean>(true);

  useEffect(() => {
    const pathName: string = props.history.location.pathname;
    const tabTitle = pathName.substring(pathName.lastIndexOf('/') + 1);

    if (tabTitle === "admin") {
      document.title = TITLE;
    }
    else if (tabTitle === "operators") {
      document.title = `Receptionists | ${TITLE}`;
    }
    else {
      document.title = `${capitalizeFirstLetter(tabTitle)} | ${TITLE}`;
    }
  }, [props.history.location]);

  useDidMount(() => {
    const initApp = async () => {
      await API.getIsIAmLoggedIn()
        .then(res => {
          const user: IOperator = res.data;

          if (!localStorage.isLoggedIn) {
            localStorage.isLoggedIn = 1;
          }

          localStorage.currentUserId = res.data.id;
          delete localStorage.isAbilityLoginToAdmin;

          setPagePending(false);
          console.log(res);

          props.initApp(user);
        })
        .catch((err) => {
          console.log('hahahah', err);
          console.log('hahahah3', err.response);

          if (
            err.response &&
            err.response.data &&
            err.response.data.code === 403 &&
            err.response.data.message === 'Access denied for admin page'
          ) {
            localStorage.isAbilityLoginToAdmin = 0;
            console.log('hello, no access');
          }
          else {
            deleteCookie('isAuthenticated');

            console.log('hello, cookie remove');

            delete localStorage.isLoggedIn;
            delete localStorage.currentUserId;

          }

          props.history.push(ROUTES.login);
        })
    }

    initApp();
  });

  if (pagePending) {
    return <GlobalPreloader />
  }

  return (
    <MainPage setPagePending={setPagePending}>
      <>
        <ChatSocket />

        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path={ROUTES.main} component={Welcome} />
            <Route exact path={ROUTES.operators} component={WithPermissions.OperatorsSettings} />
            <Route exact path={ROUTES.couriers} component={WithPermissions.CouriersSettings} />
            <Route exact path={ROUTES.glossary} component={WithPermissions.GlossarySettings} />
            <Route exact path={ROUTES.webMaster} component={WithPermissions.WebMasterSettings} />
            <Route exact path={ROUTES.shortcuts} component={WithPermissions.ShortcutsSettings} />
            <Route exact path={ROUTES.ringtones} component={WithPermissions.RingtonesSettings} />
            <Route exact path={ROUTES.voicemails} component={WithPermissions.VoicemailsSettings} />
            <Route exact path={ROUTES.callsRecords} component={WithPermissions.CallsRecordsSettings} />
            <Route exact path={ROUTES.operatorsLogs} component={WithPermissions.OperatorsLogsSettings} />

            <Route exact path={ROUTES.clientsRating} component={WithPermissions.ClientsRating} />
            <Route exact path={ROUTES.girlsRating} component={WithPermissions.GirlsRating} />
            <Route exact path={ROUTES.operatorsRating} component={WithPermissions.OperatorsRating} />

            <Route exact path={ROUTES.cronJobs} component={WithPermissions.CronJobsSettings} />
            <Route exact path={ROUTES.systemRoles} component={WithPermissions.SystemRolesSettings} />
            <Route exact path={ROUTES.msgTemplates} component={WithPermissions.MsgTemplatesSettings} />
            <Route exact path={ROUTES.statistics} component={WithPermissions.StatisticSettings} /> //
            <Route exact path={ROUTES.settings} component={WithPermissions.Settings} />
            <Route exact path={ROUTES.phoneNumbers} component={WithPermissions.PhoneNumbersSettings} />
            <Route exact path={ROUTES.callsLog} component={WithPermissions.CallsLogSettings} />
            <Route exact path={ROUTES.smsLogs} component={WithPermissions.SmsLogsSettings} />
            <Route exact path={ROUTES.publicApi} component={WithPermissions.PublicApiSettings} />
            <Route exact path={ROUTES.bookings} component={WithPermissions.BookingsSettings} />
            <Route exact path={ROUTES.contacts} component={WithPermissions.ContactsSettings} />
            <Route exact path={ROUTES.sessions} component={WithPermissions.SessionsSettings} />
            <Route exact path={ROUTES.tags} component={WithPermissions.TagColorsSettings} />

            <Route path={ROUTES.accessDenied} component={AccessDenied} />
            <Redirect to={ROUTES.main} />
          </Switch>
        </Suspense>
      </>
    </MainPage>
  )
};


const mapDispatchToProps = {
  initApp,
};

const PrivateRoutesConnected = connect(null, mapDispatchToProps)(PrivateRoutes);


const App = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.login} component={LoginPage} />

      <PrivateRoute
        path={ROUTES.main}
        component={PrivateRoutesConnected}
        isAuthenticated={process.env.NODE_ENV === 'development'
          ? !!localStorage.isLoggedIn
          : !!getCookie('isAuthenticated')
        }
        redirectLink={ROUTES.login}
      />


      <Route path={ROUTES.notFound} component={NotFound} />
      <Redirect to={ROUTES.notFound} />
    </Switch>
  );
};

// const mapStateToProps = (state: AppState) => ({
//   // isAuthenticated: state.auth.isAuthenticated,
// });

export default withRouter(App);
