import React, { useState, useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import API from '../../../../api/api';
import { LIMIT } from '../../../../config/constants';
import { playAudio } from '../../../../redux/ducks/activeWindows';

import { AppState } from '../../../../redux/store';
import { IMissedCall } from '../../../../interfaces/interfaces';
import { IMissedCallsWidgetProps } from '../../Welcome.types';
import { IContactCardState } from '../../../../components/ContactCard/ContactCard.types';

import List from '../../../../components/List/List';
import MissedCallsWidgetItem from './MissedCallsWidgetItem';
import DropWrapper from '../../../../components/DropWrapper/DropWrapper';
import ContactCard from '../../../../components/ContactCard/ContactCard';


const MissedCallsWidget = (props: IMissedCallsWidgetProps) => {
  const [missedCalls, setMissedCalls] = useState<IMissedCall[]>([]);
  const [filter, setFilter] = useState<{ status: string | null }>({
    status: null,
  })
  const [contactCard, setContactCard] = useState<IContactCardState>({
    isOpen: false,
    contact: null,
  });
  const [pending, setPending] = useState<boolean>(false);

  const contactCardRef = useRef(null);

  useEffect(() => {
    setPending(true);

    const config = {
      limit: LIMIT,
      offset: 0,
      status: filter.status
    };

    API.getMissedCalls(config)
      .then(({ data }) => {
        setMissedCalls(data);
      })
      .catch(console.log)
      .finally(() => {
        setPending(false);
      })
  }, [filter]);

  const loadMore = useCallback((offset: number) => {
    const config = {
      limit: LIMIT,
      offset,
      status: filter.status,
    };

    API.getMissedCalls(config)
      .then(({ data }) => {
        setMissedCalls(prevCalls => ([...prevCalls, ...data]));
      })
      .catch(console.log);
  }, [filter]);

  const openContactCard = useCallback((card: IContactCardState) => setContactCard(card), []);

  const closeContactCard = useCallback(() => setContactCard({
    isOpen: false,
    contact: null,
  }), []);

  const playAudio = useCallback((audio: EventTarget) => props.playAudio(audio), []);


  const filterByStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter({ ...filter, status: e.currentTarget.value });
  };

  return (
    <div className="welcome__item card missed-calls-widget">
      <h1 className="welcome__item-title">
        Missed Calls
      </h1>

      <div className="table calls-duration-widget welcome__not-draggable">
        {contactCard.isOpen && contactCard.contact &&
          <div ref={contactCardRef}>
            <DropWrapper
              dropWrapperRef={contactCardRef}
              closeDropWrapper={closeContactCard}
            >
              <ContactCard contact={contactCard.contact} />
            </DropWrapper>
          </div>
        }

        <div className="table__header">
          <div className="table__header-block missed-calls-widget__table-block-client">
            Contact
          </div>

          <div className="table__header-block missed-calls-widget__table-block-missed-date-time">
            Missed Date Time
          </div>

          <div className="table__header-block missed-calls-widget__table-block-resolved-after">
            Resolved After
          </div>

          <div className="table__header-block missed-calls-widget__table-block-operator">
            Operator
          </div>

          <div className="table__header-block missed-calls-widget__table-block-voicemail">
            Voicemail
          </div>

          <div className="table__header-block missed-calls-widget__table-block-status">
            <span>Status</span>

            <select
              className="select"
              onChange={filterByStatus}
            >
              <option value="">all</option>
              <option value="resolved">resolved</option>
              <option value="unresolved">unresolved</option>
            </select>
          </div>
        </div>

        <List
          list={missedCalls}
          listItem={MissedCallsWidgetItem}
          classPrefix='table'
          limit={LIMIT}
          loadMore={loadMore}
          scrollInitialPosition='top'
          listItemProps={{
            openContactCard,
            playAudio,
          }}
          listLoadPending={pending}
        />
      </div>
    </div>
  )
};

const mapStateToProps = (state: AppState) => ({
  operators: state.operators.entities,
});

const mapDispatchToProps = {
  playAudio,
};

export default connect(mapStateToProps, mapDispatchToProps)(MissedCallsWidget);