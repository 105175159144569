import React from 'react';

import './GlobalPreloader.scss';

import Spinner from '../../components/Spinner';

const GlobalPreloader = () => {
  return <div className="global-preloader">
    <div className="global-preloader__wrap">
      <Spinner className="global-preloader__spinner" />
    </div>
  </div>
};

export default GlobalPreloader;