export default (seconds: number, isShowHours: boolean = true): string => {
  let prettyHours: number | string = Math.floor(seconds / 3600);
  let prettyMinutes: number | string = Math.floor((seconds / 60) % 60);
  let prettySeconds: number | string = seconds % 60;

  if (prettyHours < 10) {
    prettyHours = '0' + prettyHours;
  }
  if (prettyMinutes < 10) {
    prettyMinutes = '0' + prettyMinutes;
  }
  if (prettySeconds < 10) {
    prettySeconds = '0' + prettySeconds;
  }

  return isShowHours
    ? `${prettyHours}:${prettyMinutes}:${prettySeconds}`
    : `${prettyMinutes}:${prettySeconds}`;
}