import getDateByTimezoneOffset from './getDateByTimezoneOffset';

export default (userTimezone: number, isCurrentMonth?: boolean): number[] => { // Current Month or  Last Month
  const todayByTimezone = getDateByTimezoneOffset(userTimezone).setHours(0, 0, 0, 0) -
    (userTimezone - (new Date().getTimezoneOffset() * (-1))) * 60000;

  let currentYearByTimezone = getDateByTimezoneOffset(userTimezone, todayByTimezone).getFullYear();
  let currentMonthByTimezone = getDateByTimezoneOffset(userTimezone, todayByTimezone).getMonth();

  // previous month was in last year
  if (!isCurrentMonth && currentMonthByTimezone === 0) {
    currentYearByTimezone -= 1;
    currentMonthByTimezone = 11;
  }
  // previous month
  else if (!isCurrentMonth) {
    currentMonthByTimezone -= 1;
  }

  const firstDayOfMonthByTimezone: number = new Date(currentYearByTimezone, currentMonthByTimezone, 1).getTime()
    - (userTimezone - (new Date(currentYearByTimezone, currentMonthByTimezone, 1).getTimezoneOffset() * (-1))) * 60000;

  // this date is last day of month, but for server we need to send last day of month + 23h59m59s to include this day in range too;
  const lastDayOfMonthByTimezone: number = new Date(currentYearByTimezone, currentMonthByTimezone + 1, 0).getTime()
    - (userTimezone - (new Date(currentYearByTimezone, currentMonthByTimezone + 1, 0).getTimezoneOffset() * (-1))) * 60000 + 86400000 - 1;

  const currentDay = todayByTimezone + 86400000 - 1;

  const dateRange = isCurrentMonth ? currentDay : lastDayOfMonthByTimezone;

  return [firstDayOfMonthByTimezone, dateRange];
}
