import React, { useState } from "react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faColumns,
  faUsers,
  faBookOpen,
  faTasks,
  faKeyboard,
  faMicrophoneAlt,
  faChartBar,
  faClock,
  faChevronDown,
  faChevronUp,
  faPhoneAlt,
  faMusic,
  faFileAlt,
  faComments,
  faCog,
  faAddressBook,
  faStar,
  faAddressCard,
  // faKeyboard,
  faUserClock,
  faPalette,
} from "@fortawesome/free-solid-svg-icons";
import { faAppStore } from "@fortawesome/free-brands-svg-icons";

import { ROUTES, PERMISSIONS } from "../../config/constants";
import { useToggle } from "../../hooks";
import { classModifier } from "../../utils";

import "./Sidebar.scss";
import SvgIcon from "../../components/SvgIcon";
import CheckPerms from "../../components/CheckPerms/CheckPerms";
import CollapseListItem from "../../components/CollapseListItem/CollapseListItem";
import TimezoneClock from "./TimezoneClock";

const ratingCollapseRoutes = [
  ROUTES.clientsRating,
  ROUTES.girlsRating,
  ROUTES.operatorsRating,
];

const templateCollapseRoutes = [
  ROUTES.shortcuts,
  ROUTES.msgTemplates,
];

const collapsibleListItems = {
  ratings: 'ratings',
  templates: 'templates',
  contacts: 'contacts',
};

const contactsCollapsedRoutes = [
  ROUTES.contacts,
  // ROUTES.contactsBlacklist,
];

const Sidebar = (props: RouteComponentProps) => {
  const [isOpen, setIsOpen] = useToggle(true);
  const [isUnfolded, toggleUnfolded] = useToggle(false);
  const [openMenuItem, setOpenMenuItem] = useState<string>('')

  console.log("Sidebar Render");

  const getClassName = (pathname: string): string => {
    if ([
      ...ratingCollapseRoutes,
      ...templateCollapseRoutes,
      ...contactsCollapsedRoutes
    ].includes(pathname)) {
      return [
        ...ratingCollapseRoutes,
        ...templateCollapseRoutes,
        ...contactsCollapsedRoutes
      ].includes(props.location.pathname)
        ? "collapse-list-item collapse-list-item--active"
        : "collapse-list-item";
    }

    return props.location.pathname === pathname
      ? "sidebar__list-item sidebar__list-item--active"
      : "sidebar__list-item";
  };

  const isShowText = isOpen || isUnfolded;

  return (
    <aside className={classModifier('sidebar', [isOpen && 'open'])}>
      <header className="sidebar__header">
        <div
          className="sidebar__burger-wrap"
          onClick={() => setIsOpen()}
        >
          <div className={classModifier('sidebar__burger', [isOpen && 'active'])}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <TimezoneClock className={`sidebar__clock ${isOpen ? "sidebar__clock--open" : ""}`} />

        {isOpen && (
          <>
            <Link to="/admin" className="sidebar__link">
              <h1 className="sidebar__title">Commdiva</h1>
            </Link>
          </>
        )}

      </header>

      <nav
        className={classModifier("sidebar__nav", [
          !isOpen && isUnfolded && 'unfolded',
        ])}
        onMouseEnter={toggleUnfolded}
        onMouseLeave={() => toggleUnfolded(false)}
      >
        <ul className="sidebar__list">

          <li
            className={getClassName(ROUTES.main)}
            onClick={() => setOpenMenuItem(ROUTES.main)}
          >
            <Link to={ROUTES.main}>
              <FontAwesomeIcon icon={faColumns} />
              {isShowText && <span>Dashboard</span>}
            </Link>
          </li>

          <CheckPerms permission={PERMISSIONS.getAllOperators}>
            <li
              className={getClassName(ROUTES.operators)}
              onClick={() => setOpenMenuItem(ROUTES.operators)}
            >
              <Link to={ROUTES.operators}>
                <SvgIcon icon="operator" height={16} />
                {isShowText && <span>Receptionists</span>}
              </Link>
            </li>
          </CheckPerms>

          <CheckPerms permission={PERMISSIONS.getAllCouriers}>
            <li
              className={getClassName(ROUTES.couriers)}
              onClick={() => setOpenMenuItem(ROUTES.couriers)}
            >
              <Link to={ROUTES.couriers}>
                <SvgIcon icon="courier" height={16} />
                {isShowText && <span>Couriers</span>}
              </Link>
            </li>
          </CheckPerms>

          <CheckPerms permission={PERMISSIONS.getRingtones}>
            <li
              className={getClassName(ROUTES.ringtones)}
              onClick={() => setOpenMenuItem(ROUTES.ringtones)}
            >
              <Link to={ROUTES.ringtones}>
                <FontAwesomeIcon icon={faMusic} />
                {isShowText && <span>Ringtones</span>}
              </Link>
            </li>
          </CheckPerms>

          <CheckPerms permission={PERMISSIONS.getVoicemails}>
            <li
              className={getClassName(ROUTES.voicemails)}
              onClick={() => setOpenMenuItem(ROUTES.voicemails)}
            >
              <Link to={ROUTES.voicemails}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 314 314"
                  width="18px"
                  height="18px"
                >
                  <path d="M289 35H25C11 35 0 46 0 60v194c0 14 11 25 25 25h264c14 0 25-11 25-25V60c0-14-11-25-25-25zM72 265l15-28c3-7 12-12 19-12h102c7 0 16 5 19 12l15 28H72zm228-11c0 6-5 11-11 11h-32l-17-34c-6-12-20-20-32-20H106c-12 0-26 8-32 20l-17 34H25c-6 0-11-5-11-11V60c0-6 5-11 11-11h264c6 0 11 5 11 11v194z" />
                  <path d="M87 117a40 40 0 100 80 40 40 0 000-80zm0 66a26 26 0 110-52 26 26 0 010 52zM227 117a40 40 0 100 80 40 40 0 000-80zm0 66a26 26 0 110-52 26 26 0 010 52zM87 91h140a7 7 0 100-14H87a7 7 0 100 14z" />
                </svg>
                {isShowText && <span>Voicemails</span>}
              </Link>
            </li>
          </CheckPerms>

          <CheckPerms permission={PERMISSIONS.getCallsRecords}>
            <li
              className={getClassName(ROUTES.callsRecords)}
              onClick={() => setOpenMenuItem(ROUTES.callsRecords)}
            >
              <Link to={ROUTES.callsRecords}>
                <FontAwesomeIcon icon={faMicrophoneAlt} />
                {isShowText && <span>Calls Records</span>}
              </Link>
            </li>
          </CheckPerms>

          <CheckPerms permission={PERMISSIONS.getOperatorsLogs}>
            <li
              className={getClassName(ROUTES.operatorsLogs)}
              onClick={() => setOpenMenuItem(ROUTES.operatorsLogs)}
            >
              <Link to={ROUTES.operatorsLogs}>
                <FontAwesomeIcon icon={faChartBar} />
                {isShowText && <span>Operators Logs</span>}
              </Link>
            </li>
          </CheckPerms>

          <CheckPerms
            permission={[
              PERMISSIONS.clientsRating,
              PERMISSIONS.girlsRating,
              PERMISSIONS.operatorsRating
            ]}
          >
            <CollapseListItem
              classPrefix="sidebar__list-item"
              isOpen={openMenuItem === collapsibleListItems.ratings}
              isActive={ratingCollapseRoutes.includes(props.location.pathname)}
            >
              <>
                <div
                  className="sidebar__list-item--collapse"
                  onClick={() => {
                    !isUnfolded && setIsOpen(true)
                    setOpenMenuItem(openMenuItem === collapsibleListItems.ratings
                      ? ''
                      : collapsibleListItems.ratings)
                  }}
                >
                  <FontAwesomeIcon icon={faStar} />
                  {isShowText &&
                    <span>
                      Ratings
                      <FontAwesomeIcon
                        icon={openMenuItem === collapsibleListItems.ratings ? faChevronUp : faChevronDown}
                        className="sidebar__list-item--collapse-icon"
                      />
                    </span>
                  }
                </div>
                {isShowText &&
                  <ul className="collapse-list">
                    <CheckPerms permission={PERMISSIONS.clientsRating}>
                      <li className={props.location.pathname === ROUTES.clientsRating
                        ? getClassName(ROUTES.clientsRating)
                        : 'collapse-list-item'
                      }>
                        <Link to={ROUTES.clientsRating} >
                          Clients Rating
                        </Link>
                      </li>
                    </CheckPerms>

                    <CheckPerms permission={PERMISSIONS.girlsRating} >
                      <li className={props.location.pathname === ROUTES.girlsRating
                        ? getClassName(ROUTES.girlsRating)
                        : 'collapse-list-item'
                      }>
                        <Link to={ROUTES.girlsRating} >
                          Girls Rating
                        </Link>
                      </li>
                    </CheckPerms>

                    <CheckPerms permission={PERMISSIONS.operatorsRating} >
                      <li className={props.location.pathname === ROUTES.operatorsRating
                        ? getClassName(ROUTES.operatorsRating)
                        : 'collapse-list-item'
                      }>
                        <Link to={ROUTES.operatorsRating} >
                          Operators Rating
                        </Link>
                      </li>
                    </CheckPerms>
                  </ul>
                }
              </>
            </CollapseListItem>
          </CheckPerms>

          <CheckPerms permission={PERMISSIONS.getAllCronjobs}>
            <li
              className={getClassName(ROUTES.cronJobs)}
              onClick={() => setOpenMenuItem(ROUTES.cronJobs)}
            >
              <Link to={ROUTES.cronJobs}>
                <FontAwesomeIcon icon={faClock} />
                {isShowText && <span>Cron Jobs</span>}
              </Link>
            </li>
          </CheckPerms>

          <CheckPerms permission={PERMISSIONS.getAllSystemRoles}>
            <li
              className={getClassName(ROUTES.systemRoles)}
              onClick={() => setOpenMenuItem(ROUTES.systemRoles)}
            >
              <Link to={ROUTES.systemRoles}>
                <FontAwesomeIcon icon={faUsers} />
                {isShowText && <span>System Roles</span>}
              </Link>
            </li>
          </CheckPerms>

          <CheckPerms permission={[PERMISSIONS.getOperatorsActivity, PERMISSIONS.getOperatorsProductivity]}>
            <li
              className={getClassName(ROUTES.statistics)}
              onClick={() => setOpenMenuItem(ROUTES.statistics)}
            >
              <Link to={ROUTES.statistics}>
                <FontAwesomeIcon icon={faChartBar} />
                {isShowText && <span>Statistics</span>}
              </Link>
            </li>
          </CheckPerms>

          <CheckPerms permission={PERMISSIONS.getCallRedirectTime}>
            <li
              className={getClassName(ROUTES.settings)}
              onClick={() => setOpenMenuItem(ROUTES.settings)}
            >
              <Link to={ROUTES.settings}>
                <FontAwesomeIcon icon={faCog} />
                {isShowText && <span>Settings</span>}
              </Link>
            </li>
          </CheckPerms>

          <CheckPerms permission={PERMISSIONS.getPhoneNumbers}>
            <li
              className={getClassName(ROUTES.phoneNumbers)}
              onClick={() => setOpenMenuItem(ROUTES.phoneNumbers)}
            >
              <Link to={ROUTES.phoneNumbers}>
                <FontAwesomeIcon icon={faPhoneAlt} />
                {isShowText && <span>Phone Numbers</span>}
              </Link>
            </li>
          </CheckPerms>

          <CheckPerms permission={[PERMISSIONS.getShortcuts, PERMISSIONS.getMsgTemplates]}>
            <CollapseListItem
              classPrefix="sidebar__list-item"
              isOpen={openMenuItem === collapsibleListItems.templates}
              isActive={templateCollapseRoutes.includes(props.location.pathname)}
            >
              <>
                <div
                  className="sidebar__list-item--collapse"
                  onClick={() => {
                    !isUnfolded && setIsOpen(true)
                    setOpenMenuItem(openMenuItem === collapsibleListItems.templates
                      ? ''
                      : collapsibleListItems.templates)
                  }}
                >
                  <FontAwesomeIcon icon={faKeyboard} />
                  {isShowText &&
                    <span>
                      Message Templates
                      <FontAwesomeIcon
                        icon={openMenuItem === collapsibleListItems.templates ? faChevronUp : faChevronDown}
                        className="sidebar__list-item--collapse-icon"
                      />
                    </span>
                  }
                </div>
                {isShowText &&
                  <ul className="collapse-list">
                    <CheckPerms permission={PERMISSIONS.getShortcuts}>
                      <li className={props.location.pathname === ROUTES.shortcuts
                        ? getClassName(ROUTES.shortcuts)
                        : 'collapse-list-item'
                      }>
                        <Link to={ROUTES.shortcuts} >
                          Shortcuts
                        </Link>
                      </li>
                    </CheckPerms>

                    <CheckPerms permission={PERMISSIONS.getMsgTemplates} >
                      <li className={props.location.pathname === ROUTES.msgTemplates
                        ? getClassName(ROUTES.msgTemplates)
                        : 'collapse-list-item'
                      }>
                        <Link to={ROUTES.msgTemplates} >
                          Templates
                        </Link>
                      </li>
                    </CheckPerms>
                  </ul>
                }
              </>
            </CollapseListItem>
          </CheckPerms>

          <CheckPerms permission={PERMISSIONS.getGlossaries}>
            <li
              className={getClassName(ROUTES.glossary)}
              onClick={() => setOpenMenuItem(ROUTES.glossary)}
            >
              <Link to={ROUTES.glossary}>
                <FontAwesomeIcon icon={faBookOpen} />
                {isShowText && <span>Glosssary</span>}
              </Link>
            </li>
          </CheckPerms>

          <CheckPerms permission={PERMISSIONS.getAllTasksForAdmin}>
            <li
              className={getClassName(ROUTES.webMaster)}
              onClick={() => setOpenMenuItem(ROUTES.webMaster)}
            >
              <Link to={ROUTES.webMaster}>
                <FontAwesomeIcon icon={faTasks} />
                {isShowText && <span>Webmaster Tasks</span>}
              </Link>
            </li>
          </CheckPerms>

          <CheckPerms permission={PERMISSIONS.getAllClientInteractions}>
            <li
              className={getClassName(ROUTES.callsLog)}
              onClick={() => setOpenMenuItem(ROUTES.callsLog)}
            >
              <Link to={ROUTES.callsLog}>
                <FontAwesomeIcon icon={faFileAlt} />
                {isShowText && <span>Calls Log</span>}
              </Link>
            </li>
          </CheckPerms>

          <CheckPerms permission={PERMISSIONS.getAllClientInteractions}>
            <li
              className={getClassName(ROUTES.smsLogs)}
              onClick={() => setOpenMenuItem(ROUTES.smsLogs)}
            >
              <Link to={ROUTES.smsLogs}>
                <FontAwesomeIcon icon={faComments} />
                {isShowText && <span>Sms Logs</span>}
              </Link>
            </li>
          </CheckPerms>

          <CheckPerms permission={PERMISSIONS.getAllPublicApps}>
            <li
              className={getClassName(ROUTES.publicApi)}
              onClick={() => setOpenMenuItem(ROUTES.publicApi)}
            >
              <Link to={ROUTES.publicApi}>
                <FontAwesomeIcon icon={faAppStore} />
                {isShowText && <span>Public API</span>}
              </Link>
            </li>
          </CheckPerms>

          <CheckPerms permission={PERMISSIONS.getBookings}>
            <li
              className={getClassName(ROUTES.bookings)}
              onClick={() => setOpenMenuItem(ROUTES.bookings)}
            >
              <Link to={ROUTES.bookings}>
                <FontAwesomeIcon icon={faAddressBook} />
                {isShowText && <span>Bookings</span>}
              </Link>
            </li>
          </CheckPerms>

          <CheckPerms permission={PERMISSIONS.getContacts} >
            <CollapseListItem
              classPrefix="sidebar__list-item"
              isOpen={openMenuItem === collapsibleListItems.contacts}
              isActive={contactsCollapsedRoutes.includes(props.location.pathname)}
            >
              <>
                <div
                  className="sidebar__list-item--collapse"
                  onClick={() => {
                    !isUnfolded && setIsOpen(true);
                    setOpenMenuItem(openMenuItem === collapsibleListItems.contacts
                      ? ""
                      : collapsibleListItems.contacts)
                  }}
                >
                  <FontAwesomeIcon icon={faAddressCard} />
                  {isShowText &&
                    <span>
                      Contacts
                      <FontAwesomeIcon
                        icon={openMenuItem === collapsibleListItems.contacts ? faChevronUp : faChevronDown}
                        className="sidebar__list-item--collapse-icon"
                      />
                    </span>}
                </div>
                {isShowText &&
                  <ul className="collapse-list">
                    <CheckPerms permission={PERMISSIONS.getContacts}>
                      <li className={props.location.pathname === ROUTES.contacts
                        ? getClassName(ROUTES.contacts)
                        : 'collapse-list-item'
                      }>
                        <Link to={ROUTES.contacts} >
                          Contacts
                        </Link>
                      </li>
                    </CheckPerms>

                    {/* <CheckPerms permission={PERMISSIONS.getContacts}>
                      <li className={props.location.pathname === ROUTES.contactsBlacklist
                        ? getClassName(ROUTES.contactsBlacklist)
                        : 'collapse-list-item'
                      }>
                        <Link to={ROUTES.contactsBlacklist}>
                          Blacklist
                        </Link>
                      </li>
                    </CheckPerms> */}
                  </ul>
                }
              </>
            </CollapseListItem>
          </CheckPerms>

          <CheckPerms permission={PERMISSIONS.getSessions}>
            <li
              className={getClassName(ROUTES.sessions)}
              onClick={() => setOpenMenuItem(ROUTES.sessions)}
            >
              <Link to={ROUTES.sessions}>
                <FontAwesomeIcon icon={faUserClock} />
                {isShowText && <span>Sessions</span>}
              </Link>
            </li>
          </CheckPerms>

          <CheckPerms permission={PERMISSIONS.getTagColors}>
            <li
              className={getClassName(ROUTES.tags)}
              onClick={() => setOpenMenuItem(ROUTES.tags)}
            >
              <Link to={ROUTES.tags}>
                <FontAwesomeIcon icon={faPalette} />
                {isShowText && <span>Tag colors</span>}
              </Link>
            </li>
          </CheckPerms>
        </ul>
      </nav>
    </aside>
  );
};

export default React.memo(withRouter(Sidebar));
