import { Dispatch } from "redux";

import API from "../../api/api";
import { normalize } from "../../utils/normalize";


import {
  IAction,
  ICronJobApi,
} from "../../interfaces/interfaces";
import { ICronJobsState, ICustomResponse } from "./types";


export const GET_ALL_CRON_JOBS = 'GET_ALL_CRON_JOBS';
export const EDIT_CRON_JOB = 'EDIT_CRON_JOB';
export const START_CRON_JOB = 'START_CRON_JOB';
export const STOP_CRON_JOB = 'STOP_CRON_JOB';


export const getAllCronJobs = () => (dispatch: Dispatch): void => {
  API.getCronJobs()
    .then(res => {
      dispatch({
        type: GET_ALL_CRON_JOBS,
        payload: normalize(res.data),
      });
    })
    .catch(console.log);
};

export const editCronJob = (cronJob: ICronJobApi) => (dispatch: Dispatch): Promise<ICustomResponse> => {
  return API.editCronJob(cronJob)
    .then(res => {
      const cron = res.data;

      cron.active = +cron.active;

      dispatch({
        type: EDIT_CRON_JOB,
        payload: cron,
      });

      return { status: true };
    })
    .catch(err => {
      const res = err && err.response && err.response.data;

      return {
        status: false,
        msg: JSON.parse(res.message),
      };
    });
};

export const startCronJob = (id: number) => (dispatch: Dispatch): void => {
  dispatch({
    type: START_CRON_JOB,
    payload: id,
  });
};

export const stopCronJob = (id: number) => (dispatch: Dispatch): void => {
  dispatch({
    type: STOP_CRON_JOB,
    payload: id,
  });
};

const initialState: ICronJobsState = {
  ids: [],
  entities: {},
};


export default (state = initialState, action: IAction) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_CRON_JOBS: {
      const { entities, ids } = payload;

      return {
        ...state,
        entities: entities,
        ids: ids,
      }
    }
    case EDIT_CRON_JOB: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: payload,
        }
      }
    }
    case START_CRON_JOB: {
      const updatedCronJob = { ...state.entities[payload] };

      updatedCronJob.running = 1;

      return {
        ...state,
        entities: {
          ...state.entities,
          [payload]: updatedCronJob,
        }
      }
    }
    case STOP_CRON_JOB: {
      const updatedCronJob = { ...state.entities[payload] };

      updatedCronJob.running = 0;

      return {
        ...state,
        entities: {
          ...state.entities,
          [payload]: updatedCronJob,
        }
      }
    }
    default:
      return state;
  }
}