import axios from 'axios';

import { handleNetError } from '../utils';

const protocol = 'https://';
const currentHostname = window && window.location && window.location.hostname;

let backendHostname;

if (currentHostname === 'localhost') {
    backendHostname = 'admin.comdiva.local';
}
else if (currentHostname && currentHostname !== 'chromewebdata') {
    backendHostname = currentHostname;
}
else {
    backendHostname = process.env.REACT_APP_BACKEND_HOST || 'unknownhosterror';
}

export const API_ROOT = protocol + backendHostname + '/api-admin/';
export const COMDIVA_PAGE_ROOT = protocol + backendHostname.slice(6);

axios.defaults.baseURL = API_ROOT;
if (process.env.NODE_ENV === 'development') {
    console.log('add axios credentials conf');

    axios.defaults.withCredentials = true;
}
axios.interceptors.response.use(
    response => {
        // Do something with response data
        return Promise.resolve(response);
    },
    error => {
        // Do something with response error
        handleNetError(error);

        return Promise.reject(error);
    });
