import React from 'react';

interface ISpinnerProps {
  className?: string;
  size?: string;
  color?: 'black' | 'red' | 'green' | 'yellow' | 'blue';
  style?: { [key: string]: string }
}

const Spinner = (props: ISpinnerProps) => {
  const {
    size = '30px',
    color = 'black',
    className = '',
    style = {},
  } = props;

  return (
    <div
      className={`spinner spinner--${color} ${className}`}
      style={{
        width: size,
        height: size,
        ...style,
      }}
    ></div>
  )
};

export default Spinner;