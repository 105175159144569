export default (timeInterval: 'day' | 'week' | 'month' | 'sum') => {
  let parseDateParams: {} = {};

  switch (timeInterval) {
    case 'day': {
      parseDateParams = { day: '2-digit', month: 'short', year: 'numeric' };
      break;
    };
    case 'week': {
      parseDateParams = { day: '2-digit', weekday: 'short', month: 'short', year: '2-digit' };
      break;
    };
    case 'month': {
      parseDateParams = { month: 'long', year: 'numeric' };
      break;
    };

    default: {
      parseDateParams = { day: '2-digit', month: 'short', year: 'numeric' };
      break;
    }
  };

  return parseDateParams;
};
