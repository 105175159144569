import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Responsive, WidthProvider, Layout, Layouts } from 'react-grid-layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { PERMISSIONS } from '../../config/constants';
import { updateActiveWidgets, updateGridLayouts } from '../../redux/ducks/widgets';
import { useToggle } from '../../hooks';
import { getDateByTimezoneOffset } from '../../utils';

import { AppState } from '../../redux/store';
import { IWelcomeProps } from './Welcome.types';

import './Welcome.scss';
import Spinner from '../../components/Spinner';
import DropWrapper from '../../components/DropWrapper/DropWrapper';
import OperatorsWidget from './components/OperatorsWidget';
import CallsWidget from './components/CallsWidget';
import IncomingCallsWidget from './components/IncomingCallsWidget';
import LastCallsWidget from './components/LastCallsWidget/LastCallsWidget';
import IncomingCallsWaitingWidget from './components/IncomingCallsWaitingWidget';
import InternalCallsDurationWidget from './components/InternalCallsDurationWidget';
import CallsDurationWidget from './components/CallsDurationWidget';
import MissedCallsWidget from './components/MissedCallsWidget/MissedCallsWidget';
import NewClientsStatisticsWidget from './components/NewClientsStatistics';


const ResponsiveReactGridLayout = WidthProvider(Responsive);

type Interval = 'day' | 'week' | 'month';
const getDatesForInterval = (interval: Interval, userTimezone: number): [number, number] => {
  let date: number = 0
  let dateRange: number = 0;

  const oneDay = 86400000; // 24 hours
  const todayByTimezone = getDateByTimezoneOffset(userTimezone).setHours(0, 0, 0, 0) -
      (userTimezone - (new Date().getTimezoneOffset() * (-1))) * 60000;

  switch (interval) {
    case 'day': {
      date = todayByTimezone;
      dateRange = date + oneDay - 1;
      break;
    }
    case 'week': {
      const daysToNearestMonday = -(1 - new Date(todayByTimezone).getDay()); // if today is Wednesday, then 2 days to monday. If friday - 4
      date = todayByTimezone - (daysToNearestMonday * oneDay);
      dateRange = date + (6 * oneDay) + oneDay - 1;
      break;
    }
    case 'month': {
      const currentYearByTimezone = new Date(todayByTimezone).getFullYear();
      const currentMonthByTimezone = new Date(todayByTimezone).getMonth();

      date = new Date(currentYearByTimezone, currentMonthByTimezone, 1).getTime()
          - (userTimezone - (new Date().getTimezoneOffset() * (-1))) * 60000;
      dateRange = new Date(currentYearByTimezone, currentMonthByTimezone + 1, 0).getTime()
          - (userTimezone - (new Date().getTimezoneOffset() * (-1))) * 60000 + oneDay - 1;
      break;
    }
  }

  return [date, dateRange];
}


const Welcome = (props: IWelcomeProps) => {
  const {
    userPermissions,
    activeWidgets: activeWidgetsFromRedux,
    gridLayouts,
    isWidgetsPending,
  } = props;

  const [gridClassName, setGridClassName] = useState('welcome-grid-layout welcome-grid-layout--not-animated');
  const [layouts, setLayouts] = useState<Layouts | null>(null);
  const [allowedWidgets, setAllowedWidgets] = useState<string[]>([]);
  const [activeWidgets, setActiveWidgets] = useState<string[]>([]);

  const [isManageWidgetsOpen, toggleManageWidgets] = useToggle(false);

  const isLoading = useRef(true);
  const manageWidgetsRef = useRef(null);

  useEffect(() => { // Remove init animation
    const timer = setTimeout(() => setGridClassName('welcome-grid-layout'), 100);

    return () => {
      clearTimeout(timer);
    }
  }, [])

  useEffect(() => { // set allowed widgets
    const newAllowedWidgets = gridConfig.ids.filter(id => {
      const currentPermission = gridConfig.widgets[id].permission;

      const isAccessAllow = userPermissions.includes(currentPermission) || userPermissions.includes('/*');

      return isAccessAllow;
    });

    setAllowedWidgets(newAllowedWidgets);
  }, [userPermissions]);

  useEffect(() => { // init activeWidgets and layouts
    const isInit = !isWidgetsPending && isLoading.current;

    if (isInit) {
      isLoading.current = false;

      if (!gridLayouts) {
        props.updateGridLayouts(gridConfig.defaultLayoutsValues);
        setLayouts(gridConfig.defaultLayoutsValues);
      }
      else {
        setLayouts(gridLayouts);
      }

      if (!activeWidgetsFromRedux) {
        props.updateActiveWidgets(allowedWidgets);
        filterAllowedWidgets(allowedWidgets);
      }
      else {
        filterAllowedWidgets(activeWidgetsFromRedux);
      }
    }
  }, [isWidgetsPending, activeWidgetsFromRedux, gridLayouts, allowedWidgets]);

  const toggleActiveWidget = (widgetId: string) => {
    if (!activeWidgets || !layouts) return;

    const isRemoving = activeWidgets.includes(widgetId);

    if (isRemoving) {
      const newUserActiveWidgets = activeWidgets.filter(id => id !== widgetId);

      props.updateActiveWidgets(newUserActiveWidgets);
      filterAllowedWidgets(newUserActiveWidgets)
    }
    else { // Adding
      const updatedGridLayouts: Layouts = {};

      Object.keys(layouts).forEach(gridSize => {
        updatedGridLayouts[gridSize] = [...layouts[gridSize]];
      });

      const findWidgetInLayout = (layout: Layout) => layout.i === widgetId;

      Object.keys(updatedGridLayouts).forEach(gridSize => {
        const isWidgetOptionsExist = !!updatedGridLayouts[gridSize].find(findWidgetInLayout);

        if (!isWidgetOptionsExist) {
          const defaultWidgetOptions = gridConfig.defaultLayoutsValues[gridSize].find(findWidgetInLayout) as Layout;

          updatedGridLayouts[gridSize].unshift(defaultWidgetOptions);
        }
      });

      isRemoving && props.updateGridLayouts(updatedGridLayouts);
      props.updateActiveWidgets([...activeWidgets, widgetId]);
      setLayouts(updatedGridLayouts);
      filterAllowedWidgets([...activeWidgets, widgetId])
    }
  };
  // @ts-ignore
  // unused parameter ignore
  const onLayoutChange = (layout: Layout[], allLayouts: Layouts) => {
    if (JSON.stringify(allLayouts) !== JSON.stringify(layouts)) {
      props.updateGridLayouts(allLayouts);
      setLayouts(allLayouts);
    }
  };

  const filterAllowedWidgets = (widgets: string[]) => {
    const activeWidgets = widgets.filter(id => {
      const currentPermission = gridConfig.widgets[id].permission;

      return userPermissions.includes(currentPermission) || userPermissions.includes('/*');
    })

    setActiveWidgets(activeWidgets);
  }

  const allowedWidgetsByGroup = getWidgetsSortedByGroup(allowedWidgets);
  const isMount = !!isWidgetsPending || !gridLayouts || !activeWidgets;

  return (
      <div className='welcome'>
        {isMount &&
        <div className="pending">
          <Spinner />
        </div>
        }

        <h1 className='welcome__title'>
          Dashboard

          <div className='welcome__manage-widgets-wrap'>
            <button
                className='btn btn--light'
                onMouseDown={toggleManageWidgets}
                // disabled={!activeWidgets || !activeWidgets.length}
            >
              Manage widgets
            </button>

            {isManageWidgetsOpen &&
            <DropWrapper
                dropWrapperRef={manageWidgetsRef}
                closeDropWrapper={toggleManageWidgets}
            >
              <ul
                  className='welcome__manage-widgets'
                  ref={manageWidgetsRef}
              >
                {Object.keys(allowedWidgetsByGroup).map(groupName =>
                    <div className='welcome__manage-widgets-group' key={groupName}>
                      <li className='welcome__manage-widgets-group-title'>{groupName}</li>

                      {allowedWidgetsByGroup[groupName].map(widgetId =>
                          <li
                              className='welcome__manage-widgets-item'
                              key={widgetId}
                              onClick={() => toggleActiveWidget(widgetId)}
                          >
                            {widgetId}

                            {activeWidgets && activeWidgets.includes(widgetId) &&
                            <FontAwesomeIcon icon={faCheck} className='welcome__manage-widgets-selected' />
                            }
                          </li>
                      )}
                    </div>
                )}
              </ul>
            </DropWrapper>
            }
          </div>
        </h1>
        {!!activeWidgets && !!layouts && !!activeWidgets.length &&
        <ResponsiveReactGridLayout
            className={gridClassName}
            rowHeight={10}
            onLayoutChange={onLayoutChange}
            cols={gridConfig.cols}
            breakpoints={gridConfig.breakpoints}
            layouts={layouts}
            draggableCancel='.welcome__not-draggable'
        >
          {activeWidgets.map((id: string) =>
              <div key={id} className='welcome-grid-layout__item'>
                {gridConfig.widgets[id].component}
              </div>
          )}
        </ResponsiveReactGridLayout>
        }
      </div>
  );
}

const gridConfig: {
  ids: string[];
  widgets: {
    [id: string]: {
      permission: string;
      type: string;
      component: JSX.Element;
    }
  };
  defaultLayoutsValues: Layouts;
  cols: { [P: string]: number; };
  breakpoints: { [P: string]: number; };
} = {
  ids: [
    'Operators count',
    'Interactions today',
    'Last calls',
    'Incoming calls chart',
    'Incoming calls waiting time',
    'Internal calls duration',
    'Incoming calls durations',
    'Outgoing calls duration',
    'Missed calls',
    'New Clients Statistics',
  ],
  cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 570, xxs: 0 },
  defaultLayoutsValues: {
    lg: [
      { x: 0, y: 8, w: 4, h: 6, i: 'Operators count', minW: 4, minH: 6, maxH: 7 },
      { x: 4, y: 8, w: 8, h: 6, i: 'Interactions today', minW: 8, minH: 6, maxH: 7 },
      { x: 0, y: 14, w: 8, h: 27, i: 'Last calls', minW: 8, minH: 27, maxH: 68 },
      { x: 8, y: 14, w: 3, h: 15, i: 'Incoming calls chart', minW: 3, minH: 15, maxW: 6, maxH: 30 },
      { x: 0, y: 0, w: 3, h: 8, i: 'Incoming calls waiting time', minW: 3, minH: 8, maxH: 9 },
      { x: 3, y: 0, w: 3, h: 8, i: 'Internal calls duration', minW: 3, minH: 8, maxH: 9 },
      { x: 6, y: 0, w: 3, h: 8, i: 'Incoming calls durations', minW: 3, minH: 8, maxH: 9 },
      { x: 9, y: 0, w: 3, h: 8, i: 'Outgoing calls duration', minW: 3, minH: 8, maxH: 9 },
      { x: 0, y: 14, w: 8, h: 27, i: 'Missed calls', minW: 8, minH: 27, maxH: 68 },
      { x: 0, y: 14, w: 8, h: 27, i: 'New Clients Statistics', minW: 8, minH: 27, maxH: 48 },
    ],
    md: [
      { x: 0, y: 0, w: 3, h: 6, i: 'Operators count', minW: 3, minH: 6, maxH: 7 },
      { x: 4, y: 0, w: 7, h: 6, i: 'Interactions today', minW: 7, minH: 6, maxH: 7 },
      { x: 0, y: 10, w: 7, h: 27, i: 'Last calls', minW: 7, minH: 27, maxH: 68 },
      { x: 0, y: 10, w: 3, h: 15, i: 'Incoming calls chart', minW: 3, minH: 15, maxW: 6, maxH: 30 },
      { x: 8, y: 6, w: 3, h: 8, i: 'Incoming calls waiting time', minW: 3, minH: 8, maxH: 9 },
      { x: 8, y: 6, w: 3, h: 8, i: 'Internal calls duration', minW: 3, minH: 8, maxH: 9 },
      { x: 8, y: 6, w: 3, h: 8, i: 'Incoming calls durations', minW: 3, minH: 8, maxH: 9 },
      { x: 8, y: 6, w: 3, h: 8, i: 'Outgoing calls duration', minW: 3, minH: 8, maxH: 9 },
      { x: 0, y: 10, w: 7, h: 27, i: 'Missed calls', minW: 7, minH: 27, maxH: 68 },
      { x: 0, y: 16, w: 7, h: 27, i: 'New Clients Statistics', minW: 7, minH: 27, maxH: 48 },
    ],
    sm: [
      { x: 0, y: 47, w: 3, h: 6, i: 'Operators count', minW: 3, minH: 6, maxH: 7 },
      { x: 0, y: 0, w: 4, h: 6, i: 'Interactions today', minW: 4, minH: 6, maxH: 7 },
      { x: 0, y: 20, w: 6, h: 27, i: 'Last calls', minW: 6, minH: 27, maxH: 68 },
      { x: 4, y: 0, w: 2, h: 15, i: 'Incoming calls chart', minW: 2, minH: 15, maxW: 6, maxH: 30 },
      { x: 0, y: 6, w: 3, h: 8, i: 'Incoming calls waiting time', minW: 3, minH: 8, maxH: 9 },
      { x: 2, y: 6, w: 2, h: 8, i: 'Internal calls duration', minW: 2, minH: 8, maxH: 9 },
      { x: 0, y: 12, w: 3, h: 8, i: 'Incoming calls durations', minW: 3, minH: 8, maxH: 9 },
      { x: 2, y: 12, w: 3, h: 8, i: 'Outgoing calls duration', minW: 3, minH: 8, maxH: 9 },
      { x: 0, y: 20, w: 6, h: 27, i: 'Missed calls', minW: 6, minH: 27, maxH: 68 },
      { x: 0, y: 12, w: 6, h: 27, i: 'New Clients Statistics', minW: 6, minH: 27, maxH: 48 },
    ],
    xs: [
      { x: 0, y: 0, w: 3, h: 6, i: 'Operators count', minW: 3, minH: 6, maxH: 7 },
      { x: 4, y: 0, w: 4, h: 6, i: 'Interactions today', minW: 4, minH: 6, maxH: 7 },
      { x: 0, y: 11, w: 4, h: 27, i: 'Last calls', minW: 4, minH: 27, maxH: 68 },
      { x: 0, y: 10, w: 2, h: 15, i: 'Incoming calls chart', minW: 2, minH: 15, maxW: 6, maxH: 30 },
      { x: 0, y: 6, w: 2, h: 8, i: 'Incoming calls waiting time', minW: 2, minH: 8, maxH: 9 },
      { x: 8, y: 6, w: 3, h: 8, i: 'Internal calls duration', minW: 3, minH: 8, maxH: 9 },
      { x: 0, y: 6, w: 3, h: 8, i: 'Incoming calls durations', minW: 3, minH: 8, maxH: 9 },
      { x: 8, y: 6, w: 3, h: 8, i: 'Outgoing calls duration', minW: 3, minH: 8, maxH: 9 },
      { x: 0, y: 11, w: 4, h: 27, i: 'Missed calls', minW: 4, minH: 27, maxH: 68 },
      { x: 0, y: 13, w: 4, h: 27, i: 'New Clients Statistics', minW: 4, minH: 27, maxH: 48 },
    ],
    xxs: [
      { x: 0, y: 0, w: 2, h: 6, i: 'Operators count', minW: 2, minH: 6, maxH: 7 },
      { x: 4, y: 0, w: 2, h: 6, i: 'Interactions today', minW: 2, minH: 6, maxH: 7 },
      { x: 0, y: 10, w: 2, h: 27, i: 'Last calls', minW: 2, minH: 27, maxH: 68 },
      { x: 0, y: 10, w: 2, h: 19, i: 'Incoming calls chart', minW: 2, minH: 19, maxW: 4, maxH: 25 },
      { x: 8, y: 6, w: 2, h: 9, i: 'Incoming calls waiting time', minW: 2, minH: 9, maxH: 10 },
      { x: 8, y: 6, w: 2, h: 9, i: 'Internal calls duration', minW: 2, minH: 9, maxH: 10 },
      { x: 8, y: 6, w: 2, h: 9, i: 'Incoming calls durations', minW: 2, minH: 9, maxH: 10 },
      { x: 8, y: 6, w: 2, h: 9, i: 'Outgoing calls duration', minW: 2, minH: 9, maxH: 10 },
      { x: 0, y: 10, w: 2, h: 27, i: 'Missed calls', minW: 2, minH: 27, maxH: 68 },
      { x: 0, y: 15, w: 2, h: 27, i: 'New Clients Statistics', minW: 2, minH: 27, maxH: 48 },

    ],
  },
  widgets: {
    'Operators count': {
      permission: PERMISSIONS.Login,
      component: <OperatorsWidget />,
      type: 'Operators',
    },
    'Interactions today': {
      permission: PERMISSIONS.getInteractionsCountByToday,
      component: <CallsWidget />,
      type: 'Calls',
    },
    'Last calls': {
      permission: PERMISSIONS.getLastCalls,
      component: <LastCallsWidget />,
      type: 'Calls',
    },
    'Incoming calls chart': {
      permission: PERMISSIONS.getIncomingCallsCountByDate,
      component: <IncomingCallsWidget getDatesForInterval={getDatesForInterval} />,
      type: 'Calls',
    },
    'Incoming calls waiting time': {
      permission: PERMISSIONS.getIncomingCallsWaitingTime,
      component: <IncomingCallsWaitingWidget getDatesForInterval={getDatesForInterval} />,
      type: 'Calls',
    },
    'Internal calls duration': {
      permission: PERMISSIONS.getInternalCallsDuration,
      component: <InternalCallsDurationWidget getDatesForInterval={getDatesForInterval} />,
      type: 'Calls',
    },
    'Incoming calls durations': {
      permission: PERMISSIONS.getCallsDuration,
      component: <CallsDurationWidget getDatesForInterval={getDatesForInterval} title='Incoming Calls Duration' type='incoming' />,
      type: 'Calls',
    },
    'Outgoing calls duration': {
      permission: PERMISSIONS.getCallsDuration,
      component: <CallsDurationWidget getDatesForInterval={getDatesForInterval} title='Outgoing Calls Duration' type='outgoing' />,
      type: 'Calls',
    },
    'Missed calls': {
      permission: PERMISSIONS.getMissedCalls,
      component: <MissedCallsWidget />,
      type: 'Calls',
    },
    'New Clients Statistics': {
      permission: PERMISSIONS.getNewClientsStatistics,
      component: <NewClientsStatisticsWidget />,
      type: 'Clients',
    },
  },
};
const getWidgetsSortedByGroup = (widgetIds: string[]): { [groupName: string]: string[] } => {
  const groups: { [groupName: string]: string[] } = {};

  widgetIds.forEach(widgetId => {
    const type = gridConfig.widgets[widgetId].type;

    if (!groups[type]) {
      groups[type] = [];
    }

    groups[type].push(widgetId);
  });

  return groups;
}

const mapStateToProps = (state: AppState) => ({
  userPermissions: state.user.permissions,
  gridLayouts: state.widgets.gridLayouts,
  activeWidgets: state.widgets.activeWidgets,
  isWidgetsPending: state.widgets.isPending,
});

const mapDispatchToProps = {
  updateActiveWidgets,
  updateGridLayouts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);