import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faCaretUp,
  faCaretDown
} from '@fortawesome/free-solid-svg-icons';

import API from '../../../api/api';
import { useToggle } from '../../../hooks';
import {
  getDateByTimezoneOffset,
  getChartDateParams,
  getDataByTimeInterval,
  getDateForView,
} from '../../../utils';

import { INewClientsStatisticsWidgetProps, lineChartDataset, IChartDataset } from '../Welcome.types';
import { AppState } from '../../../redux/store';
import { selectUserTimezone } from '../../../redux/selectors/selectors';

import DropWrapper from '../../../components/DropWrapper/DropWrapper';
import Calendar from '../../../components/Calendar/Calendar';
import Spinner from '../../../components/Spinner';
import TimeIntervalsSwitcher, { DAY, MONTH, SUM, TimeInterval, WEEK } from '../../../components/TimeIntervalsSwitcher/TimeIntervalsSwitcher';

interface IChartData {
  datasets: IChartDataset[],
  labels: string[],
};

const defaultChartData = {
  datasets: [],
  labels: [],
};


const NewClientsStatisticsWidget = (props: INewClientsStatisticsWidgetProps) => {
  const {
    userTimezone
  } = props;

  const [filter, setFilter] = useState<{
    date: null | number | number[],
  }>({
    date: getDateByTimezoneOffset(userTimezone).setHours(0, 0, 0, 0) -
      (userTimezone - (new Date().getTimezoneOffset() * (-1))) * 60000,
  });
  const [timeInterval, setTimeInterval] = useState<TimeInterval>(DAY);
  const [chartStep, setChartStep] = useState<number | null>(null);
  const [chartData, setChartData] = useState<IChartData>(defaultChartData);
  const [apiData, setApiData] = useState<{
    dates: number[],
    contactsCount: number[]
  }>({
    dates: [],
    contactsCount: []
  });

  const [isCalendarOpen, toggleCalendarOpen] = useToggle(false);
  const [isPending, togglePending] = useToggle(false);

  const calendarRef = useRef(null);
  const chartRef = useRef(null);

  let history = useHistory();

  useEffect(() => {
    const config = {
      date: filter.date,
    };

    togglePending();

    API.getNewClientsStatistics(config)
      .then(({ data }) => {
        setApiData(data)
      })
      .catch(err => {
        console.log(err);
        setChartData(defaultChartData)
      })
      .finally(() => togglePending());
  }, [filter.date])

  useEffect(() => {
    const { contactsCount, dates } = apiData;
    const datasets: IChartDataset[] = [];

    const { datesByInterval, dataByInterval } = getDataByTimeInterval(userTimezone, timeInterval, dates, contactsCount);

    const labels: string[] = timeInterval === SUM
      ? ['Selected range']
      : datesByInterval.map((date: number) =>
        getDateByTimezoneOffset(userTimezone, date).toLocaleString('en-US', getChartDateParams(timeInterval)));

    let resultLabels: string[];
    let resultData: number[];

    if ((Math.max(...dataByInterval) - Math.min(...dataByInterval) <= 5))
      setChartStep(1)
    else
      setChartStep(null);

    if (labels.length <= 1 && dataByInterval.length <= 1) {
      setChartData(defaultChartData)

      resultLabels = ['', labels[0], ''];
      resultData = [NaN, dataByInterval[0], NaN]

      if (contactsCount[0] > 5) setChartStep(null);
    }
    else {
      resultLabels = labels;
      resultData = dataByInterval;
    }

    datasets.push({
      label: 'New Clients',
      data: resultData,
      ...lineChartDataset
    })

    setChartData({ labels: resultLabels, datasets })
  }, [apiData, timeInterval]);


  const startFilteringByDate = (date: number | number[] | null) => {
    if (filter.date === date) return;

    setFilter(prev => ({ ...prev, date }));
    toggleCalendarOpen();
  };

  const onClick = (e: any) => { // type any is in react-chartjs-2 for this function
    if (e[0]) {
      const selectedLabel = chartData.labels[e[0]._index];
      let date: null | number | number[] = null;
      
      const week = 604800000;
      const day = 86400000;
      const localTimezone = new Date().getTimezoneOffset() * -60000;
      const todayByTimezone = getDateByTimezoneOffset(userTimezone).setHours(0, 0, 0, 0) -
        (userTimezone - (new Date().getTimezoneOffset() * (-1))) * 60000;
  
      switch (timeInterval) {
        case DAY: {
          date = new Date(selectedLabel).getTime() + localTimezone - (userTimezone *1 * 60000);
          break;
        }
        case WEEK: {
          const selectedDay = new Date(selectedLabel).getTime() + localTimezone - (userTimezone *1 * 60000);
          // we need day to be + 23h59m59s
          const currentDay = todayByTimezone + day - 1;
          const isCurrentWeek = (currentDay - selectedDay) < week;
  
          date = [
            selectedDay,
            isCurrentWeek ? currentDay : (selectedDay + week - 1)
          ];
          break;
        }
        case MONTH: {
          const selectedYear = new Date(selectedLabel).getFullYear();
          const selectedMonth = new Date(selectedLabel).getMonth();
  
          const firstDayOfMonth = new Date(selectedYear, selectedMonth, 1).getTime()
            + localTimezone - (userTimezone *1 * 60000);
          // we need day to be + 23h59m59s
          const lastDayOfMonth = new Date(selectedYear, selectedMonth + 1, 0).getTime()
            + (localTimezone - (userTimezone *1 * 60000)) + day - 1;
          const currentDay = todayByTimezone + day - 1;
          const isCurrentMonth = currentDay < lastDayOfMonth;
  
          date = [
            firstDayOfMonth,
            isCurrentMonth ? currentDay : lastDayOfMonth
          ];
          break;
        }
        case SUM: {
          date = filter.date
          break;
        }
        default: return;
      };
  
      history.push('admin/contacts', { filterDate: date });
    }
  };

  return (
    <div className='welcome__item card clients-statistics-widget'>
      <h2 className='welcome__item-title'>
        New Clients Statistics
      </h2>

      <div className='clients-statistics-widget__chart calls-duration-widget welcome__not-draggable'>
        <div className='clients-statistics-widget__calendar-and-intervals'>
          <TimeIntervalsSwitcher
            timeInterval={timeInterval}
            onChange={setTimeInterval}
            className="clients-statistics-widget__switch"
            date={apiData.dates}
            isSumButton
          />

          <div className='clients-statistics-widget__calendar-wrap'>
            <button
              onMouseDown={toggleCalendarOpen}
              className='clients-statistics-widget__btn-open-calendar'
            >
              {getDateForView(userTimezone, filter.date)}
              <FontAwesomeIcon
                icon={faCalendarAlt}
                className='clients-statistics-widget__date-icon'
              />
              <FontAwesomeIcon
                className='clients-statistics-widget__open-indicator'
                icon={isCalendarOpen ? faCaretUp : faCaretDown}
              />
            </button>

            {isCalendarOpen &&
              <DropWrapper
                dropWrapperRef={calendarRef}
                closeDropWrapper={toggleCalendarOpen}
              >
                <div ref={calendarRef}>
                  <Calendar
                    onChange={startFilteringByDate}
                    date={filter.date}
                    onClose={toggleCalendarOpen}
                    isCannotBeNull
                    userTimezone={userTimezone}
                  />
                </div>
              </DropWrapper>
            }
          </div>
        </div>

        <div className='clients-statistics-widget__chart-wrap'>
          <Line
            ref={chartRef}
            data={chartData}
            onElementsClick={onClick}
            options={{
              maintainAspectRatio: false,
              scales: {
                yAxes: [{
                  ticks: {
                    stepSize: chartStep,
                    beginAtZero: true,
                  }
                }]
              },
              plugins: {
                datalabels: {
                  display: false,
                },
              },
            }}
          />
        </div>

        {isPending &&
          <div className="clients-statistics-widget__pending">
            <Spinner />
          </div>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  userTimezone: selectUserTimezone(state),
});

export default connect(mapStateToProps)(NewClientsStatisticsWidget);