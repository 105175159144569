export const LIMIT = 20;
export const RINGTONES_LIMIT = 10;

export const TITLE = 'Admin ComDiva App';

export const ROUTES = {
  main: '/admin',
  login: '/admin/login',
  notFound: '/admin/not-found',
  accessDenied: '/admin/access-denied',
  couriers: '/admin/couriers',
  operators: '/admin/operators',
  shortcuts: '/admin/shortcuts',
  ringtones: '/admin/ringtones',
  voicemails: '/admin/voicemails',
  callsRecords: '/admin/calls-records',
  operatorsLogs: '/admin/operators-logs',
  cronJobs: '/admin/cron-jobs',
  systemRoles: '/admin/system-roles',
  statistics: '/admin/statistics',
  settings: '/admin/settings',
  glossary: '/admin/glossary',
  webMaster: '/admin/web-master',
  phoneNumbers: '/admin/phones',
  msgTemplates: '/admin/msg-templates',
  callsLog: '/admin/calls-log',
  smsLogs: '/admin/sms-logs',
  publicApi: '/admin/public-api',
  bookings: '/admin/bookings',
  clientsRating: '/admin/clients-rating',
  girlsRating: '/admin/girls-rating',
  operatorsRating: '/admin/operators-rating',
  contacts: '/admin/contacts',
  sessions: '/admin/sessions',
  tags: '/admin/tags',
};

export const PERMISSIONS = {
  // Auth
  Login: '/api-admin/auth/login',
  Logout: '/api-admin/auth/logout',
  //  Couriers
  getAllCouriers: '/api-admin/courier/all',
  createCouriers: '/api-admin/courier/create ',
  deleteCouriers: '/api-admin/courier/delete',
  changeStatusCourier: '/api-admin/courier/change-status',
  // Operators
  getAllOperators: '/api-admin/user/index',
  createOperators: '/api-admin/user/create',
  editOperators: '/api-admin/user/update',
  deleteOperators: '/api-admin/user/delete-user',
  getOperatorInactivityTime: '/api-admin/user/get-inactivity-time',
  editOperatorInactivityTime: '/api-admin/user/set-inactivity-time',
  // Ringtones
  getRingtones: '/api-admin/ringtone/index',
  getRingtonesByType: '/api-admin/ringtone/get-by-type',
  editSystemRingtones: '/api-admin/ringtone/update-system',
  editGroupsRingtones: '/api-admin/ringtone/update-groups',
  deleteRingtones: '/api-admin/ringtone/delete',
  editContactRingtone: '/api-admin/ringtone/set-contact-ringtone',
  getCustomRingtones: '/api-admin/ringtone/get-caller-ringtones',
  // Shortcuts
  getShortcuts: '/api-admin/shortcuts/index',
  createShortcuts: '/api-admin/shortcuts/create',
  editShortcuts: '/api-admin/shortcuts/update',
  deleteShortcuts: '/api-admin/shortcuts/delete',
  // Voicemails
  getVoicemails: '/api-admin/voicemail/get-all',
  deleteVoicemails: '/api-admin/voicemail/delete',
  // Calls Records
  getCallsRecords: '/api-admin/call-records/get-all',
  updateCallRecordMsg: '/api-admin/call-records/update',
  deleteCallsRecords: '/api-admin/call-records/delete',
  // Operators Logs
  getOperatorsLogs: '/api-admin/user-log/index',
  deleteOperatorsLogs: '/api-admin/user-log/delete',
  // Cronjobs
  getAllCronjobs: '/cronjob/index',
  getCronjobLogs: '/cronjob/main/get-logs',
  createCronjobs: '/cronjob/main/create',
  editCronjobs: '/cronjob/main/update',
  deleteCronjobs: '/cronjob/main/delete',
  stopCronjob: '/cronjob/main/kill-process',
  startCronjob: '/cronjob/main/run',
  // System Roles
  getOperatorRoles: '/api-admin/rbac/role/get-user-roles',
  editOperatorRoles: '/api-admin/rbac/role/update-user-role',
  getAllSystemRoles: '/api-admin/rbac/role/index',
  createSystemRole: '/api-admin/rbac/role/create',
  editSystemRole: '/api-admin/rbac/role/update',
  deleteSystemRole: '/api-admin/rbac/role/delete',
  // Permissions
  getPermissions: '/api-admin/rbac/permission/get-permissions',
  // Statistics:
  getStatistics: '/api-admin/statistics/index',
  getOperatorsActivity: '/api-admin/statistics/get-user-activity',
  getOperatorsProductivity: '/api-admin/statistics/get-user-productivity',

  // Chats:
  getUnreadMsgTime: '/api-admin/message/get-unread-time',
  setUnreadMsgTime: '/api-admin/message/set-unread-time',
  // Glossaries
  getGlossaries: '/api-admin/glossary/index',
  // Web Master
  getAllTasksForAdmin: '/api-admin/web-master/get-all-tasks-for-admin',
  // Phone Numbers
  getPhoneNumbers: '/api-admin/phones/index',
  createPhoneNumber: '/api-admin/phones/create',
  deletePhoneNumber: '/api-admin/phones/delete',
  updatePhoneNumber: '/api-admin/phones/update',
  getCountries: '/api-admin/phones/get-countries',

  //Settings
  getCallRedirectTime: '/api-admin/settings/get-call-redirect-time',
  setCallRedirectTime: '/api-admin/settings/set-call-redirect-time',
  getNightMode: '/api-admin/settings/get-night-mode',
  setNightMode: '/api-admin/settings/set-night-mode',

  // Templates
  getMsgTemplates: '/api-admin/templates/index',
  createMsgTemplate: '/api-admin/templates/create',
  editMsgTemplate: '/api-admin/templates/update',
  deleteMsgTemplate: '/api-admin/templates/delete',
  // Client Interaction
  getAllClientInteractions: '/api-admin/caller-interaction/get-all-interactions',
  // Public API
  getAllPublicApps: '/api-admin/application/get-all',
  createPublicApp: '/api-admin/application/create',
  updatePublicApp: '/api-admin/application/edit',
  deletePublicApp: '/api-admin/application/delete',
  // Bookings
  getBookings: '/api-admin/booking/index',
  getBookingsStatistic: '/api-admin/booking/get-booking-statistic',

  // Ratings
  clientsRating: '/api-admin/ratings/most-profitable-client',
  girlsRating: '/api-admin/ratings/most-profitable-girl',
  operatorsRating: '/api-admin/ratings/most-profitable-user',
  // Dashboard
  getAllOperatorsForWidget: '/api-admin/dashboard/index',
  getLastCalls: '/api-admin/dashboard/get-calls',
  getMissedCalls: '/api-admin/dashboard/get-missed-call',
  getInteractionsCountByToday: '/api-admin/dashboard/get-count-interaction',
  getIncomingCallsCountByDate: '/api-admin/dashboard/get-count-calls-by-interval',
  getIncomingCallsWaitingTime: '/api-admin/dashboard/get-waiting-time',
  getInternalCallsDuration: '/api-admin/dashboard/get-duration-of-internal-calls-by-period',
  getCallsDuration: '/api-admin/dashboard/get-duration-of-calls-by-period',
  getNewClientsStatistics: '/api-admin/dashboard/get-contacts-count',
  // Contacts:
  getContacts: '/api-admin/contacts',
  // Sessions
  getSessions: '/api-admin/sales-session/index',
  deleteSessions: '/api-admin/sales-session/delete',
  // Tag colors
  getTagColors: '/api-admin/colors/index',
  createTagColor: '/api-admin/colors/create',
  updateTagColor: '/api-admin/colors/update',
  deleteTagColor: '/api-admin/colors/delete',
};

export const contactsTypes = [
  { id: 1, caller_type: 'client', name: 'Clients' },
  { id: 2, caller_type: 'girl', name: 'Girls' },
  { id: 3, caller_type: 'agent', name: 'Agents' }
];

export const callersTypes = Object.freeze<{ [key: number]: string }>({
  1: 'client',
  2: 'girl',
  3: 'agent',
  4: 'services',
});

export const COMPONENT_FADE_OUT_TIME: number = 500;

export const MSG_PEACES = Object.freeze({
  GIRL_NAME: 'girlName',
  MENTION: 'mention',
  LINK: 'link',
  TEXT: 'text'
});

export const INTERACTION_TYPE = Object.freeze({
  CALL: {
    ALL: [1, 2],
    INCOMING: 1,
    OUTGOING: 2,
  },
  SMS: {
    ALL: [3, 4],
    OUTGOING: 3,
    INCOMING: 4,
  },
});

export const BOOKINGS_STATUS = Object.freeze({
  0: 'canceled',
  1: 'pending',
  2: 'in-progress',
  3: 'finished',
  4: 'future',
  5: 'pre-pending'
});

export const CONTACT_TYPES = Object.freeze({
  CLIENT: 1,
  GIRL: 2,
  AGENT: 3,
});

export const DIVA_GIRLS_IMG_HOST = 'https://media.divaescort.com/images/media/';

export const componentsFadeOutTime: number = 500;