import React from 'react';
import { connect } from 'react-redux';

import { ICheckPermsProps } from './CheckPerms.types';
import { AppState } from '../../redux/store';

const CheckPerms = (props: ICheckPermsProps) => {
  const {
    permission,
    userPermissions,
  } = props;

  let isAccessDenied: boolean = false;

  if (Array.isArray(permission)) {
    if (!permission.length) {
      return null;
    }

    let i = permission.length;
    let isPermissionInUserPermissions = false;

    while (i--) {
      if (userPermissions.includes(permission[i])) {
        isPermissionInUserPermissions = true;
      }
      else {
        isAccessDenied = true;
      }
    }

    if (isPermissionInUserPermissions) {
      isAccessDenied = false;
    }
  }
  else if (!userPermissions.includes(permission)) {
    isAccessDenied = true;
  }

  if (isAccessDenied && !userPermissions.includes('/*')) {
    return null;
  }

  return <>{props.children}</>;
};

const mapStateToProps = (state: AppState) => ({
  userPermissions: state.user.permissions,
});

export default connect(mapStateToProps)(CheckPerms);