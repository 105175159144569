import React from 'react';

import { ROUTES } from '../../config/constants';
import { withRouter, RouteComponentProps } from 'react-router';

import './AccessDenied.scss';


const AccessDenied = (props: RouteComponentProps) => {
  const goToMainPage = (): void => {
    props.history.push(ROUTES.main);
  }

  return (
    <div className="card access-denied">
      <h2 className='access-denied__title'>Access Denied</h2>

      <button
        className="btn btn--primary access-denied__btn-home"
        onClick={goToMainPage}
      >
        Go to main page
      </button>
    </div>
  );
};

export default withRouter(AccessDenied);