import { Dispatch } from "redux";
import { IActiveWindowsState } from "./types";
import { IAction } from "../../interfaces/interfaces";

export const PLAY_AUDIO = 'PLAY_AUDIO';


export const playAudio = (audio: EventTarget) => (dispatch: Dispatch) => {
  dispatch({
    type: PLAY_AUDIO,
    payload: audio,
  })
};


const initialState: IActiveWindowsState = {
  audio: null,
};

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case PLAY_AUDIO: {
      const { audio } = state;

      if (audio && audio === action.payload) {
        return state;
      }
      else if (audio && audio !== action.payload) {
        audio.pause();
        audio.currentTime = 0;
      }

      return {
        ...state,
        audio: action.payload,
      };
    }
    default:
      return state;
  }
}