import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { Layouts } from 'react-grid-layout';

import {
  ILoginParams,
  IOperatorApi,
  ICronJobApi,
  ISystemRoleApi,
  IMsgTemplate,
  IPublicApp,
  IContact,
  ICourierApi,
  // IContactTag
} from "../interfaces/interfaces";
import { LIMIT, ROUTES, RINGTONES_LIMIT } from "../config/constants";
import { INormalizedCountry } from "../containers/PhoneNumbersSettings/components/CountriesMultiSelect/setAvailableCountries.types";
import { IRatingsGetParams, RatingType } from "../containers/Ratings/Ratings.types";
import { IBookingsConfig } from "../containers/BookingsSettings/BookingsSettings.types";
import { IContactsConfig } from "../containers/ContactsSettings/ContactsSettings.types";
import { ISessionsConfig } from "../containers/SessionsSettings/SessionsSettings.types";


const loginUser = (credentials: ILoginParams) => {
  const Credentials = new FormData();

  Credentials.set('username', credentials.username);
  Credentials.set('password', credentials.password);
  Credentials.set("timezone", Intl.DateTimeFormat().resolvedOptions().timeZone);

  return axios.post('auth/login', Credentials)
};

const logoutUser = () => {
  return axios.get('auth/logout')
    .catch(err => console.log(err))
    .finally(() => {
      delete localStorage.isLoggedIn;
      delete localStorage.currentUserId;

      window.location.href = ROUTES.login;
    })
};

//ringtones------------------------------------------------------
const getRingtonesByType = (type: 'call' | 'sms', offset: number = 0): Promise<AxiosResponse> =>
  axios.get('ringtone/get-by-type', { params: { type, limit: RINGTONES_LIMIT, offset } });
;

const searchContacts = (query: string, offset: number, limit: number): Promise<AxiosResponse> =>
  axios.get('callers/get-by-query', { params: { query, offset, limit } });

const getRingtones = (type: string): Promise<AxiosResponse> =>
  axios.get("ringtone", { params: { type } });

const getRingtonesDefault = (callerType?: number, triggerType?: number): Promise<AxiosResponse> => {
  const Ringtone = new FormData();
  triggerType && Ringtone.set("type", String(triggerType));
  callerType && Ringtone.set("callerType", String(callerType));

  return axios.post("ringtone/set-default", Ringtone);
}

const deleteRingtones = (callerId: number, type?: string | null): Promise<AxiosResponse> => {
  const Ringtone = new FormData();

  Ringtone.set("callerId", String(callerId));
  type && Ringtone.set("type", type);

  return axios.post('ringtone/delete', Ringtone)
};

// const updateGroupRingtones = (ids: number[], files: File[]): Promise<AxiosResponse> => {
//   const GroupRingtones = new FormData();

//   GroupRingtones.set('ids', JSON.stringify(ids));

//   if (files.length === 1) {
//     GroupRingtones.set("files", files[0]);
//   }
//   else {
//     files.forEach(file => GroupRingtones.append("files[]", file));
//   }


//   return axios.post('ringtone/update-groups', GroupRingtones);
// };

const changeRingtone = (id: number, triggerType: number, callerType: number): Promise<AxiosResponse> => {
  const Ringtone = new FormData();
  Ringtone.set("id", String(id));
  Ringtone.set("type", String(triggerType));
  Ringtone.set("callerType", String(callerType));

  return axios.post('ringtone/set-for-group', Ringtone);
}

const getAvailableRingtones = (triggerType: number | boolean, query: string, offset: number, limit: number): Promise<AxiosResponse> =>
  axios.get("ringtone/available", { params: { type: triggerType, title: query, offset, limit } });

// const updateSystemRingtone = (file: File, id: number): Promise<AxiosResponse> => {
//   const SystemRingtone = new FormData();

//   SystemRingtone.set("id", String(id));
//   SystemRingtone.set("file", file);

//   return axios.post("ringtone/update-system", SystemRingtone);
// };

const setContactRingtones = (
  contactId: number,
  callId?: number | null,
  smsId?: number | null
): Promise<AxiosResponse> => {
  const Ringtone = new FormData();

  Ringtone.set("contactId", String(contactId));
  callId && Ringtone.set("callId", String(callId));
  smsId && Ringtone.set("smsId", String(smsId));

  return axios.post("ringtone/set-contact-ringtone", Ringtone);
};

const getContactRingtones = (offset: number = 0): Promise<AxiosResponse> =>
  axios.get(`ringtone/get-caller-ringtones?offset=${offset}&limit=${LIMIT}`);

//couriers------------------------------------------------------
const getAllCouriers = (): Promise<AxiosResponse> => {
  return axios.get("courier/all");
};

const createCourier = (courier: ICourierApi): Promise<AxiosResponse> => {

  const NewOperator: FormData = new FormData();

  const { username, email, password } = courier;

  username && NewOperator.set("username", username);
  password && NewOperator.set("password", password);
  email && NewOperator.set("email", email);

  return axios.post("courier/create", NewOperator);
};

const deleteCourier = (id: number): Promise<AxiosResponse> => {

  const config = {
    params: {
      id,
    }
  }

  return axios.get("courier/delete", config);
};

const changeCourierStatus = (courier: ICourierApi): Promise<AxiosResponse> => {
  const EditedOperator: FormData = new FormData();

  EditedOperator.set("id", String(courier.id));
  EditedOperator.set("status", String(courier.status));

  return axios.post("courier/change-status", EditedOperator);
};

//Settings------------------------------------------------------
const getCallRedirect = (): Promise<AxiosResponse> =>
  axios.get('settings/get-call-redirect-time');

const updateCallRedirect = (
  config: {
    redirectActive?: boolean,
    seconds?: string
  }): Promise<AxiosResponse> => {
  const { redirectActive, seconds } = config;
  const CallRedirectTime = new FormData();

  (redirectActive !== undefined) && CallRedirectTime.set('redirectActive', String(redirectActive));
  seconds && CallRedirectTime.set('seconds', seconds);

  return axios.post('settings/set-call-redirect-time', CallRedirectTime);
};

const getAvailableNowDuration = () => axios.get('settings/get-duration-of-available-now');

const setAvailableNowDuration = (minutes: number): Promise<AxiosResponse> => {
  const DurationData = new FormData();

  DurationData.set('duration', JSON.stringify(minutes));

  return axios.post('settings/set-duration-available-now', DurationData);
};

const getTimeGap = () => axios.get('settings/get-config');

const setTimeGap = (gapType: string, gapValue: number) => {
  const GapData = new FormData();
  
  GapData.set(gapType, String(gapValue));

  return axios.post('settings/set-gap-between-bookings', GapData);
};

//shortcuts------------------------------------------------------
const getShortcuts = (offset: number = 0, categoryId: number | string = '') => {
  const params: { [key: string]: any } = {};

  params.offset = offset;
  params.limit = LIMIT;
  if (categoryId) {
    params.categoryId = categoryId;
  }

  return axios.get('shortcuts', { params });
};

const searchShortcuts = (query: string, offset?: number | undefined) => {
  const config = {
    params: {
      query,
      limit: LIMIT,
      offset: offset || ""
    }
  };

  return axios.get("shortcuts/search", config);
};

const deleteShortcuts = (arr: number[]) => {
  const Ids = new FormData();

  Ids.set("ids", JSON.stringify(arr));

  return axios.post("shortcuts/delete", Ids);
};

const addNewShortcut = (
  shortcut: string,
  value: string,
  category: { id: number | string, value: string } | null
) => {
  const Shortcut = new FormData();

  if (category) {
    category.id === 'new-category'
      ? Shortcut.set("newCategory", category.value)
      : Shortcut.set("category_id", String(category.id));
  }

  Shortcut.set("shortcut", shortcut);
  Shortcut.set("full", value);

  return axios.post("shortcuts/create", Shortcut);
};

const editShortcut = (
  shortcut: string,
  value: string,
  id: number,
  category: { id: number | string, value: string } | null
) => {
  const Shortcut = new FormData();

  if (category) {
    category.id === "new-category"
      ? Shortcut.set("newCategory", category.value)
      : Shortcut.set("category_id", String(category.id));
  }
  else {
    Shortcut.set("clearCategory", String(1));
  }

  Shortcut.set("shortcut", shortcut);
  Shortcut.set("full", value);
  Shortcut.set("id", String(id));

  return axios.post("shortcuts/update", Shortcut);
};

const getAllOperators = (): Promise<AxiosResponse> => {
  return axios.get("user/index");
};

const createOperator = (operator: IOperatorApi): Promise<AxiosResponse> => {
  const NewOperator: FormData = new FormData();

  const { username, phone, email, file, password, active } = operator;

  username && NewOperator.set("username", username);
  phone && NewOperator.set("phone", phone);
  email && NewOperator.set("email", email);
  file && NewOperator.set("file", file);
  password && NewOperator.set("password", password);
  active && NewOperator.set("active", String(active));

  return axios.post("user/create", NewOperator);
};

const editOperator = (operator: IOperatorApi): Promise<AxiosResponse> => {
  const EditedOperator: FormData = new FormData();

  const { username, phone, email, file, password, active, id } = operator;

  username && EditedOperator.set("username", username);
  phone && EditedOperator.set("phone", phone);
  email && EditedOperator.set("email", email);
  file && EditedOperator.set("file", file);
  password && EditedOperator.set("password", password);
  (active === 0 || active) && EditedOperator.set("active", String(active));
  EditedOperator.set("id", String(id));

  return axios.post("user/update", EditedOperator);
};

const deleteOperator = (id: number): Promise<AxiosResponse> => {
  const DeletedOperator: FormData = new FormData();

  DeletedOperator.set("id", String(id));

  return axios.post("user/delete-user", DeletedOperator);
};

const getIsIAmLoggedIn = (): Promise<AxiosResponse> => {
  return axios.get("user/get-current-user");
};

const getOperatorsLogs = (
  offset: number = 0,
  config: {
    date: number | number[] | null;
    operatorId: number | null;
    action: string | null;
  }
): Promise<AxiosResponse> => {
  const { date, operatorId, action } = config;
  const OperatorLogs: FormData = new FormData();

  OperatorLogs.set("offset", String(offset));
  OperatorLogs.set("limit", String(LIMIT));
  operatorId && OperatorLogs.set("userId", String(operatorId));
  action && OperatorLogs.set("action", action);

  if (Array.isArray(date)) {
    OperatorLogs.set("date", String(date[0]));
    OperatorLogs.set("dateRange", String(date[1]));
  } else {
    date && OperatorLogs.set("date", String(date));
  }

  return axios.post("user-log/", OperatorLogs);
};

const deleteOperatorLogs = (id: number): Promise<AxiosResponse> => {
  const OperatorLogs = new FormData();

  OperatorLogs.set("id", String(id));

  return axios.post("user-log/delete", OperatorLogs);
};

const getOperatrLogActions = (): Promise<AxiosResponse> => {
  return axios.post("user-log/get-actions");
};

const getCallsRecords = (
  offset: number = 0,
  config: {
    date: number | number[] | null;
    operatorId: number | null;
    direction: string | null;
    clientId: number | null;
    isMarked: string | null;
    message: string | null;
  }
): Promise<AxiosResponse> => {
  const { date: dateParameter } = config;

  const addDateParams = (conf: {
    date: number | number[] | null;
    operatorId: number | null;
    direction: string | null;
    clientId: number | null;
    isMarked: string | null;
    message: string | null;
    limit: number;
    offset: number;
  }) => {
    if (Array.isArray(dateParameter)) {
      return {
        ...conf,
        date: dateParameter[0],
        dateRange: dateParameter[1]
      }
    } else {
      return {
        ...conf,
        date: !!dateParameter
          ? dateParameter
          : null,
      }
    }
  }

  return axios.get("call-records/get-all", {
    params: addDateParams({
      ...config,
      limit: LIMIT,
      offset,
    })
  });
};

const getSenderGsm = (): Promise<AxiosResponse> => axios.get("phones/get-gsm");

const setSenderGsm = (gsm: string) => {
  const Gsm = new FormData();

  Gsm.set("gsm", gsm);

  return axios.post('phones/change-sender', Gsm);
}

const getBalance = (id: number) => {
  const config = {
    params: {
      id,
    }
  }

  return axios.get("dinstar/balance", config);
}

const updateCallRecordMsg = (id: number, message?: string): Promise<AxiosResponse> => {
  const Message = new FormData();

  Message.set("id", String(id));
  typeof message === 'string' && Message.set("message", message);

  return axios.post("call-records/update", Message);
};

const deleteCallRecord = (id: number): Promise<AxiosResponse> => {
  const DeletedCallRecord = new FormData();

  DeletedCallRecord.set("id", String(id));

  return axios.post("call-records/delete", DeletedCallRecord);
};

const getCronJobs = (): Promise<AxiosResponse> => {
  const CronJob = new FormData();

  return axios.post("cronjob/", CronJob);
};

const editCronJob = (cronJob: ICronJobApi): Promise<AxiosResponse> => {
  const CronJob = new FormData();
  const { id, title, description, cron_string, active } = cronJob;

  CronJob.set("id", String(id));
  title && CronJob.set("title", title);
  description && CronJob.set("description", description);
  cron_string && CronJob.set("cron_string", cron_string);
  (active === 0 || active) && CronJob.set("active", String(active));

  return axios.post("cronjob/main/update", CronJob);
};

const startCronJob = (id: number): Promise<AxiosResponse> => {
  const CronJob = new FormData();

  CronJob.set("id", String(id));

  return axios.post("cronjob/main/run", CronJob);
};

const stopCronJob = (id: number): Promise<AxiosResponse> => {
  const CronJob = new FormData();

  CronJob.set("id", String(id));

  return axios.post("cronjob/main/kill-process", CronJob);
};

const getCronJobLogs = (cronJobId: number, offset: number = 0): Promise<AxiosResponse> => {
  const Logs = new FormData();

  Logs.set("limit", String(LIMIT));
  Logs.set("offset", String(offset));
  Logs.set("jobId", String(cronJobId));

  return axios.post("cronjob/main/get-logs", Logs);
};

const getSystemRoles = (): Promise<AxiosResponse> => {
  return axios.post("rbac/role");
};

const getOperatorSystemRoles = (operatorId: number): Promise<AxiosResponse> => {
  const OperatorRoles = new FormData();

  OperatorRoles.set("userId", String(operatorId));

  return axios.post("rbac/role/get-user-roles", OperatorRoles);
};

const editOperatorSystemRoles = (operatorId: number, roles: string[]): Promise<AxiosResponse> => {
  const EditOperatorRoles = new FormData();

  EditOperatorRoles.set("userId", String(operatorId));
  EditOperatorRoles.set("roles", JSON.stringify(roles));

  return axios.post("rbac/role/update-user-role", EditOperatorRoles);
};

const createSystemRole = (role: ISystemRoleApi): Promise<AxiosResponse> => {
  const NewSystemRole = new FormData();
  const { name, description, permissions } = role;

  name && NewSystemRole.set("name", name);
  description && NewSystemRole.set("description", description);
  permissions && NewSystemRole.set("permissions", JSON.stringify(permissions));

  return axios.post("rbac/role/create", NewSystemRole);
};

const editSystemRole = (role: ISystemRoleApi): Promise<AxiosResponse> => {
  const SystemRole = new FormData();
  const { name, description, oldName, permissions } = role;

  name && SystemRole.set("name", name);
  description && SystemRole.set("description", description);
  oldName && SystemRole.set("oldName", oldName);
  permissions && SystemRole.set("permissions", JSON.stringify(permissions));

  return axios.post("rbac/role/update", SystemRole);
};

const deleteSystemRole = (roleName: string): Promise<AxiosResponse> => {
  const DeleteRole = new FormData();

  DeleteRole.set("role", roleName);

  return axios.post("rbac/role/delete", DeleteRole);
};

const getPermissions = (
  offset: number = 0,
  roleName?: string,
  full: boolean = false,
  noLimit: boolean = false
): Promise<AxiosResponse> => {
  const Permissions = new FormData();

  !noLimit && Permissions.set("limit", String(LIMIT));
  Permissions.set("offset", String(offset));
  full && Permissions.set("full", "1");
  roleName && Permissions.set("role", roleName);

  return axios.post("rbac/permission/get-permissions", Permissions);
};

const getPermissionsByGroup = (): Promise<AxiosResponse> => {
  const Permissions = new FormData();

  return axios.post("rbac/permission/get-permission-by-group", Permissions);
};

const getClients = (type: number = 0, offset: number = 0): Promise<AxiosResponse> => {
  const Clients = new FormData();

  Clients.set("offset", String(offset));
  Clients.set("limit", String(LIMIT));
  type && Clients.set("type", String(type));

  return axios.post("callers", Clients);
};

const searchClients = (query: string, type?: number, offset: number = 0) => {
  const SearchClients = new FormData();

  SearchClients.set("query", query);
  SearchClients.set("offset", String(offset));
  SearchClients.set("limit", String(LIMIT));
  type && SearchClients.set("type", String(type));

  return axios.post("callers/search", SearchClients);
};

const getVoicemails = (
  offset: number = 0,
  config: {
    date: number | number[] | null;
    listened: string | null;
  }
): Promise<AxiosResponse> => {
  const { date, listened } = config;
  const Voicemails = new FormData();

  Voicemails.set("offset", String(offset));
  Voicemails.set("limit", String(LIMIT));
  listened && Voicemails.set("listened", listened);

  if (Array.isArray(date)) {
    Voicemails.set("date", String(date[0]));
    Voicemails.set("dateRange", String(date[1]));
  } else {
    date && Voicemails.set("date", String(date));
  }

  return axios.post("voicemail/get-all", Voicemails);
};

const deleteVoicemail = (id: number = 0): Promise<AxiosResponse> => {
  const Voicemail = new FormData();

  Voicemail.set("id", String(id));

  return axios.post("voicemail/delete", Voicemail);
};

const getStatistics = (
  config: {
    date?: number | number[];
    sort?: number;
  } = {}
): Promise<AxiosResponse> => {
  const { date, sort } = config;
  const Statistics = new FormData();

  if (Array.isArray(date)) {
    Statistics.set("date", String(date[0]));
    Statistics.set("dateRange", String(date[1]));
  } else {
    date && Statistics.set("date", String(date));
  }

  sort && Statistics.set("sort", String(sort)); // Sort by Alphabet

  return axios.post("statistics/", Statistics);
};

const getOperatorActivity = (config: {
  action: (number | string)[];
  date?: number | number[] | null;
  operatorId: number[];
}): Promise<AxiosResponse> => {
  const { action, date, operatorId } = config;
  const Statistics = new FormData();

  if (Array.isArray(date)) {
    Statistics.set("date", String(date[0]));
    Statistics.set("dateRange", String(date[1]));
  }
  else {
    date && Statistics.set('date', String(date));
  }
  action && Statistics.set('action', JSON.stringify(action));
  Statistics.set('timeIntervals', String(8)); // For getting dates only for days interval
  operatorId && Statistics.set('userId', JSON.stringify(operatorId));

  return axios.post('statistics/get-user-activity', Statistics);
}

const getOperatorProductivity = (params: {
  type: number;
  filterDate: number | number[] | null;
  operatorId: number[] | null;
}): Promise<AxiosResponse> => {
  const { type, filterDate, operatorId } = params;
  const config: {
    params: {
      type: number;
      userId?: string | null;
      date?: number;
      dateRange?: number;
    }
  } = {
    params: {
      type,
      userId: operatorId && JSON.stringify(operatorId),
    }
  }

  if (Array.isArray(filterDate)) {
    config.params.date = (filterDate[0]);
    config.params.dateRange = (filterDate[1]);
  } else if (filterDate) {
    config.params.date = (filterDate);
  }

  return axios.get('statistics/get-user-productivity', config);
}

const editOperatorInactivityTime = (time: number): Promise<AxiosResponse> => {
  const InactivityTime = new FormData();

  InactivityTime.set("inactivityTime", String(time * 60));

  return axios.post("user/set-inactivity-time", InactivityTime);
};

const getOperatorInactivityTime = (): Promise<AxiosResponse> => {
  return axios.post("user/get-inactivity-time");
};

const getInteractionsCount = (): Promise<AxiosResponse> => {
  return axios.post("dashboard/get-count-interaction");
};

const setUnreadMsgTime = (time: number): Promise<AxiosResponse> => {
  const MaxTime = new FormData();

  MaxTime.set("time", String(time * 24 * 60 * 60));
  //PHP needs timestamp at seconds lol

  return axios.post("message/set-unread-time", MaxTime);
};

const getUnreadMsgTime = (): Promise<AxiosResponse> => axios.post("message/get-unread-time");

const getGlossaryMessages = (
  offset?: number,
  filters: {
    filterType?: string,
    filterFrequency?: string,
    filterUser?: number,
    filterDate?: number | number[],
    filterCallerId?: number,
  } = {},
): Promise<AxiosResponse> => {
  const config: any = {
    params: {
      limit: LIMIT,
      offset: offset || "",
      type: filters.filterType || "",
      frequency: filters.filterFrequency || "",
      userId: filters.filterUser || "",
      callerId: filters.filterCallerId || "",
    }
  }

  if (Array.isArray(filters.filterDate)) {
    config.params.dateStart = (filters.filterDate[0]);
    config.params.dateEnd = (filters.filterDate[1]);
  } else if (filters.filterDate) {
    config.params.dateStart = (filters.filterDate);
  }

  return axios.get("glossary/index", config);
};

const searchGlossaryMsg = (query: string, offset?: number): Promise<AxiosResponse> => {
  const config = {
    params: {
      q: query,
      limit: LIMIT,
      offset
    }
  }

  return axios.get("glossary/search", config);
}

const deleteGlossaryMsg = (id: number) => {
  const config = {
    params: {
      id
    }
  }

  return axios.get("glossary/delete", config);
}

const updateGlossaryMsg = (message: string, id: number) => {
  const Message = new FormData();

  Message.set("message", message);
  Message.set("id", String(id));

  return axios.post("glossary/update", Message);
}

const getWebMasterTasks = (
  config: {
    status?: number | null,
    date?: number | number[] | null,
    userFinisherId?: number | null,
    userCreatorId?: number | null,
  },
  offset: number = 0,
): Promise<AxiosResponse> => {
  const { status, date, userFinisherId, userCreatorId } = config;
  const WebMaster = new FormData();

  WebMaster.set("offset", String(offset));
  WebMaster.set("limit", String(LIMIT));

  if (Array.isArray(date)) {
    WebMaster.set("date", String(date[0]));
    WebMaster.set("dateRange", String(date[1]));
  } else {
    date && WebMaster.set("date", String(date));
  }

  status && WebMaster.set('status', String(status));
  userFinisherId && WebMaster.set('userFinisherId', String(userFinisherId));
  userCreatorId && WebMaster.set('userCreatorId', String(userCreatorId));

  return axios.post("web-master/get-all-tasks-for-admin", WebMaster);
};

const getPhoneNumbers = (offset: number = 0, limit: number = 20): Promise<AxiosResponse> =>
  axios.get(`phones/index?offset=${offset}&limit=${limit}`);

const removePhoneNumber = (id: number): Promise<AxiosResponse> =>
  axios.get(`phones/delete?id=${id}`);

const submitEditPhoneNumber = (
  phoneNumber: string,
  isDefault: number,
  caller_type: string,
  id: number,
  countries: INormalizedCountry[] = []
): Promise<AxiosResponse> => {
  const PhoneNumber = new FormData();

  const formatedCountries = countries
    ? countries.map(c => c.value)
    : [];

  PhoneNumber.set("number", phoneNumber);
  PhoneNumber.set("default", String(isDefault));
  PhoneNumber.set("caller_type", caller_type);
  PhoneNumber.set("id", String(id));
  PhoneNumber.set("countries", JSON.stringify(formatedCountries));

  return axios.post("phones/update", PhoneNumber);
};

const createPhoneNumber = (phoneNumber: string, caller_type: string, countries: INormalizedCountry[] = []): Promise<AxiosResponse> => {
  const PhoneNumber = new FormData();

  const formatedCountries = countries
    ? countries.map(c => c.value)
    : [];

  PhoneNumber.set("number", phoneNumber);
  PhoneNumber.set("default", String(0));
  PhoneNumber.set("caller_type", caller_type);
  PhoneNumber.set("countries", JSON.stringify(formatedCountries));

  return axios.post("phones/create", PhoneNumber);
};

const getCountries = (): Promise<AxiosResponse> => axios.get('phones/get-countries');

const searchWebMasterTasks = (query: string, limit: number, offset: number = 0) => {
  const SearchData = new FormData();

  SearchData.set("query", query);
  SearchData.set("limit", String(limit));
  SearchData.set("offset", String(offset));

  return axios.post("web-master/search", SearchData);
};
const getMsgTemplates = (offset?: number, filter?: string): Promise<AxiosResponse> => {
  const config = {
    params: {
      limit: LIMIT,
      offset: offset || "",
      caller_type: filter || ""
    }
  };

  return axios.get('templates', config);
}

const createMsgTemplate = (text: string, contactType: number): Promise<AxiosResponse> => {
  const MsgTemplate = new FormData();

  MsgTemplate.set('text', text);
  MsgTemplate.set('caller_type', String(contactType));

  return axios.post('templates/create', MsgTemplate);
}

const updateMsgTemplate = (template: IMsgTemplate): Promise<AxiosResponse> => {
  const MsgTemplate = new FormData();

  MsgTemplate.set('text', template.text);
  MsgTemplate.set('caller_type', String(template.caller_type));
  MsgTemplate.set('id', String(template.id));

  return axios.post('templates/update', MsgTemplate);
}

const deleteMsgTemplate = (templateId: number): Promise<AxiosResponse> => {
  const MsgTemplate = new FormData();

  MsgTemplate.set('id', String(templateId));

  return axios.post('templates/delete', MsgTemplate);
}
const searchMsgTemplates = (query: string, offset?: number): Promise<AxiosResponse> => {
  const MsgTemplate = new FormData();

  MsgTemplate.set('text', query);
  offset && MsgTemplate.set('offset', String(offset));

  return axios.post('templates/search', MsgTemplate);
};

const getShortcutCategories = (): Promise<AxiosResponse> =>
  axios.get('shortcuts/get-categories');

const getPublicApps = (): Promise<AxiosResponse> =>
  axios.get('application/get-all');

const createPublicApp = (name: string): Promise<AxiosResponse> => {
  const PublicApp = new FormData();

  PublicApp.set('name', name);

  return axios.post('application/create', PublicApp);
}

const updatePublicApp = (app: IPublicApp): Promise<AxiosResponse> => {
  const PublicApp = new FormData();

  PublicApp.set('application_id', String(app.id));
  PublicApp.set('name', app.name);
  PublicApp.set('active', String(app.active));

  return axios.post('application/update', PublicApp);
}

const deletePublicApp = (appId: number): Promise<AxiosResponse> => {
  const PublicApp = new FormData();

  PublicApp.set('application_id', String(appId));

  return axios.post('application/delete', PublicApp);
}

const getAllInteractions = (
  filters: {
    filterType: number | number[],
    filterPhone?: string,
    filterOperatorId?: number,
    filterContactId?: number,
    filterDate?: number | number[],
  },
  offset?: number,
): Promise<AxiosResponse> => {
  const { filterPhone, filterType, filterOperatorId, filterContactId, filterDate } = filters;

  const config: any = {
    params: {
      limit: LIMIT,
      offset: offset || "",
      type: Array.isArray(filterType) ? JSON.stringify(filterType) : filterType,
      user_id: filterOperatorId || null,
      caller_id: filterContactId || null,
      phone_number: filterPhone || null,
    }
  }

  if (Array.isArray(filterDate)) {
    config.params.date = (filterDate[0]);
    config.params.dateRange = (filterDate[1]);
  } else if (filterDate) {
    config.params.date = (filterDate);
  }

  return axios.get("caller-interaction/get-all-interactions", config);
};

const getBookings = (filters: IBookingsConfig, offset?: number): Promise<AxiosResponse> => {
  const { filterProfileId, filterContactId, filterOperatorId, filterDate, filterType = null } = filters;

  const config: { params: { [key: string]: string | number | null } } = {
    params: {
      limit: LIMIT,
      offset: offset || "",
      profileId: filterProfileId || null,
      callerId: filterContactId || null,
      userId: filterOperatorId || null,
      isSuccess: filterType || null,
    },
  }

  if (Array.isArray(filterDate)) {
    config.params.date = (filterDate[0]);
    config.params.dateRange = (filterDate[1]);
  } else if (filterDate) {
    config.params.date = (filterDate);
  }

  return axios.get("booking", config);
};

const getBookingsAndProfitForChart = (filters: IBookingsConfig): Promise<AxiosResponse> => {
  const { filterProfileId, filterContactId, filterOperatorId, filterDate, filterType = null, chartDataType } = filters;

  const config: { params: { [key: string]: string | number | null } } = {
    params: {
      profileId: filterProfileId || null,
      callerId: filterContactId || null,
      userId: filterOperatorId || null,
      isSuccess: filterType || null,
      type: chartDataType || 1,
    },
  }

  if (Array.isArray(filterDate)) {
    config.params.date = (filterDate[0]);
    config.params.dateRange = (filterDate[1]);
  } else if (filterDate) {
    config.params.date = (filterDate);
  }

  return axios.get("booking/get-booking-statistic", config);
};

const getDivaGirls = (params: { [key: string]: string | number }): Promise<AxiosResponse> => {
  const newParams: { [key: string]: string | number } = {};
  const config: { params: { [key: string]: string | number } } = { params: {} };
  const setParam = (key: string, value: string | number) => {
    newParams[key] = value;
  }

  if (!params.part) {
    params.part = 1;
  }
  if (!params.limit) {
    params.limit = LIMIT;
  }
  params['filter-ids'] && setParam('filter-ids', params['filter-ids']);

  config.params = newParams;

  return axios.get('diva/girls', config);
};

const getRatingItems = (type: RatingType, getParams: IRatingsGetParams = {}) => {
  const config = {
    params: {
      limit: LIMIT,
      ...getParams
    }
  }

  return axios.get('ratings/most-profitable-' + type, config)
}

const updateWidgetLayouts = (gridLayouts: Layouts): Promise<AxiosResponse> => {
  const WidgetLayouts = new FormData();

  WidgetLayouts.set('widgetLayout', JSON.stringify(gridLayouts));

  return axios.post('user/update-widget-layouts-for-current-user', WidgetLayouts);
};

const updateActiveWidgets = (activeWidgets: string[]): Promise<AxiosResponse> => {
  const ActiveWidgets = new FormData();

  ActiveWidgets.set('activeWidgets', JSON.stringify(activeWidgets));

  return axios.post('user/update-active-widgets-for-current-user', ActiveWidgets);
};

//Widgets
const getAllOperatorsForWidget = (): Promise<AxiosResponse> => {
  return axios.get("dashboard/index");
};

const getLastCalls = (config: {
  limit: number,
  offset: number,
  type?: string | null,
}): Promise<AxiosResponse> => {
  return axios.get('dashboard/get-calls', { params: config });
};

const getMissedCalls = (config: {
  limit: number,
  offset: number,
  status: string | null,
}) => axios.get('dashboard/get-missed-call', { params: config });

const getCallsDurationByPeriod = (type: 'incoming' | 'outgoing', timeIntervals: [number, number] | null): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = {
    params: { type }
  };

  if (timeIntervals) {
    config.params.date = timeIntervals[0];
    config.params.dateRange = timeIntervals[1];
  }

  return axios.get('dashboard/get-duration-of-calls-by-period', config);
};

const getIncomingCallsWaitingTime = (timeIntervals: [number, number]): Promise<AxiosResponse> => {
  const config = {
    params: {
      date: timeIntervals[0],
      dateRange: timeIntervals[1],
    },
  }

  return axios.get('dashboard/get-waiting-time', config);
}

const getIncomingCallsCountByInterval = (timeIntervals: [number, number]): Promise<AxiosResponse> => {
  const config = {
    params: {
      date: timeIntervals[0],
      dateRange: timeIntervals[1],
    },
  }

  return axios.get('dashboard/get-count-calls-by-interval', config);
}

const getDurationOfInternalCalls = (timeIntervals: [number, number]): Promise<AxiosResponse> => {
  const config = {
    params: {
      date: timeIntervals[0],
      dateRange: timeIntervals[1],
    },
  }

  return axios.get('dashboard/get-duration-of-internal-calls-by-period', config);
}

const getNewClientsStatistics = (config: {
  date: null | number | number[],
}): Promise<AxiosResponse> => {
  const { date } = config;
  const Statistics = new FormData();

  if (Array.isArray(date)) {
    Statistics.set('date', JSON.stringify([date[0], date[1]]))
  }
  else {
    date && Statistics.set('date', String(date));
  }

  return axios.post('dashboard/get-contacts-count', Statistics)
};

const getUserWidgets = (): Promise<AxiosResponse> => {
  const Widgets = new FormData();

  return axios.post('user/get-widgets-for-current-user', Widgets);
};

const getContacts = (
  filters: IContactsConfig,
  offset?: number,
  limit?: number,
): Promise<AxiosResponse> => {
  const {
    filterByTagIds,
    filterByType,
    filterDate,
    filterSortingOrder,
    filterSortingType,
    searchQuery,
  } = filters;

  const config: { params: { [key: string]: string | number | number[] | null } } = {
    params: {
      limit: limit ? limit : LIMIT,
      offset: offset || '',
      tagIds: filterByTagIds ? JSON.stringify(filterByTagIds) : null,
      type: filterByType || null,
      query: searchQuery || null,
      sortBy: (!!filterSortingOrder && !!filterSortingType)
        ? filterSortingOrder + '=' + filterSortingType
        : null
    }
  }

  if (Array.isArray(filterDate)) {
    config.params.date = (filterDate[0]);
    config.params.dateRange = (filterDate[1]);
  } else if (filterDate) {
    config.params.date = (filterDate);
  }

  return axios.get('contacts', config);
};

// FIXME: remove this function with serverSide fix
// FIXME: photo does not work
const formatDefaultContactFields = (fieldsArray: any[], fieldName: string) => {
  if (!fieldsArray || !fieldsArray.length) {
    return;
  }
  if (fieldsArray.length === 1 && !fieldsArray[0]) {
    return;
  }

  const fieldsData = fieldsArray.map((item) => {
    if (item.id) {
      return item;
    }

    // const name = Object.keys(item)[0];
    // const field = Object.values(item)[0];

    return {
      default: item.default,
      label: fieldName,
      [fieldName]: item[fieldName]
    };
  });

  return JSON.stringify(fieldsData);
};

const saveContact = async (
  contact: IContact,
  action: 'create' | 'update',
  initialType?: number,
) => {
  let Contact = new FormData();

  // const tels = formatDefaultContactFields(contact.tels, "tel");
  const emails = formatDefaultContactFields(contact.emails, "email");

  Contact.set("fn", contact.fn || "");
  contact.tels && Contact.set("tels", JSON.stringify(contact.tels));
  emails && Contact.set("emails", emails);
  contact.type && Contact.set("type", String(contact.type));
  Contact.set("note", contact.note || "");
  Contact.set("urls", JSON.stringify(contact.urls));
  Contact.set("adr", contact.adr || "");
  Contact.set("not_for", contact.not_for || "");
  Contact.set("ex", contact.is_ex !== undefined ? String(contact.is_ex) : "0");
  Contact.set("id", String(contact.id) || "");

  if (action === "update") {
    initialType && Contact.set("initialType", String(initialType));
    contact.isActive && Contact.set("isActive", String(contact.isActive) || "");
    contact.unreadCount && Contact.set("unreadCount", String(contact.unreadCount) || "");
    contact.callerTags && Contact.set("callerTags", JSON.stringify(contact.callerTags));
  }
  if (contact.type === 2) {
    contact.agent && Contact.set("agentId", String(contact.agent.id)); // here comes agent object
  }

  console.log(contact.image);

  // if (contact.image) {
  //   await new Promise(
  //     (res, rej) =>
  //       new Compressor(contact.image, {
  //         quality: 0.6,
  //         success(result) {
  //           console.log("result", result);
  //           image = result;
  //           res(result);
  //         },
  //         error(err) {
  //           console.log("error image", err);
  //         }
  //       })
  //   );
  // }

  contact.image && Contact.set("file", JSON.stringify(contact.image));

  return action === "update"
    ? axios.post("contacts/update", Contact)
    : axios.post("contacts/create", Contact);
};

const deleteContact = (contactId: number): Promise<AxiosResponse> => {
  return axios.get('contacts/delete?id=' + contactId);
};

const getTopTags = () => {
  return axios.post('tags/get-top-tags')
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);

      return [];
    });
};

const searchTags = (query: string): Promise<AxiosResponse> => {
  return axios.get(`tags/search?query=${query}`)
    .then(({ data }) => {
      if (data.length) {
        return data
      }

      return []
    })
    .catch(console.log);
};

const getPhoneLabels = (): Promise<AxiosResponse> =>
  axios.get('callers/get-contacts-labels');

const getClientNotFor = (caller_id: number): Promise<AxiosResponse> =>
  axios.get('caller-not-for/view', { params: { caller_id } });

const addClientNotFor = (contact_id: number, contact_ignore_id: number): Promise<AxiosResponse> => {
  const NotForData = new FormData();

  NotForData.set('caller_id', String(contact_id));
  NotForData.set('caller_ignore_id', String(contact_ignore_id));

  return axios.post('caller-not-for/create', NotForData);
};

const removeClientNotFor = (contact_id: number, contact_ignore_id: number): Promise<AxiosResponse> => {
  const RemoveData = new FormData();

  RemoveData.set('caller_id', String(contact_id));
  RemoveData.set('caller_ignore_id', String(contact_ignore_id));

  return axios.post('caller-not-for/delete', RemoveData);
};
const getSessions = (
  filters: ISessionsConfig,
  offset?: number,
): Promise<AxiosResponse> => {
  const {
    filterContactId,
    filterOperatorId,
    filterDate,
    filterActivity,
    filterStatus = null
  } = filters;

  const config: { params: { [key in string]: string | number | null } } = {
    params: {
      limit: LIMIT,
      offset: offset || 0,
      active: filterActivity || null,
      callerId: filterContactId || null,
      userId: filterOperatorId || null,
      status: filterStatus || null,
    }
  }

  if (Array.isArray(filterDate)) {
    config.params.date = (filterDate[0]);
    config.params.dateRange = (filterDate[1]);
  } else if (filterDate) {
    config.params.date = (filterDate);
  }

  return axios.get("sales-session", config);
};

const deleteSession = (sessionId: number): Promise<AxiosResponse> => {
  const Session = new FormData();

  Session.set('id', String(sessionId));

  return axios.post('sales-session/delete', Session);
}

const getSessionLog = (bookingId: number) => {
  const SessionLog = new FormData();

  SessionLog.set('bookingId', String(bookingId));

  return axios.post('sales-session/get-booking-log', SessionLog);
}

const getNightMode = (): Promise<AxiosResponse> => axios.get('settings/get-night-mode');

const setNightMode = (mode: number): Promise<AxiosResponse> => {

  const Mode = new FormData();

  Mode.set('nightMode', String(mode));

  return axios.post('settings/set-night-mode', Mode);
}

const getTagColors = (
  params: {
    offset?: number;
    color?: string;
    title?: string;
    caller_type?: string | number;
  } = {}
): Promise<AxiosResponse> => {
  const config = {
    params: {
      limit: LIMIT,
      offset: params?.offset,
      color: params?.color,
      title: params?.title,
      caller_type: params?.caller_type,
    },
  };

  return axios.get('colors', config);
};

const createTagColor = (params: { 
  color: string; 
  caller_type: string | number;
  title?: string;
}): Promise<AxiosResponse> => {
  const { color, title, caller_type } = params;

  const Color = new FormData();

  Color.set('color', color);
  Color.set('caller_type', String(caller_type));
  title && Color.set('title', title);

  return axios.post('colors/create', Color);
}

const updateTagColor = (params: {
  id: number;
  color: string; 
  caller_type: string | number;
  title?: string;
}): Promise<AxiosResponse> => {
  const { id, color, title, caller_type } = params;

  const Color = new FormData();

  Color.set('id', String(id));
  Color.set('color', color);
  Color.set('caller_type', String(caller_type));
  title && Color.set('title', title);

  return axios.post('colors/update', Color);
}

const deleteTagColor = (id: number) => {
  const ColorId = new FormData();

  ColorId.set('id', String(id));

  return axios.post('colors/delete', ColorId);
}

export default {
  getSenderGsm,
  getTimeGap,
  setTimeGap,
  deleteRingtones,
  getRingtones,
  setContactRingtones,
  getContactRingtones,
  getRingtonesByType,
  setSenderGsm,
  // updateGroupRingtones,
  // updateSystemRingtone,
  searchContacts,
  loginUser,
  logoutUser,
  searchShortcuts,
  deleteShortcuts,
  addNewShortcut,
  editShortcut,
  getAllOperators,
  createOperator,
  editOperator,
  deleteOperator,
  getShortcuts,
  getIsIAmLoggedIn,
  getOperatorsLogs,
  deleteOperatorLogs,
  getOperatrLogActions,
  getCallsRecords,
  updateCallRecordMsg,
  deleteCallRecord,
  getCronJobs,
  editCronJob,
  startCronJob,
  stopCronJob,
  getCronJobLogs,
  getSystemRoles,
  getNightMode,
  getOperatorSystemRoles,
  editOperatorSystemRoles,
  createSystemRole,
  editSystemRole,
  deleteSystemRole,
  getPermissions,
  getPermissionsByGroup,
  getClients,
  searchClients,
  getVoicemails,
  deleteVoicemail,
  getStatistics,
  getSessionLog,
  getOperatorActivity,
  getOperatorInactivityTime,
  editOperatorInactivityTime,
  getInteractionsCount,
  setUnreadMsgTime,
  getUnreadMsgTime,
  getGlossaryMessages,
  getWebMasterTasks,
  searchGlossaryMsg,
  deleteGlossaryMsg,
  updateGlossaryMsg,
  searchWebMasterTasks,
  getPhoneNumbers,
  removePhoneNumber,
  submitEditPhoneNumber,
  createPhoneNumber,
  getCountries,
  getMsgTemplates,
  createMsgTemplate,
  updateMsgTemplate,
  deleteMsgTemplate,
  searchMsgTemplates,
  getShortcutCategories,
  getAllInteractions,
  getPublicApps,
  createPublicApp,
  updatePublicApp,
  deletePublicApp,
  getBookings,
  getDivaGirls,
  getRatingItems,
  getCallRedirect,
  updateCallRedirect,
  getAvailableNowDuration,
  setAvailableNowDuration,
  getAllOperatorsForWidget,
  getLastCalls,
  getMissedCalls,
  updateWidgetLayouts,
  updateActiveWidgets,
  getCallsDurationByPeriod,
  getIncomingCallsWaitingTime,
  getIncomingCallsCountByInterval,
  getDurationOfInternalCalls,
  getNewClientsStatistics,
  getUserWidgets,
  getContacts,
  saveContact,
  deleteContact,
  getTopTags,
  searchTags,
  getPhoneLabels,
  getClientNotFor,
  addClientNotFor,
  removeClientNotFor,
  getSessions,
  deleteSession,
  getOperatorProductivity,
  getBookingsAndProfitForChart,
  setNightMode,
  changeRingtone,
  getAvailableRingtones,
  getRingtonesDefault,
  createCourier,
  deleteCourier,
  getAllCouriers,
  changeCourierStatus,
  getBalance,
  getTagColors,
  createTagColor,
  updateTagColor,
  deleteTagColor,
};
