import React from 'react';

interface ISvgIconProps {
  fill?: string;
  width?: string | number;
  height?: string | number;
  className?: string;
  icon?: string;
  urlPath?: string;
}

// Put svgSprite to public/media folder
const url = '/media/sprite-svg.svg';

const SvgIcon = (props: ISvgIconProps) => (
  <svg
    viewBox='0 0 16 16'
    fill={props.fill}
    width={props.width}
    height={props.height}
    className={props.className}
  >
    <use xlinkHref={`${props.urlPath ? props.urlPath : url}#${props.icon}`} />
  </svg>
);

export default SvgIcon;