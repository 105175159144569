import getDateByTimezoneOffset from './getDateByTimezoneOffset'
import { TimeInterval, SUM, DAY, WEEK, MONTH } from '../components/TimeIntervalsSwitcher/TimeIntervalsSwitcher'

type TResultData = {
  [key: string]: {
    [key: string]: number[],
  }
}

function getDataByTimeIntervalForStackedBar(
  userTimezone: number,
  dataInterval: TimeInterval,
  dates: number[],
  dataset: TResultData
): { datesByInterval: number[], dataByInterval: TResultData } {

  let resultDates: number[] = [];
  let resultData: TResultData = {};

  const datasetKeys: string[] = Object.keys(dataset);

  if (dataInterval === SUM) {
    resultDates = [dates[0]]

    datasetKeys.forEach((datasetKey) => {
      const datasetKeysResult: { [key: string]: number[] } = {};

      const datasetProp = dataset[datasetKey];
      const dataKeys = Object.keys(datasetProp)

      dataKeys.forEach((key: string) => {
        let sumData: number = 0;

        datasetProp[key].forEach((value: number) => {
          sumData += value;
        })

        datasetKeysResult[key] = [sumData];
      })

      resultData[datasetKey] = datasetKeysResult;
    })
  }

  else if (dataInterval === DAY) {
    resultDates = [...dates];
    resultData = JSON.parse(JSON.stringify(dataset));
  }

  else if (dataInterval === WEEK) {
    dates.forEach((date, idx) => {
      const timezoneDate = getDateByTimezoneOffset(userTimezone, date);

      if (timezoneDate.getDay() === 1 || idx === 0) {
        resultDates.push(date);
      }
    })

    datasetKeys.forEach((datasetKey) => {
      const allWeeksData: { [key: string]: number[] } = {};
      let oneWeekData: number = 0;

      const datasetProp = dataset[datasetKey];
      const dataKeys = Object.keys(datasetProp);

      dataKeys.forEach(dataKey => {
        const dataKeyAllWeeksData: number[] = [];

        datasetProp[dataKey].forEach((value: number, idx: number) => {
          const timezoneDate = getDateByTimezoneOffset(userTimezone, dates[idx]);

          oneWeekData += value;

          if (timezoneDate.getDay() === 0 || idx === (datasetProp[dataKey].length - 1)) {
            dataKeyAllWeeksData.push(oneWeekData);
            oneWeekData = 0;
          }
        })

        allWeeksData[dataKey] = dataKeyAllWeeksData;
      })

      resultData[datasetKey] = allWeeksData;
    })
  }

  else if (dataInterval === MONTH) {
    dates.forEach((date, idx) => {
      const timezoneDate = getDateByTimezoneOffset(userTimezone, date);

      if (timezoneDate.getDate() === 1 || idx === 0) {
        resultDates.push(date);
      }
    })

    datasetKeys.forEach((datasetKey) => {
      let allMonthsData: { [key: string]: number[] } = {};
      let oneMonthData: number = 0;

      const datasetProp = dataset[datasetKey];
      const dataKeys = Object.keys(datasetProp);

      dataKeys.forEach(dataKey => {
        const dataKeyAllMonthData: number[] = [];

        datasetProp[dataKey].forEach((value: number, idx: number) => {
          const timezoneDate = getDateByTimezoneOffset(userTimezone, dates[idx]);
          const lastDayOfMonth = new Date(timezoneDate.getFullYear(), timezoneDate.getMonth() + 1, 0);

          oneMonthData += value;

          if (+timezoneDate === +lastDayOfMonth || idx === (datasetProp[dataKey].length - 1)) {
            dataKeyAllMonthData.push(oneMonthData);
            oneMonthData = 0;
          }
        });

        allMonthsData[dataKey] = dataKeyAllMonthData;
      })

      resultData[datasetKey] = allMonthsData;
    })
  }

  return {
    datesByInterval: resultDates,
    dataByInterval: resultData
  }
}

export default getDataByTimeIntervalForStackedBar;