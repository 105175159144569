import { createSelector } from 'reselect';

import { AppState } from "../store";
import {
  IOperator,
  IOperatorData,
} from '../../interfaces/interfaces';


export const selectPrettyOperatorsCount = createSelector(
  (state: AppState) => state.operators.entities,
  (state: AppState) => state.operators.ids,

  (operators, operatorsIds) => {
    let online: number = 0;
    let away: number = 0;
    let busy: number = 0;

    operatorsIds.forEach((id: number) => {
      const currentOperator: IOperator = operators[id];

      switch (currentOperator.status) {
        case 'online':
          online++;
          break;
        case 'away':
          away++;
          break;
        case 'busy':
          busy++;
          break;
      }
    });

    return {
      total: operatorsIds.length,
      online,
      away,
      busy,
    }
  }
);

export const selectOperatorsNamesById = createSelector(
  (state: AppState) => state.operators.entities,
  (state: AppState) => state.operators.ids,

  (operators, operatorsIds) => {
    const operatorsNames: IOperatorData[] = [] as IOperatorData[];

    operatorsIds.forEach((id: number) => operatorsNames.push({
      id,
      username: operators[id].username,
    }));

    return operatorsNames;
  }
);

export const selectUserTimezone = (state: AppState) => {
  if (state.user.user.timezone && (state.user.user.timezone.offset_minutes || state.user.user.timezone.offset_minutes === 0)) {
    return state.user.user.timezone.offset_minutes;
  }
  return new Date().getTimezoneOffset() * -1;
}