import React from 'react';
import { Route, RouteProps } from 'react-router-dom';


interface IPrivateRouteProps extends RouteProps {
  isAuthenticated: boolean;
  redirectLink: string;
}

const Redirect = ({ redirectLink }: { redirectLink: string }) => {
  console.log(333333333333);
  window.location.href = redirectLink;

  return null;
}


const PrivateRoute = ({ component: Component, ...props }: IPrivateRouteProps) => {
  if (!Component) return null;

  return <Route {...props} render={routeProps => (
    props.isAuthenticated
      ? <Component {...routeProps} />
      : <Redirect redirectLink={props.redirectLink} />
  )}
  />
}

export default PrivateRoute;