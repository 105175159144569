import React from 'react';

import { PERMISSIONS } from '../../../config/constants';

import Loadable from './Loadable';
import CheckPerms from '../../../components/CheckPerms/CheckPerms';

const RingtonesSettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getRingtones}>
      <Loadable.RingtonesSettings />
    </CheckPerms>
  )
}

const OperatorsSettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getAllOperators}>
      <Loadable.OperatorsSettings />
    </CheckPerms>
  )
}
const CouriersSettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getAllCouriers}>
      <Loadable.CouriersSettings />
    </CheckPerms>
  )
}
const WebMasterSettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getAllTasksForAdmin}>
      <Loadable.WebMasterSettings />
    </CheckPerms>
  )
}

const GlossarySettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getGlossaries}>
      <Loadable.GlossarySettings />
    </CheckPerms>
  )
}
const ShortcutsSettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getShortcuts}>
      <Loadable.ShortcutsSettings />
    </CheckPerms>
  )
}
const VoicemailsSettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getVoicemails}>
      <Loadable.VoicemailsSettings />
    </CheckPerms>
  )
}

const CallsRecordsSettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getCallsRecords}>
      <Loadable.CallsRecordsSettings />
    </CheckPerms>
  )
}

const OperatorsLogsSettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getOperatorsLogs}>
      <Loadable.OperatorsLogsSettings />
    </CheckPerms>
  )
}

const ClientsRating = () => {
  return (
    <CheckPerms permission={PERMISSIONS.clientsRating}>
      <Loadable.ClientsRating />
    </CheckPerms>
  )
}
const GirlsRating = () => {
  return (
    <CheckPerms permission={PERMISSIONS.girlsRating}>
      <Loadable.GirlsRating />
    </CheckPerms>
  )
}
const OperatorsRating = () => {
  return (
    <CheckPerms permission={PERMISSIONS.operatorsRating}>
      <Loadable.OperatorsRating />
    </CheckPerms>
  )
}

const CronJobsSettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getAllCronjobs}>
      <Loadable.CronJobsSettings />
    </CheckPerms>
  )
}
const SystemRolesSettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getAllSystemRoles}>
      <Loadable.SystemRolesSettings />
    </CheckPerms>
  )
}
const MsgTemplatesSettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getMsgTemplates}>
      <Loadable.MsgTemplatesSettings />
    </CheckPerms>
  )
}

const PhoneNumbersSettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getPhoneNumbers}>
      <Loadable.PhoneNumbersSettings />
    </CheckPerms>
  )
}

const CallsLogSettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getAllClientInteractions}>
      <Loadable.CallsLogSettings />
    </CheckPerms>
  )
}

const SmsLogsSettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getAllClientInteractions}>
      <Loadable.SmsLogsSettings />
    </CheckPerms>
  )
}

const PublicApiSettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getAllPublicApps}>
      <Loadable.PublicApiSettings />
    </CheckPerms>
  )
}

const BookingsSettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getBookings}>
      <Loadable.BookingsSettings />
    </CheckPerms>
  )
}

const StatisticSettings = () => {
  return (
    <CheckPerms permission={[PERMISSIONS.getOperatorsActivity, PERMISSIONS.getOperatorsProductivity]}>
      <Loadable.StatisticSettings />
    </CheckPerms>
  )
}

const Settings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getCallRedirectTime}>
      <Loadable.Settings />
    </CheckPerms>
  )
}

const ContactsSettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getContacts}>
      <Loadable.ContactsSettings />
    </CheckPerms>
  )
}
const SessionsSettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getSessions}>
      <Loadable.SessionsSettings />
    </CheckPerms>
  )
}
const TagColorsSettings = () => {
  return (
    <CheckPerms permission={PERMISSIONS.getTagColors}> 
      <Loadable.TagColorsSettings />
    </CheckPerms>
  )
}

export default {
  RingtonesSettings,
  OperatorsSettings,
  CouriersSettings,
  WebMasterSettings,
  GlossarySettings,
  ShortcutsSettings,
  VoicemailsSettings,
  CallsRecordsSettings,
  OperatorsLogsSettings,
  ClientsRating,
  GirlsRating,
  OperatorsRating,
  CronJobsSettings,
  SystemRolesSettings,
  MsgTemplatesSettings,
  PhoneNumbersSettings,
  CallsLogSettings,
  SmsLogsSettings,
  PublicApiSettings,
  StatisticSettings,
  BookingsSettings,
  Settings,
  ContactsSettings,
  SessionsSettings,
  TagColorsSettings,
}