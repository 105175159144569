import React from "react";
import { FieldRenderProps } from "react-final-form";


interface ITextInputProps {
  className?: string;
  autoFocus?: boolean;
  getErrors?: any;
  placeholder?: string;
  errorClass?: string;
  errorInputClass?: string;
  input: FieldRenderProps<string, HTMLInputElement>['input'];
  meta: FieldRenderProps<string | number, HTMLInputElement>['meta'];
}


const TextInput = (props: ITextInputProps) => {
  const {
    input,
    autoFocus,
    className,
    placeholder,
    getErrors,
    meta: { error, touched, active }
  } = props;

  const errorInputClass = (error && touched && !active)
    ? props.errorInputClass
    : '';

  return (
    <>
      <input
        {...input}
        type="text"
        placeholder={placeholder}
        className={`${className} ${errorInputClass}`}
        name={input.name}
        value={input.value}
        autoComplete="off"
        autoFocus={autoFocus}
      />

      {/* Client Error */}
      {error && touched && !active && <div className={props.errorClass}>{error}</div>}
      {/* Server Error */}
      {getErrors
        && getErrors[input.name]
        && !error
        && <div className={props.errorClass}>{getErrors[input.name]}</div>
      }
    </>
  )
}

export default TextInput;