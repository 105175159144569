let socketHost;

const hostname = window && window.location && window.location.hostname;

if (hostname === 'localhost') {
    socketHost = 'wss://comdiva.local:8000';
}
else if (hostname === 'admin.comdiva.local') {
    socketHost = 'wss://comdiva.local:8000';
}
else if (hostname === 'admin.commdiva.abcwdl.com') {
    socketHost = 'wss://socket.commdiva.abcwdl.com:8000';
}
else if (hostname === 'admin.connect.divaes.co') {
    socketHost = `wss://socket.connect.divaes.co:8000`;
}
else {
    socketHost = 'ws://127.0.0.1:8000';
}

export const SOCKET_ROOT = `${socketHost}`;