import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { connect } from 'react-redux';

import API from '../../../api/api';
import { classModifier } from '../../../utils';
import { useToggle } from '../../../hooks';

import { IIncomingCallsWaitingWidgetProps } from '../Welcome.types';
import { AppState } from '../../../redux/store';

import Spinner from '../../../components/Spinner';
import { selectUserTimezone } from '../../../redux/selectors/selectors';


const chartOptions = {
  maintainAspectRatio: false,
  legend: {
    position: 'bottom',
    display: true,
    labels: {
      fontColor: '#404040',
    }
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
};

class ChartData {
  labels: string[];
  datasets: any;

  constructor(labelNames: string[], labelValues: number[]) {
    this.labels = labelNames;
    this.datasets = [{
      data: labelValues,
      backgroundColor: ['#fbbc06', '#ff3366'],
    }];
  }
}


const IncomingCallsWidget = (props: IIncomingCallsWaitingWidgetProps) => {
  const {
    userTimezone,
  } = props;

  const [dateFilter, setDateFilter] = useState<'day' | 'week' | 'month'>('day');
  const [chartData, setChartData] = useState<ChartData>({ labels: [], datasets: [] });

  const [isPending, togglePending] = useToggle(false);

  useEffect(() => {
    togglePending();

    API.getIncomingCallsCountByInterval(props.getDatesForInterval(dateFilter, userTimezone))
      .then((res) => {
        if (!res.data) return;

        const { received, missed } = res.data;

        setChartData(new ChartData(['received', 'missed'], [received, missed]));
      })
      .finally(() => togglePending());
  }, [dateFilter, userTimezone]);

  const getIsDataEmpty = () => {
    if (!chartData.datasets[0]) {
      return true;
    }
    const [received, missed] = chartData.datasets[0].data;

    return !received && !missed;
  }

  return (
    <div className="welcome__item card">
      <h2 className="welcome__item-title">
        Incoming Calls
      </h2>

      <div className="incoming-calls-widget welcome__not-draggable">
        {isPending &&
          <div className="welcome__spinner-wrap">
            <Spinner />
          </div>
        }

        <div className="switch incoming-calls-widget__switch">
          <button
            className={classModifier('switch__btn', [dateFilter === 'day' && 'active'])}
            onClick={() => setDateFilter('day')}
          >
            Today
          </button>

          <button
            className={classModifier('switch__btn', [dateFilter === 'week' && 'active'])}
            onClick={() => setDateFilter('week')}
          >
            Week
          </button>

          <button
            className={classModifier('switch__btn', [dateFilter === 'month' && 'active'])}
            onClick={() => setDateFilter('month')}
          >
            Month
          </button>
        </div>

        <div className="incoming-calls-widget__chart">
          {!!getIsDataEmpty() && !isPending &&
            <span className='pending incoming-calls-widget__empty'>
              No data for this period
            </span>
          }

          <Doughnut
            data={chartData}
            options={chartOptions}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  userTimezone: selectUserTimezone(state),
});

export default connect(mapStateToProps)(IncomingCallsWidget);