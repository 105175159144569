// get date by timezone offset
// or get date by utcDate with localTimezoneOffset
export default (timezoneOffset: number = 0, utcDate?: string | number): Date => { // timezoneOffset in minutes
  // localTimezoneOffset convert to hours
  const localTimezoneOffset: number = new Date().getTimezoneOffset() * -60000;

  let UTCDate: number = new Date().getTime() - localTimezoneOffset;

  if (utcDate) {
    // if date like "2020-04-08 09:57:05" its already UTC date
    if (typeof utcDate === 'string') {
      UTCDate = new Date(utcDate).getTime();
    }
    // if date in timestamp we need to calc with our localTimezoneOffset
    else {
      UTCDate = new Date(utcDate).getTime() - new Date(utcDate).getTimezoneOffset() * -60000;
    }
  }

  return new Date(UTCDate - timezoneOffset * -60000);
}