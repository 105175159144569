import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay,
  faPhoneAlt,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faPhoneSlash
} from '@fortawesome/free-solid-svg-icons';

import { getFullDateAndTime, getDateByTimezoneOffset } from '../../../../utils';

import {
  ILastCallsWidgetItemProps,
  ILastCallsWidgetItemConnectProps,
} from '../../Welcome.types';
import { AppState } from '../../../../redux/store';
import { IContactInteractionEnum } from '../../../../interfaces/interfaces';

import Player from '../../../../components/Player/Player';
import { selectUserTimezone } from '../../../../redux/selectors/selectors';


const LastCallsWidgetItem = (props: ILastCallsWidgetItemProps) => {
  const {
    item: call,
    operator,
    userTimezone,
    userHour12,
  } = props;

  const [isOpenCall, setOpenCall] = useState<undefined | boolean>();
  const [animation, setAnimation] = useState({
    className: '',
    styles: {},
    isProcessGoes: false,
  });

  useEffect(() => {
    const isNotInit = isOpenCall !== undefined;

    if (isOpenCall && isNotInit) {
      setAnimation({
        className: 'last-calls-widget__table-item-wrap--open',
        styles: {},
        isProcessGoes: true,
      });

      setTimeout(() => setAnimation({
        className: '',
        styles: {
          height: '121px',
        },
        isProcessGoes: false,
      }), 500);
    }
    else if (!isOpenCall && isNotInit) {
      setAnimation({
        className: 'last-calls-widget__table-item-wrap--close',
        styles: {},
        isProcessGoes: true,
      });

      setTimeout(() => setAnimation({
        className: '',
        styles: {
          height: '61px',
        },
        isProcessGoes: false,
      }), 500);
    }
  }, [isOpenCall]);

  const openContactCard = () => {
    const { caller } = call;

    if (!caller) return;

    props.openContactCard({
      isOpen: true,
      contact: caller,
    });
  }

  const getCallIcon = () => {
    if (call.status === 'no-answer') {
      return <FontAwesomeIcon icon={faPhoneSlash} className="last-calls-widget__missed-icon" />;
    }
    else {
      return (
        <>
          <FontAwesomeIcon
            icon={faPhoneAlt}
            className="last-calls-widget__phone-icon"
          />
          <FontAwesomeIcon
            icon={call.type === 1 ? faLongArrowAltLeft : faLongArrowAltRight}
            className="last-calls-widget__arrow-icon"
          />
        </>
      )
    }
  }

  return (
    <div
      className={'last-calls-widget__table-item-wrap ' + animation.className}
      style={animation.styles}
    >
      <div className='table__item'>
        <div className="table__item-block last-calls-widget__table-block-icon">
          {getCallIcon()}
        </div>

        <div className="table__item-block last-calls-widget__table-block-client">
          <span
            className='last-calls-widget__client-name'
            onClick={openContactCard}
          >
            {call.caller ? call.caller.fn : 'none'}
          </span>
        </div>

        <div className="table__item-block last-calls-widget__table-block-operator">
          {operator && operator.username}
        </div>

        <div className="table__item-block last-calls-widget__table-block-date">
          {getFullDateAndTime(getDateByTimezoneOffset(userTimezone, call.dateCreated), userHour12)}
        </div>

        <div className="table__item-block last-calls-widget__table-block-status">
          {call.status}
        </div>

        <div className="table__item-block last-calls-widget__table-block-direction">
          {IContactInteractionEnum[call.type]}
        </div>

        <div className="table__item-block last-calls-widget__table-block-duration">
          {call.duration}
        </div>

        <div className="table__item-block last-calls-widget__table-block-actions">
          {!!call.callRecordsUrl &&
            <button
              className="btn btn--primary last-calls-widget__open-player"
              onClick={animation.isProcessGoes ? () => null : () => setOpenCall(!isOpenCall)}
            >
              <FontAwesomeIcon icon={faPlay} />
            </button>
          }
        </div>
      </div>

      {!!call.callRecordsUrl &&
        <div className="last-calls-widget__call-wrap">
          <div className="last-calls-widget__call">
            <Player
              src={call.callRecordsUrl}
              onPlay={props.playAudio}
            />
          </div>
        </div>
      }
    </div>
  );
};

const mapStateToProps = (state: AppState, ownProps: ILastCallsWidgetItemConnectProps) => ({
  operator: state.operators.entities[ownProps.item.user_id],
  userTimezone: selectUserTimezone(state),
  userHour12: state.user.user.isHour12,
});

export default connect(mapStateToProps)(LastCallsWidgetItem);