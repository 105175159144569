import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { selectPrettyOperatorsCount } from '../../../redux/selectors/selectors';
import { classModifier } from '../../../utils';

import { AppState } from '../../../redux/store';
import { IOperatorsWidgetProps } from '../Welcome.types';

import Spinner from '../../../components/Spinner';


const OperatorsWidget = (props: IOperatorsWidgetProps) => {
  const {
    operatorsCount,
  } = props;

  const [isPending, setPending] = useState(true);

  useEffect(() => {
    if (!!operatorsCount.total) {
      setPending(false);
    }
  }, [operatorsCount])


  return (
    <div className="welcome__item card">
      <h2 className="welcome__item-title">
        Operators
      </h2>

      <div className="operators-widget welcome__not-draggable">
        {isPending
          ? (
            <div className="welcome__spinner-wrap">
              <Spinner />
            </div>
          )
          : (
            <div className="welcome__count-container">
              <div className='welcome__count-wrap'>
                <span>Total</span>
                <span className='welcome__count'>
                  {operatorsCount.total}
                </span>
              </div>

              <div className='welcome__count-wrap'>
                <span>Online</span>
                <span
                  className={classModifier('welcome__count', [
                    operatorsCount.online !== 0 && 'online'
                  ])}
                >
                  {operatorsCount.online}
                </span>
              </div>

              <div className='welcome__count-wrap'>
                <span>Away</span>
                <span
                  className={classModifier('welcome__count', [
                    operatorsCount.away !== 0 && 'away'
                  ])}
                >
                  {operatorsCount.away}
                </span>
              </div>

              <div className='welcome__count-wrap'>
                <span>Busy</span>
                <span
                  className={classModifier('welcome__count', [
                    operatorsCount.busy !== 0 && 'busy'
                  ])}
                >
                  {operatorsCount.busy}
                </span>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  operatorsCount: selectPrettyOperatorsCount(state),
});

export default connect(mapStateToProps)(OperatorsWidget);