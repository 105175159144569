import React, { Dispatch, SetStateAction } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';

import './Topbar.scss';
import API from '../../api/api';
import { connect } from 'react-redux';
import { COMDIVA_PAGE_ROOT } from '../../config/api-config';


// interface ITopbarProps {
//   logoutUser: () => void,
// };


const Topbar = (props: { setPagePending: Dispatch<SetStateAction<boolean>> }) => {
  const logout = () => {
    props.setPagePending(true);

    return API.logoutUser();
  }

  return (
    <header className="topbar">

      <a className="topbar__link--goto" href={COMDIVA_PAGE_ROOT}>
        <FontAwesomeIcon icon={faHeadset} />
        Go to Comdiva Page
      </a>


      <button
        onClick={logout}
        className="topbar__btn--logout">
        <svg
          fill="#727cf5"
          height={40}
          width={40}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 330 330">
          <path d="M51 180h174a15 15 0 000-30H51l20-19a15 15 0 10-22-22L4 154l-1 1v2H2v1l-1 1v2l-1 1a14 14 0 000 4v2l1 1v2l1 1v1h1v2l1 1 45 45a15 15 0 0022 0c5-6 5-16 0-22l-20-19z" />
          <path d="M207 42c-41 0-79 21-102 55a15 15 0 0025 16 93 93 0 110 104 15 15 0 00-24 17A123 123 0 10207 42z" />
        </svg>
      </button>
    </header>
  );
}

const mapDispatchToProps = {
  // logoutUser,
};

export default connect(null, mapDispatchToProps)(Topbar);