export default (cookie: string) => {
  const subdomains = ['connect', 'cronjobs', 'app', 'admin'];

  let domain = window.location.hostname;

  const host = window.location.hostname.split('.');

  domain = host.filter(value => !subdomains.includes(value)).join('.');
  
  return document.cookie = cookie + "=; Expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/; Domain=" + domain;
}