import getDateByTimezoneOffset from './getDateByTimezoneOffset';

export default (filterDate: number | number[] | null, userTimezone: number) => {
  if (!filterDate) return 'All Time';

  if (Array.isArray(filterDate)) {
    const startRangeDateByTimezone = getDateByTimezoneOffset(userTimezone, +new Date(filterDate[0]));
    const endRangeDateByTimezone = getDateByTimezoneOffset(userTimezone, +new Date(filterDate[1]));

    return new Date(startRangeDateByTimezone).toLocaleDateString() + ' - ' + new Date(endRangeDateByTimezone).toLocaleDateString();
  }
  else {
    return getDateByTimezoneOffset(userTimezone, +new Date(filterDate)).toLocaleDateString();
  }
}