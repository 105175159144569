import { IEntityPhoto } from "../interfaces/interfaces";


export default (photo: null | IEntityPhoto, size: 'default' | 'min' = 'default'): string => {
  if (!photo) {
    return '/media/avatars/defaultAvatar.svg';
  }
  else if (size === 'default') {
    return photo.src;
  }
  else if (size = 'min') {
    return photo.min;
  }

  return '/media/avatars/defaultAvatar.svg';
}