import getDateByTimezoneOffset from './getDateByTimezoneOffset'

export default (userTimezone: number, date: number | number[] | null) => {
  if (!date) return '';

  const convertDate = (date: number): string => getDateByTimezoneOffset(userTimezone, date).toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

  return Array.isArray(date)
    ? convertDate(date[0]) + ' - ' + convertDate(date[1])
    : convertDate(date);
}