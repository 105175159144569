import React, { useState, Fragment, useRef, useEffect } from "react";

import formatTime from "../../utils/formatTime";

import "./Player.scss";
import Spinner from "../Spinner";
import Slider from "./Slider";
import { classModifier } from "../../utils";
import { usePrevious } from "../../hooks";

interface IPlayerProps {
  src?: string,
  blob?: string | undefined,
  hideVolume?: boolean,
  blobDuration?: number,
  onPlay: (target: EventTarget) => void,
  pending?: boolean,
  props?: any
}


const Player = ({ src, blob, blobDuration, pending, ...props }: IPlayerProps) => {
  const [pause, setPause] = useState<boolean>(true);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [volume, setVolume] = useState<number>(100);
  const [duration, setDuration] = useState<number>(0);
  const [wasThumbMoved, setWasThumMoved] = useState<boolean>(false); // It's just toggle-flag for render child component. He equal true or false.
  //                                                       If it's equal undefined, it means that init happening now or slider is volume-slider
  const [muteVolume, setMuteVolume] = useState<any>(); // It's analogue of wasThumbMoved for volume-slider
  const [nowThereIsAManualTimeChange, setNowThereIsAManualTimeChange] = useState(false); // Time change may be happening automaticaly or with click/mousemove

  const audioRef: any = useRef({});

  const prevBlob = usePrevious(blob);

  useEffect(() => {
    audioRef.current.volume = volume / 100;
  }, [volume]);

  useEffect(() => {
    if (blob && blob !== prevBlob) {
      setPause(true);
    }
  }, [blob])

  const moveThumb = () => setWasThumMoved(!wasThumbMoved);

  const onInitAudio = () => {
    if (audioRef.current.duration === Infinity && blobDuration) { // Chrome Bug
      return setDuration(Math.ceil(blobDuration / 1000)); //        https://bugs.chromium.org/p/chromium/issues/detail?id=642012
    }

    setDuration(Math.ceil(audioRef.current.duration));
  };

  const onTimeChange = () => {
    const currentAudioTime = Math.ceil(audioRef.current.currentTime);

    if (currentAudioTime !== currentTime) {
      setCurrentTime(currentAudioTime);

      if (nowThereIsAManualTimeChange === true) {
        return setNowThereIsAManualTimeChange(false);
      }

      moveThumb();
    }
  };

  const onTimeChangeManually = (newTime: number) => {
    const newTimeFloor = Math.floor(newTime);

    if (newTimeFloor !== currentTime) {
      audioRef.current.currentTime = newTimeFloor;
      setNowThereIsAManualTimeChange(true);
    }
  };

  const onPlay = ({ target }: React.SyntheticEvent<HTMLAudioElement, Event>) => {
    if (props.onPlay) {
      props.onPlay(target);
    }
  };

  const onEndAudio = () => {
    setCurrentTime(0);
    moveThumb();
  };

  const toggleMute = () => {
    setVolume(volume ? 0 : 100);
    setMuteVolume(!muteVolume);
  };

  const togglePause = () => {
    audioRef.current[pause ? 'play' : 'pause']();

    setPause(!pause);
  };

  if (pending) {
    return <div className="player">
      <div className='player__spinner'>
        <Spinner size='15px' />
      </div>
    </div>
  }

  return (
    <Fragment>
      <div className="player">
        {duration === 0 &&
          <div className='player__spinner'>
            <Spinner size='15px' />
          </div>
        }

        {duration > 0 &&
          <Fragment>
            <div
              className={classModifier('player__control', pause ? 'play' : 'pause')}
              onClick={togglePause}
            ></div>

            <div className="player__time">
              {formatTime(currentTime * 1000)}
            </div>

            <Slider
              onUpdate={onTimeChangeManually}
              countOfSteps={duration}
              currentStep={currentTime}
              wasThumbMoved={wasThumbMoved}
            />

            <div className="player__time">
              {formatTime(duration * 1000)}
            </div>

            {!props.hideVolume &&
              <div className="player__control player__control--volume">
                {volume > 0
                  ? <svg
                    height="100%"
                    fill="#808080"
                    viewBox="0 0 459 459"
                    onClick={toggleMute}
                  >
                    <path d="M0,153v153h102l127.5,127.5v-408L102,153H0z M344.25,229.5c0-45.9-25.5-84.15-63.75-102v204    C318.75,313.65,344.25,275.4,344.25,229.5z M280.5,5.1v53.55C354.45,81.6,408,147.899,408,229.5S354.45,377.4,280.5,400.35V453.9    C382.5,430.949,459,339.15,459,229.5C459,119.85,382.5,28.049,280.5,5.1z" />
                  </svg>
                  : <svg
                    height="100%"
                    fill="#808080"
                    viewBox="0 0 461.55 461.55"
                    onClick={toggleMute}
                  >
                    <path d="M345.525,229.5c0-45.9-25.5-84.15-63.75-102v56.1l63.75,63.75C345.525,239.7,345.525,234.6,345.525,229.5z M409.275,229.5    c0,22.95-5.1,45.9-12.75,66.3l38.25,38.25c17.85-30.6,25.5-68.85,25.5-107.1c0-109.65-76.5-201.45-178.5-224.4V56.1    C355.725,81.6,409.275,147.9,409.275,229.5z M34.425,0L1.275,33.15L121.125,153H1.275v153h102l127.5,127.5V262.65L340.425,372.3    c-17.851,12.75-35.7,22.95-58.65,30.601v53.55c35.7-7.65,66.3-22.95,94.35-45.9l51,51l33.15-33.149l-229.5-229.5L34.425,0z     M230.775,25.5l-53.55,53.55l53.55,53.55V25.5z" />
                  </svg>
                }
                <Slider
                  onUpdate={setVolume}
                  countOfSteps={100}
                  currentStep={volume}
                  mute={muteVolume}
                />
              </div>
            }
          </Fragment>
        }
      </div>

      <audio
        controls
        ref={audioRef}
        onLoadedMetadata={onInitAudio}
        onTimeUpdate={onTimeChange}
        onEnded={onEndAudio}
        className='player__audio-source'
        // src={blob || src}
        src={blob}
        onPlay={onPlay}
        onPause={() => setPause(true)}
      >
        {!blob && <source src={src} />}
      </audio>
    </Fragment>
  );
};

export default Player;
