import { IContact } from "../interfaces/interfaces";

const avatarsPath = '/media/avatars/';
/**
 * @param { {photo?: string, chatType?: string} } callerOrRoom
 * @returns {string}
 */

const getAvatar = ( contact: IContact, isFullSizeImg?: boolean ) => {
  if (contact.photo) {
    return isFullSizeImg
      ? contact.photo.src
      : contact.photo.min
  }
  else if (contact.chatType && contact.chatType !== 'private') {
    return avatarsPath + contact.chatType + 'Avatar.png';
  }
  else {
    return avatarsPath + 'defaultAvatar.svg';
  }
};

export default getAvatar;