import React, { useState, useRef, useEffect } from "react";

interface ISliderProps {
  countOfSteps: number,
  maxCountOfSteps?: number,
  onUpdate: (num: number) => void,
  wasThumbMoved?: boolean,
  currentStep: number,
  mute?: boolean,
}


const Slider = ({ maxCountOfSteps = 100, ...props }: ISliderProps) => { // maxCountOfSteps it's count of steps which slider can go.
  //                                                         This param does not need to pass 
  //                                                         Can't pass countOfSteps more that it
  const {
    countOfSteps,
    onUpdate,
    wasThumbMoved,
    mute
  } = props;

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [countInStep, setCountInStep] = useState<number>(0); // how much steps in step (in proportion). Example: 2 step of 5 It's 20/100

  const sliderRef: any = useRef({});
  const thumbRef: any = useRef({});

  useEffect(() => { // Init
    const maxCountOfSteps: number = 100;

    setCurrentStep(props.currentStep);
    setCountInStep(maxCountOfSteps / countOfSteps);
  }, [countOfSteps]);

  useEffect(() => { // Main slider
    if (wasThumbMoved !== undefined) {
      let newCurrentStep = currentStep;

      if (currentStep / countInStep !== props.currentStep - 1) { // not normal. seconds in player not equal slider step
        setCurrentStep((props.currentStep - 1) * countInStep);
        newCurrentStep = (props.currentStep - 1) * countInStep;
      }

      if (newCurrentStep !== countInStep && newCurrentStep / countInStep === countOfSteps) {
        return setCurrentStep(0);
      }

      setCurrentStep((Math.floor(newCurrentStep / countInStep) * countInStep) + countInStep);
    }
  }, [wasThumbMoved, countInStep]);

  useEffect(() => { // Volume slider
    if (mute === undefined) {
      return;
    }

    if (mute) {
      return setCurrentStep(0);
    }

    setCurrentStep(100);
  }, [mute, countInStep]);

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    updateThumbCoords(e.clientX);

    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("mousemove", onMouseMove);
  };

  const onMouseUp = () => {
    document.removeEventListener("mouseup", onMouseUp);
    document.removeEventListener("mousemove", onMouseMove);
  };

  const onMouseMove = (e: any) => {
    updateThumbCoords(e.clientX);
  };

  const updateThumbCoords = (clientX: number) => {
    const sliderWidth: number = sliderRef.current.getBoundingClientRect().width;
    const thumbWidth: number = thumbRef.current.getBoundingClientRect().width;
    const rightEdge: number = sliderWidth - thumbWidth;
    const maxCountOfSteps: number = 100;

    const currentLeft: number = sliderRef.current.getBoundingClientRect().left + (thumbWidth / 2);
    const shiftX: number = clientX - currentLeft;

    const sizeOfStep: number = rightEdge / maxCountOfSteps; // in pixels

    if (clientX < currentLeft) {
      setCurrentStep(0);
      onUpdate(0);

      return;
    }
    else if (shiftX > rightEdge) {
      setCurrentStep(maxCountOfSteps);
      onUpdate(countOfSteps);

      return;
    }

    const newCurrentStepInPercentage: number = shiftX / sizeOfStep; // For Slider
    const newCurrentStepInSeconds: number = newCurrentStepInPercentage / countInStep; // For Player

    setCurrentStep(newCurrentStepInPercentage);
    onUpdate(newCurrentStepInSeconds);
  };

  const getLeftOffsetFor = (forWhat: string) => {
    const maxCountOfSteps: number = 100;
    const leftInPercentage: number = (currentStep / maxCountOfSteps) * 100;

    const thumbWidth = thumbRef.current.offsetWidth;

    const substractWidth: number = forWhat === 'left-path' // left-path It's line that moves behind thumb
      ? 0
      : thumbWidth * (currentStep / 100);

    return `calc(${leftInPercentage}% - ${substractWidth}px)`;
  };

  return (
    <div
      className="player__slider"
      onMouseDown={onMouseDown}
      ref={sliderRef}
    >
      <div
        className="player__slider-left-path"
        style={{
          width: getLeftOffsetFor("left-path")
        }}
      />

      <div
        className="player__thumb"
        ref={thumbRef}
        style={{
          left: getLeftOffsetFor("thumb")
        }}
      />

      <div className="player__slider-right-path" />
    </div>
  );
};

export default Slider;