import React from 'react';
import { connect } from 'react-redux';

import { updateCallsAndMessagesWidget } from '../../../redux/ducks/widgets';
import { useDidMount, useToggle } from '../../../hooks';

import { AppState } from '../../../redux/store';
import { ICallsWidgetsProps } from '../Welcome.types';

import Spinner from '../../../components/Spinner';


const CallsWidget = (props: ICallsWidgetsProps) => {
  const [isPending, togglePending] = useToggle(true);

  const { callsToday, messagesToday } = props;

  useDidMount(() => {
    props.updateCallsAndMessagesWidget()
      .then(() => togglePending());
  });

  return (
    <div className="welcome__item card">
      <h2 className="welcome__item-title">
        Calls & Messages today
      </h2>

      <div className="calls-widget welcome__not-draggable">
        <div className="welcome__data">
          {isPending
            ? (
              <div className="welcome__spinner-wrap">
                <Spinner />
              </div>
            )
            : (
              <div className="welcome__count-container">
                <div className='welcome__count-wrap'>
                  <span>Incoming Calls</span>
                  <span className='welcome__count'>
                    {callsToday.incoming ? callsToday.incoming : '0'}
                  </span>
                </div>

                <div className='welcome__count-wrap'>
                  <span>Outgoing Calls</span>
                  <span className='welcome__count'>
                    {callsToday.outgoing ? callsToday.outgoing : '0'}
                  </span>
                </div>

                <div className='welcome__count-wrap'>
                  <span>Incoming Messages</span>
                  <span className='welcome__count'>
                    {messagesToday.incoming ? messagesToday.incoming : '0'}
                  </span>
                </div>

                <div className='welcome__count-wrap'>
                  <span>Outgoing Messages</span>
                  <span className='welcome__count'>
                    {messagesToday.outgoing ? messagesToday.outgoing : '0'}
                  </span>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  callsToday: state.widgets.interactionsToday.callsToday,
  messagesToday: state.widgets.interactionsToday.messagesToday,
});

const mapDispatchToProps = {
  updateCallsAndMessagesWidget,
};

export default connect(mapStateToProps, mapDispatchToProps)(CallsWidget);