import { Layouts } from 'react-grid-layout';

import { CallOrMessageWidget } from '../../redux/ducks/widgets';
import { ICall, IOperator, IMissedCall } from '../../interfaces/interfaces';
import { IContactCardState } from '../../components/ContactCard/ContactCard.types';

export interface IWelcomeProps {
  userPermissions: string[];
  gridLayouts: Layouts | null;
  activeWidgets: string[] | null;
  isWidgetsPending: boolean;
  updateActiveWidgets: (newWidgets: string[]) => void;
  updateGridLayouts: (newLayouts: Layouts) => void;
  userTimezone: number;
}

export interface IOperatorsWidgetProps {
  operatorsCount: {
    total: number,
    online: number,
    away: number,
    busy: number,
  },
}

export interface ILastCallsWidgetProps {
  playAudio: (audio: EventTarget) => void;
}

export interface IMissedCallsWidgetProps {
  playAudio: (audio: EventTarget) => void;
}

export interface ILastCallsWidgetItemProps {
  openContactCard: (card: IContactCardState) => void;
  playAudio: (audio: EventTarget) => void;
  item: ICall;
  operator: IOperator;
  userTimezone: number;
  userHour12: boolean;
}

export interface IMissedCallsWidgetItemProps {
  openContactCard: (card: IContactCardState) => void;
  playAudio: (audio: EventTarget) => void;
  item: IMissedCall;
  operator: IOperator;
  userTimezone: number;
  userHour12: boolean;
}

export interface ILastCallsWidgetItemConnectProps {
  item: ICall;
}

export interface IMissedCallsWidgetItemConnectProps {
  item: IMissedCall;
}

export interface ICallsWidgetsProps {
  callsToday: CallOrMessageWidget;
  messagesToday: CallOrMessageWidget;
  updateCallsAndMessagesWidget: () => Promise<void>;
}

export interface INewClientsStatisticsWidgetProps {
  userTimezone: number;
}

interface IIntervalsWidget {
  getDatesForInterval: (interval: 'day' | 'week' | 'month', userTimezone: number) => [number, number];
  userTimezone: number;
}

export interface IIncomingCallsWidgetProps extends IIntervalsWidget { }
export interface IIncomingCallsWaitingWidgetProps extends IIntervalsWidget { }
export interface IInternalCallsDurationWidgetProps extends IIntervalsWidget { }
export interface ICallsDurationWidgetProps extends IIntervalsWidget {
  title: string,
  type: 'incoming' | 'outgoing';
}

export const lineChartDataset = {
  lineTension: 0.2,
  backgroundColor: 'rgba(78,91,242,0.1)',
  borderColor: 'rgba(78,91,242,0.8)',
  borderJoinStyle: 'round',
  pointBorderColor: 'rgba(78,91,242,1)',
  pointBackgroundColor: 'rgba(78,91,242,0.8)',
  pointBorderWidth: 2,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: 'rgba(78,91,242,1)',
  pointHoverBorderColor: 'rgba(78,91,242,0.5)',
  pointHoverBorderWidth: 5,
  pointRadius: 3,
};

export type TLineChartDatasetOptions = typeof lineChartDataset;

export interface IChartDataset extends TLineChartDatasetOptions {
  label: string,
  data: number[],
}

export interface IChartData {
  labels: string,
  datasets: Array<IChartDataset>
}