import { Component } from 'react';
import { connect } from 'react-redux';

import { SOCKET_ROOT } from '../../config/socket-config';

import {
  changeOperatorStatus,
  updateOperator,
} from '../../redux/ducks/operators';
import { stopCronJob, startCronJob } from '../../redux/ducks/cronJobs';
import { updateCallsAndMessagesWidget } from '../../redux/ducks/widgets';

import { IChatSocketProps } from './ChatSocket.types';
import { AppState } from '../../redux/store';
import { ROUTES } from '../../config/constants';


class ChatSocket extends Component<IChatSocketProps> {
  state = {
    ws: new WebSocket(SOCKET_ROOT)
  }

  componentDidMount() {
    // if (localStorage.isLoggedIn) {
    this.setupSocket();
    // }
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    const webSocket = this.state.ws;
    webSocket.close();
  }

  setupSocket = () => {
    const webSocket = this.state.ws;

    webSocket.onopen = () => {
      console.log('ChatWebSocket connected!)');
    }

    webSocket.onclose = () => {
      console.log('ChatWebSocket disconnected!(');
    }

    webSocket.onerror = (err) => {
      console.log('ChatWebSocket error: ', err);
    }

    webSocket.onmessage = (e) => {
      const { data, type } = JSON.parse(e.data);

      console.log('WebSocket event happen!:', 'type:', type, ', data:', data);

      switch (type) {
        // Synchronization of operators work
        case 'ping': {
          webSocket.send(JSON.stringify({ type: 'pong', data: 'pong' }));
          break;
        }
        case 'change_user_status': {
          this.props.changeOperatorStatus(data.user_id, data.status);
          break;
        }
        case 'change_user_data': {
          if (this.props.userId === data.id && data.status === 'offline') {
            return window.location.href = ROUTES.login;
          }
          this.props.updateOperator(data);
        }
        case 'cron': {
          switch (data.type) {
            case 'start-process': {
              this.props.startCronJob(data.id);
              break;
            }
            case 'kill-process': {
              this.props.stopCronJob(data.id);
              break;
            }
          }
          break;
        }
        case 'widgets': {
          switch (data.type) {
            case 'interactions_count': {
              this.props.updateCallsAndMessagesWidget();
              break;
            }
          }
        }
        default:
          break;
      }
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state: AppState) => ({
  userId: state.user.user.id
});

const mapDispatchToProps = {
  changeOperatorStatus,
  updateOperator,
  stopCronJob,
  startCronJob,
  updateCallsAndMessagesWidget,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatSocket);