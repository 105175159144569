import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay
} from '@fortawesome/free-solid-svg-icons'

import {
  IMissedCallsWidgetItemProps,
  IMissedCallsWidgetItemConnectProps
} from '../../Welcome.types';

import Player from '../../../../components/Player/Player';
import { AppState } from '../../../../redux/store';
import LazyLoadImage from '../../../../components/LazyLoadImage/LazyLoadImage';
import { getPhotoFromEntity, getFullDateAndTime, getDateByTimezoneOffset } from '../../../../utils';
import { selectUserTimezone } from '../../../../redux/selectors/selectors';

const MissedCallsWidgetItem = (props: IMissedCallsWidgetItemProps) => {
  const { item, operator, userTimezone, userHour12 } = props;

  const [isOpenCall, setOpenCall] = useState<undefined | boolean>();
  const [animation, setAnimation] = useState({
    className: '',
    styles: {},
    isProcessGoes: false,
  });

  useEffect(() => {
    const isNotInit = isOpenCall !== undefined;

    if (isOpenCall && isNotInit) {
      setAnimation({
        className: 'missed-calls-widget__table-item-wrap--open',
        styles: {},
        isProcessGoes: false,
      });

      setTimeout(() => setAnimation({
        className: '',
        styles: {},
        isProcessGoes: true
      }), 500);
    } else if (!isOpenCall && isNotInit) {
      setAnimation({
        className: 'missed-calls-widget__table-item-wrap--close',
        styles: {},
        isProcessGoes: true,
      });

      setTimeout(() => setAnimation({
        className: '',
        styles: {},
        isProcessGoes: false,
      }), 500);
    }
  }, [isOpenCall]);

  const openContactCard = () => {
    const { caller } = item;

    if (!caller) return;

    props.openContactCard({
      isOpen: true,
      contact: caller,
    });
  };

  const showResolvedTime = (dateCreated: string, resolvedDate: string) => {
    let seconds = (Date.parse(resolvedDate) - Date.parse(dateCreated)) / 1000;
    let days = Math.floor(+seconds / (3600 * 24));
    let hours = Math.floor(+seconds % (3600 * 24) / 3600);
    let minutes = Math.floor(+seconds % 3600 / 60);

    let showDays = days > 0 ? `${days}d ` : "";
    let showHours = hours > 0 ? `${hours}h ` : "";
    let showMinutes = minutes > 0 ? `${minutes}min` : "< 1min";

    return showDays + showHours + showMinutes;
  };

  return (
    <div
      className={`missed-calls-widget__table-item-wrap ${animation.className}`}
      style={animation.styles}
    >
      <div className="table__item">
        <div className="table__item-block missed-calls-widget__table-block-client">
          <span className="missed-calls-widget__client-avatar">
            <LazyLoadImage
              src={getPhotoFromEntity(item.caller.photo)}
              alt='Operator Photo'
              spinnerColor='black'
              spinnerSize='20px'
            />
          </span>
          <span
            className="missed-calls-widget__client-name"
            onClick={openContactCard}
          >
            {item.caller ? item.caller.fn : '-'}
          </span>
        </div>

        <div className="table__item-block missed-calls-widget__table-block-missed-date-time">
          {item.dateCreated
            ? getFullDateAndTime(getDateByTimezoneOffset(userTimezone, item.dateCreated), userHour12)
            : '-'
          }
        </div>

        <div className="table__item-block missed-calls-widget__table-block-resolved-after">
          {item.dateResolved ? showResolvedTime(item.dateCreated, item.dateResolved) : '-'}
        </div>

        <div className="table__item-block missed-calls-widget__table-block-operator">
          {operator && operator.username}
        </div>

        <div className="table__item-block missed-calls-widget__table-block-voicemail">
          {!!item.callRecordsUrl
            ? <button
              className="btn btn--primary last-calls-widget__open-player"
              onClick={animation.isProcessGoes ? () => null : () => setOpenCall(!isOpenCall)}
            >
              <FontAwesomeIcon icon={faPlay} />
            </button>
            : '-'
          }
        </div>

        <div className="table__item-block missed-calls-widget__table-block-status"></div>
      </div>

      {!!item.callRecordsUrl &&
        <div className="missed-calls-widget__call-wrap">
          <div className="missed-calls-widget__call">
            <Player
              src={item.callRecordsUrl}
              onPlay={props.playAudio}
            />
          </div>
        </div>
      }
    </div>
  );
};

const mapStateToProps = (state: AppState, ownProps: IMissedCallsWidgetItemConnectProps) => ({
  operator: state.operators.entities[ownProps.item.user_id],
  userTimezone: selectUserTimezone(state),
  userHour12: state.user.user.isHour12,
});

export default connect(mapStateToProps)(MissedCallsWidgetItem);