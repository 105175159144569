import React, { SetStateAction, Dispatch } from 'react';

import './MainPage.scss';
import Topbar from '../../containers/Topbar/Topbar';
import Sidebar from '../../containers/Sidebar/Sidebar';


const MainPage = (props: { children: React.ReactChild, setPagePending: Dispatch<SetStateAction<boolean>> }) => {
  return (
    <div className="main-page" >
      <Sidebar />

      <main className="main-page__wrapper" >
        <Topbar setPagePending={props.setPagePending} />

        <div className="main-page__settings-container" >
          {props.children}
        </div>
      </main>
    </div>
  );
}

export default MainPage;