export interface IRingtone {
  title: string,
  type: number,
  url: string,
  id: number,
  hash: String,
  caller_type: number,
  callers_type: Number | null
  is_default_for_type: number;
};

export interface IShortcut {
  id: number;
  shortcut: string;
  value: string;
  count: number;
  edited_by?: string;
  date_edited?: string;
  category: ICategory | null;
};

export interface IAction {
  type: string,
  payload?: any,
};

export interface IOperatorApi {
  id?: number;
  username?: string;
  phone?: string | null;
  email?: string;
  file?: File;
  active?: number;
  password?: string;
};

export interface ITimeZone {
  id: number,
  is_active: number,
  name: string,
  offset_minutes: number,
  updated_at: string,
  url: string,
  utc_offset: string,
}

export interface IOperator {
  id: number;
  active: number;
  type: number;
  unreadCount: number;
  photo: null | IEntityPhoto;
  status: string;
  username: string;
  email: string;
  role: string;
  timezone: ITimeZone;
  phone: null | string;
};

export interface ICourier {
  id: number;
  status: number;
  photo: null | IEntityPhoto;
  username: string;
  email: string;
};

export interface ICourierApi {
  id?: number;
  username?: string;
  phone?: string | null;
  email?: string;
  file?: File;
  status?: number;
  password?: string;
};
export interface ILoginParams {
  username: string,
  password: string,
  timezone: string,
}

export interface ICronJob {
  id: number;
  active: number;
  running: number;
  started_at: number;
  logs: any[];
  command: string;
  title: string;
  description: string;
  cron_string: string;
};

export interface ICronJobApi {
  id?: number;
  active?: number;
  title?: string;
  description?: string;
  cron_string?: string;
}

export interface ISystemRoleApi {
  name: string;
  oldName?: string;
  description?: string;
  permissions?: string[];
}

export interface ICall {
  callRecordsUrl: string | null;
  callStages: ICallStage[],
  callBegin: string;
  caller: IContact;
  caller_id: number;
  dateCreated: string;
  duration: number;
  hasUrls: number;
  id: number;
  isRead: number;
  status: string;
  type: number;
  user: IOperator;
  user_id: number;
  cost?: number;
  phone_number?: string;
}

export interface IContact {
  date_created: string;
  date_updated: string;
  editedBy: number;
  fn: string;
  hardLocked: number;
  id: number;
  isNew: boolean;
  last_interaction: string;
  makred: boolean; //TODO Marked
  note: string;
  photo: null | IEntityPhoto;
  pinned: boolean;
  uncompletedTasks: number;
  unreadCount: number;
  avatar: null | string,
  type: number,
  //TODO 
  urls: IContactUrl[];
  adr: string;
  tels: IContactTel[];
  emails: IContactEmail[];
  callerTags: IContactTag[];
  blocked: null | ICallerBlocked;
  chatType?: string;
  contacts_resource: string | null;
  not_for: string | null;
  is_ex: number;
  agentId?: number;
  agent?: IContact,
  isActive: boolean | string;
  image: IEntityPhoto | null;
  short_name: string;
  relatedUserId?: number | null;
  links?: string;
  map?: string;
};

export interface ICallerBlocked {
  reason: string;
  date_created: string;
  user: IOperator;
};

export interface IContactTel {
  id: number;
  labelName: string;
  tel: string;
  status: number;
  default: number;
}

export interface ITelLabel {
  label: string,
  value: string,
}
export interface IContactEmail {
  id: number;
  name: string;
  email: string;
  default: number;
}
export interface IContactTag {
  id: number;
  title: string;
  color: string;
}

export interface IContactUrl {
  title: string,
  url: string,
}

export interface IMissedCall {
  callRecordsUrl?: string;
  callBegin?: string;
  caller: IContact;
  caller_id?: number;
  dateCreated: string;
  dateResolved?: string;
  duration?: number;
  hasUrls?: number;
  id?: number;
  isRead?: number;
  status?: string;
  type?: number;
  user?: IOperator;
  user_id: number;
}

export interface IDivaProfile {
  id: number,
  avatar: string | null,
  contact_uid: string,
  created_at: string,
  name: string,
  place: string,
  link: string,
}

export interface IMessage {
  caller?: IContact,
  caller_id: number,
  interaction_id: number,
  id: number,
  dateCreated: string,
  status?: string,
  user: IOperator,
  user_id: number,
  type: number,
  cost?: number,
  segments: number,
  phone_number?: string,
  body?: string,
  hasUrls: number,
  data: any,//TODO fix type
}
export interface IEntityPhoto {
  src: string;
  min: string;
}

export interface IInteraction {
  id: number;
  body: string;
  caller_id: number;
  caller: IContact;
  status: number;
}

export interface IMsgTemplate {
  id: number;
  text: string;
  caller_type: number;
}

export interface IPublicApp {
  id: number;
  name: string;
  date_created: string;
  active: number;
  last_activity_date: string;
  api_key: string;
}

export interface AnyObject {
  [key: string]: any,
}

export interface ReactSelectOption {
  label: any;
  value: any;
}

export interface ICallStage {
  id: number,
  interaction_id: number,
  date_created: string,
  user_id: number,
  recipient_id: number | null,
  action: string,
  call_records_url: string | null,
}

export enum IRingtoneTypeEnum {
  SMS_TYPE = 1,
  CALL_TYPE,
  TRANSFER_TYPE,
  VOICEMAIL_TYPE,
  ON_HOLD_TYPE,
};

export enum IContactInteractionEnum {
  incoming = 1, // call
  outgoing = 2, // call
};

export enum IContactInteractionSmsEnum {
  outgoing = 3, // sms
  incoming = 4, // sms
};

export type FinalFormReset = (initialValues?: object | undefined) => void;

export interface IOperatorData {
  id: number,
  username: string,
}
export interface IOperatorsNamesList {
  [key: number]: string,
};

export interface ICategory {
  id: number | string;
  title: string;
};

export interface INormalizedCategory extends ICategory {
  value: string;
  label: string;
};

export interface INotForContact {
  callerIgnore: IContact;
  caller_id: number;
  caller_ignore_id: number;
  date_created: string;
  id: number;
  reason?: string;
}

export interface IBooking {
  id: number,
  callerId: number,
  caller: IContact,
  discount_rate: number,
  duration: string,
  is_success: number,
  profile_id: number,
  profile: null | IContact,
  rate: string,
  usersIds: number[] | [],
  userId: number,
  date: string,
  date_created: string,
  status: 0 | 1 | 2 | 3,
};