import { AxiosError } from 'axios';

import { ROUTES } from '../config/constants';
import deleteCookie from './deleteCookie';

export default (error: AxiosError, errorHandler = (err: AxiosError) => console.log(err)) => {
  console.log('handleNetError', error.response);

  if (!error.response) {
    console.error('Unhandled error', error);
    return;
  }

  if (error.response.data.message === 'Access denied') {
    window.location.href = ROUTES.accessDenied;

    return;
  }

  switch (error.response.data.code) {
    case 666:

      console.log('hahaha - Invalid Connect Token');

      deleteCookie('isAuthenticated');

      delete localStorage.isLoggedIn;
      delete localStorage.currentUserId;

      window.location.href = ROUTES.login;


      break;
    case 400:
      errorHandler(error.response.data.message);
      break;
    default:
      console.error('Unhandled error', error);
      break;
  }
};
